import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { provideNgxMask } from 'ngx-mask';
import { PORTAL_TYPE_URLS } from '../shared/enums/portal-types-urls.enum';
import { SIDEBAR_OPTIONS_URLS } from '../shared/enums/sidebar-option-urls.enum';
import { AuthService } from '../shared/services/auth/auth.service';
import { LoadingService } from '../shared/services/loading/loading.service';
import { emailValidator, verifyErrorIsConection, verifyErrorIsTimeout } from '../shared/utils';
import { ToastrService } from 'ngx-toastr';
import { ModalForgotPassComponent } from '../shared/components/modal-forgot-pass/modal-forgot-pass.component';
import { MatDialog } from '@angular/material/dialog';
import { ERRORS_ENUM } from '../shared/enums/error.enum';
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  providers: [provideNgxMask()],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  loginFormGroup: FormGroup = new FormGroup([]);
  passType: string = 'password';
  token: string | null = null;

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _router: Router,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
    });
  }

  ngOnInit(): void {
    this.showSlides(0);
    this.initializeFormGroup();
    this.disabledFormControls();
  }

  initializeFormGroup() {
    this.loginFormGroup = this._formBuilder.group({
      email: [, Validators.compose([Validators.required, this.emailValidator])],
      senha: [, [Validators.required]],
      token: [, Validators.compose([Validators.required, Validators.minLength(6)])],
      newPass: ['', [Validators.required, this.newPassValidator()]],
    });
  }

  disabledFormControls() {
    if (this.token) {
      this.loginFormGroup.get('email')?.clearValidators();
      this.loginFormGroup.get('senha')?.clearValidators();
    } else {
      this.loginFormGroup.get('token')?.clearValidators();
      this.loginFormGroup.get('newPass')?.clearValidators();
    }
  }

  get email() {
    return this.loginFormGroup.get('email');
  }

  get password() {
    return this.loginFormGroup.get('senha');
  }

  get newPass() {
    return this.loginFormGroup.get('newPass');
  }


  emailValidator(control: AbstractControl) {
    if (control.value && !emailValidator(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  };


  verifyPassContainQuotation(): boolean {
    if (this.newPass?.value && /['"]/.test(this.newPass?.value)) return true;
    else return false;
  }

  verifyPassNoContainNumberAndLetters(): boolean {
    if (this.newPass?.value && !/[a-zA-Z]/.test(this.newPass?.value) || !/[0-9]/.test(this.newPass?.value)) return true;
    else return false;
  }

  verifyPassContainLowerAndUpperCase(): boolean {
    if (this.newPass?.value && /[a-z]/.test(this.newPass?.value) && /[A-Z]/.test(this.newPass?.value)) return true;
    return false;
  }

  verifyPassContainSequence(): boolean {
    if (this.newPass?.value && /123|234|345|456|567|678|789/.test(this.newPass?.value)) return true;
    else return false;
  }

  newPassValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const senha = control.value;
      const errors = {};
      if (senha && senha.length < 8) return { senhaCurta: 'A senha deve ter pelo menos 8 caracteres' };
      if (this.verifyPassContainQuotation()) return { senhaComAspas: 'A senha não pode conter aspas' };
      if (this.verifyPassNoContainNumberAndLetters()) return { senhaSemLetraENumero: 'A senha deve conter letras e números' };
      if (!this.verifyPassContainLowerAndUpperCase()) return { senhaSemMaisculaEMinuscula: 'deve conter pelo menos uma letra maiúscula e uma minúscula.' };
      if (this.verifyPassContainSequence()) return { senhaComNumerosSequenciais: 'A senha não pode conter números sequenciais' };
      return null;
    };
  }

  login() {
    if (!this.loginFormGroup.valid) return this.openToastError("Preencha corretamente os campos!");
    this.loadingService.present();
    this._authService.login(this.loginFormGroup.value.email, this.loginFormGroup.value.senha).subscribe({
      next: () => this._router.navigate([PORTAL_TYPE_URLS.RJ_190 + '/' + SIDEBAR_OPTIONS_URLS.GENERAL_PANEL]),
      error: (err) => {
        if (!verifyErrorIsTimeout(err) && !verifyErrorIsConection(err)) return this.openToastError(err.error.message)
        return;
      }
    }).add(() => this.loadingService.dismiss());
  }

  showSlides(slideIndex: number) {
    let i;
    let slides = document.getElementsByClassName("slides") as any;
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slideIndex++;
    if (slideIndex > slides.length) { slideIndex = 1 }
    if (slides[slideIndex - 1]) {
      slides[slideIndex - 1].style.display = "block";
      setTimeout(() => {
        this.showSlides(slideIndex)
      }, 3000);
    }
  }

  openToastError(message: string): void {
    this.toastr.error(message, 'Erro!');
  }

  openToastWarning(message: string): void {
    this.toastr.warning(message, 'Atenção!');
  }

  openModalForgotPass() {
    this.dialog.open(ModalForgotPassComponent, {
      width: '35%',
      maxWidth: '350px',
      height: '50%',
      maxHeight: '350px'
    });
  }


  resetPass() {
    if (!this.loginFormGroup.valid) return this.openToastError("Preencha corretamente os campos!");
    this.loadingService.present();
    this._authService.resetPass(this.loginFormGroup.value.newPass, this.token, this.loginFormGroup.value.token).subscribe({
      next: () => {
        this.loadingService.dismiss();
        this._router.navigateByUrl(PORTAL_TYPE_URLS.LOGIN).then(() => this.toastr.success('Senha alterada com sucesso.', 'Sucesso!'))
      },
      error: (err) => {
        if (!verifyErrorIsTimeout(err) && !verifyErrorIsConection(err)) this.toastr.error(err?.error?.message ? err.error?.message : 'Erro ao alterar a senha.', 'Erro!');
        this.loadingService.dismiss();
      },
    })
  }

  preventSpace(event: KeyboardEvent) {
    if (event.key === ' ') event.preventDefault();
  }
}
