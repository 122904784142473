import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { GeneralPanel190RjService } from "../../../190rj/services/general-panel190rj/general-panel190RJ.service";
import { HeaderTableSettings } from "../../../shared/components/header-box-table/header-box-table.model";
import { PORTAL_FILTER_CARD_TITLES } from "../../../shared/components/filter-card/enums/filter-card-titles.enum";
import { getLastFiveYears } from "../../../shared/utils";

@Injectable({
  providedIn: 'root'
})

export class TableGeneralPanel190RJService {
  constructor(
    private generalPanelRJ190Service: GeneralPanel190RjService,
  ) { }

  public async getSettingsTable(card: any, pageNumber?: number, year?: string) {
    let config = {} as HeaderTableSettings;
    let response = await this.getDataTableByCard(card, pageNumber, year);
    if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES) {
      config.headerTitle = card.title;
      config.itensPerPage = 5;
      config.totalItens = response?.total;
      config.showTextBox = false;
      config.showFirstFilterBox = true;
      config.showExcelButton = true;
      config.hideButtonSearch = true;
      config.optionsFirstFilter = [];
      config.titlesTables = [
        'Tipo',
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
        'Total'];
      config.showButton = false;
      config.tableBody = response?.data ? response?.data : [];
    } else if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS) {
      config.headerTitle = card.title;
      config.itensPerPage = 5;
      config.totalItens = response.total;
      config.showTextBox = false;
      config.showFirstFilterBox = true;
      config.showExcelButton = true;
      config.hideButtonSearch = true;
      config.optionsFirstFilter = [];
      config.titlesTables = [
        'Tipo',
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
        'Total'];
      config.showButton = false;
      config.tableBody = response?.data ? response.data : [];
    } else if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_MISSING) {
      config.headerTitle = card.title;
      config.itensPerPage = 5;
      config.showTextBox = false;
      config.showFirstFilterBox = true;
      config.showExcelButton = true;
      config.hideButtonSearch = true;
      config.optionsFirstFilter = [];
      config.titlesTables = [
        'Tipo',
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
        'Total'];
      config.showButton = false;
      config.tableBody = response ? response : [];;
    };
    let dates = getLastFiveYears();
    dates.forEach((year: number) => {
      config?.optionsFirstFilter?.push({ name: `${year}`, value: `${year}` });
    });
    return config;
  }

  private async getDataTableByCard(card?: any, pageNumber?: number, year?: string) {
    let response: any;
    if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES) {
      await firstValueFrom(this.generalPanelRJ190Service.generalPanelEmergencyTable(5, pageNumber, year)).then((responseAPI) => response = responseAPI).catch(() => response = []);
    } else if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS) {
      await firstValueFrom(this.generalPanelRJ190Service.denouncementTable(5, pageNumber, year)).then((responseAPI) => response = responseAPI).catch(() => response = []);
    } else if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_MISSING) {
      await firstValueFrom(this.generalPanelRJ190Service.missingTable(year)).then((responseAPI) => response = responseAPI).catch(() => response = []);
    };
    if (response) {
      let newResponse: any = [];
      let arrayForeach = response?.data ? response.data : response;
      arrayForeach.forEach((element: any) => {
        let newObject = {
          type: element?.tipoChamado ? element.tipoChamado : element.tipoDenuncia ? element.tipoDenuncia : element.tipo,
          jan: element.Jan ? element.Jan : 0,
          fev: element.Fev ? element.Fev : 0,
          mar: element.Mar ? element.Mar : 0,
          abr: element.Abr ? element.Abr : 0,
          mai: element.Mai ? element.Mai : 0,
          jun: element.Jun ? element.Jun : 0,
          jul: element.Jul ? element.Jul : 0,
          ago: element.Ago ? element.Ago : 0,
          set: element.Set ? element.Set : 0,
          out: element.Out ? element.Out : 0,
          nov: element.Nov ? element.Nov : 0,
          dez: element.Dez ? element.Dez : 0,
          total: element.Jan +
            element.Fev +
            element.Mar +
            element.Abr +
            element.Mai +
            element.Jun +
            element.Jul +
            element.Ago +
            element.Set +
            element.Out +
            element.Nov +
            element.Dez
        };
        newResponse.push(newObject);
      });
      response?.data ? response.data = newResponse : response = newResponse;
      return response;
    } else return {};
  }

}
