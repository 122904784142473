import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-portal-190rj',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './portal-190rj.component.html',
  styleUrl: './portal-190rj.component.scss',
})
export class Portal190rjComponent {
}
