import { Component } from '@angular/core';
import { DynamicButtonComponent } from '../../../shared/components/dynamic-button/dynamic-button.component';
import { ButtonConfig } from '../../../shared/components/dynamic-button/dynamic-button.model';
import { HeaderBoxTableComponent } from '../../../shared/components/header-box-table/header-box-table.component';
import { OptionFilter } from '../../../shared/components/header-box-table/header-box-table.model';
import { SidebarOptionTitleBandComponent } from '../../../shared/components/title-band/sidebar-option-title-band.component';
import { DynamicTableService } from '../../../shared/components/dynamic-table/services/dynamic-table.service';
import { Observable, of } from 'rxjs';
import { SIDEBAR_OPTIONS_URLS } from '../../../shared/enums/sidebar-option-urls.enum';
import { Router } from '@angular/router';


@Component({
  selector: 'app-emergency-page-rede-mulher',
  standalone: true,
  imports: [HeaderBoxTableComponent, SidebarOptionTitleBandComponent, DynamicButtonComponent],
  templateUrl: './emergency-page-rede-mulher.component.html',
  styleUrl: './emergency-page-rede-mulher.component.scss'
})
export class EmergencyPageRedeMulherComponent {
  emergencysData: any[];
  configButtonRegister: ButtonConfig = { label: 'Excel', imgSrc: "assets/excel_ic.svg", handler: () => this.excelExport(), customColor: { backgroundColor: "#00954B" } }
  tableConfig: any = {};

  constructor(
    private _tableService: DynamicTableService,
    public router: Router
  ) {

  }

  async ngOnInit() {
    this.tableConfig = await this.getConfigTable();
    this.emergencysData = this.tableConfig.tableBody;
  }

  getConfigTable(): Promise<any> {
    return new Promise(resolve => {
      this.getData().subscribe(
        {
          next: (response) => {
            resolve(response);
          },
          error: () => {
            console.log('error api')
          }
        }
      )
    })
  }

  public searchAndFilterItens(event: any) {
    let newArray: any = [];
    this.emergencysData.forEach(emergency => {
      if (event?.searchValue && event?.filterValue) {
        if (this.filterDepedingCard(event.filterValue, emergency)) {
          if (this.validateObject(emergency, event.searchValue)) {
            newArray.push(emergency);
          }
        }
      } else {
        if (event?.searchValue) {
          if (this.validateObject(emergency, event.searchValue)) {
            newArray.push(emergency);
          }
        } else {
          if (this.filterDepedingCard(event.filterValue, emergency)) newArray.push(emergency);
        }
      }
    });
    this.tableConfig.tableBody = newArray;
  }

  public filterDepedingCard(value: string, object: any): boolean {
    if (value.toLowerCase().includes(object.type.toLowerCase())) return true;
    return false;
  }

  public validateObject(object: any, textValue: string): boolean {
    if (
      object.protocol.toString().toLowerCase().includes(textValue) ||
      object.date.toString().toLowerCase().includes(textValue) ||
      object.hour.toString().includes(textValue) ||
      object.type.toString().toLowerCase().includes(textValue) ||
      object.victim.toString().toLowerCase().includes(textValue) ||
      object.status.toString().toLowerCase().includes(textValue) ||
      object.classification.toString().toLowerCase().includes(textValue)) return true;
    return false;
  }

  public clearSearchTable() {
    this.tableConfig.tableBody = this.emergencysData;
  };

  public excelExport() {

  }

  private getData(): Observable<any> {
    // return this._tableService.getObjectConfigTable(this.router.url, SIDEBAR_OPTIONS_URLS.EMERGENCY);
    return of({});
  }
}
