import { Subtitle } from "../../line-chart/models/line-dataset";

export class BarDataset {
  constructor(
    data: Array<number>,
    backgroundColor: string,
    labels: Array<string>,
    borderRadius: number = 20,
    barThickness: number = 20,
    subtitles?: Subtitle[] | undefined,
  ) {
    this.data = data;
    this.backgroundColor = backgroundColor;
    this.labels = labels;
    this.borderRadius = borderRadius;
    this.barThickness = barThickness;
    this.subtitles = subtitles;
  }

  data: Array<number>;
  backgroundColor: string;
  labels: Array<string>;
  borderRadius: number = 20;
  barThickness: number = 20;
  subtitles: Subtitle[] | undefined
}
