import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicButtonComponent } from '../../../shared/components/dynamic-button/dynamic-button.component';
import { ButtonConfig } from '../../../shared/components/dynamic-button/dynamic-button.model';
import { HeaderBoxTableComponent } from '../../../shared/components/header-box-table/header-box-table.component';
import { SidebarOptionTitleBandComponent } from '../../../shared/components/title-band/sidebar-option-title-band.component';
import { EXTRAS_PAGES_URLS } from '../../../shared/enums/extras-pages-urls.enum';
import { UsersService } from '../../services/users-page/users.service';
import { LoadingService } from '../../../shared/services/loading/loading.service';
import { TableUsers190RJService } from '../../services/dynamic-table/dynamic-table__users-page-190rj.service';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { getTypeUser } from '../../../shared/utils';
import { TYPE_USERS } from '../../../shared/enums/type-users';

@Component({
  selector: 'app-users-page190rj',
  standalone: true,
  imports: [HeaderBoxTableComponent, SidebarOptionTitleBandComponent, DynamicButtonComponent],
  templateUrl: './users-page190rj.component.html',
  styleUrl: './users-page190rj.component.scss'
})
export class UsersPage190rjComponent implements OnInit {
  readonly labelUsersPortal: string = 'Usuários Portal';
  readonly labelUsersApp: string = 'Usuários App';
  configButtonRegister: ButtonConfig = { label: 'Usuário', imgSrc: "assets/imgs/icon_more.svg", handler: () => this.registerUser(), customColor: { backgroundColor: "#60B863" } };
  configButtonToggleUsers: ButtonConfig = { label: this.labelUsersPortal, imgSrc: "assets/imgs/toggle-off.svg", handler: () => this.toggleUsers(), customColor: { backgroundColor: "#60B863" } };
  tableConfig: any = {};
  show: boolean = false;
  profiles: any[] = [];
  organs: any[] = [];
  querySearch: string = "";
  filterQuery: string = "";

  constructor(
    private tableService: TableUsers190RJService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private usersService: UsersService,
    private authService: AuthService
  ) { }


  async ngOnInit() {
    this.loadingService.present();
    if (window.history.state.editPage) {
      this.configButtonToggleUsers.label = this.labelUsersApp;
      this.configButtonToggleUsers.imgSrc = 'assets/imgs/toggle-on.svg';
    }
    if (this.configButtonToggleUsers.label == this.labelUsersPortal) {
      this.tableConfig = await this.getUsersPortal();
      this.profiles = this.tableConfig.optionsFirstFilter;
      this.organs = await this.getOrgans();
      this.tableConfig.tableBody = this.getOrganAndProfile(this.tableConfig.tableBody);
    } else {
      this.tableConfig = await this.getUsersApp();
      this.configHandleButton();
    }
    this.tableConfig.currentPage = 1;
    this.show = true;
    this.loadingService.dismiss();
  }


  public async searchAndFilterItens(event: any) {
    this.querySearch = event.searchValue;
    this.filterQuery = event.filterFirstValue;
    if (this.querySearch || this.filterQuery) {
      this.loadingService.present();
      this.tableConfig = this.configButtonToggleUsers.label == this.labelUsersPortal ? await this.getUsersPortal(1, this.querySearch, this.filterQuery, true) :
        await this.getUsersApp(1, this.querySearch);
      this.tableConfig.optionsFirstFilter = this.profiles;
      this.tableConfig.currentPage = 1;
      this.tableConfig.tableBody = this.getOrganAndProfile(this.tableConfig.tableBody);
      this.configHandleButton();
      this.loadingService.dismiss();
    }
  }

  public async clearSearchTable() {
    this.loadingService.present();
    this.querySearch = "";
    this.filterQuery = "";
    this.tableConfig = this.configButtonToggleUsers.label == this.labelUsersPortal ? await this.getUsersPortal(1, '', '', true) :
      await this.getUsersApp();
    this.tableConfig.tableBody = this.getOrganAndProfile(this.tableConfig.tableBody);
    this.tableConfig.currentPage = 1;
    this.tableConfig.optionsFirstFilter = this.profiles;
    this.configHandleButton();
    this.loadingService.dismiss();
  }

  public registerUser() {
    this.router.navigate([EXTRAS_PAGES_URLS.NEW_USER], { relativeTo: this.route });
  }

  public async toggleUsers() {
    this.loadingService.present();
    this.show = false;
    if (this.configButtonToggleUsers.label == this.labelUsersPortal) {
      this.configButtonToggleUsers.label = this.labelUsersApp;
      this.configButtonToggleUsers.imgSrc = 'assets/imgs/toggle-on.svg';
      this.tableConfig = await this.getUsersApp();
      this.configHandleButton();
    } else {
      this.configButtonToggleUsers.label = this.labelUsersPortal;
      this.configButtonToggleUsers.imgSrc = 'assets/imgs/toggle-off.svg';
      this.tableConfig = await this.getUsersPortal();
      if (this.profiles.length == 0) this.profiles = this.tableConfig.optionsFirstFilter;
      if (this.organs.length == 0) this.organs = await this.getOrgans();
      this.tableConfig.tableBody = this.getOrganAndProfile(this.tableConfig.tableBody);
    };
    this.tableConfig.currentPage = 1;
    this.loadingService.dismiss();
    this.show = true;
  }

  private async getUsersPortal(page?: number, name?: string, profileID?: string, isItSearch?: boolean): Promise<any> {
    return this.tableService.getSettingsTableUsersPortal(page, name, profileID, isItSearch);
  };

  private async getUsersApp(page?: number, name?: string): Promise<any> {
    return this.tableService.getSettingsTableUsersApp(page, name);
  };

  async swapPage(page: number) {
    this.loadingService.present();
    this.tableConfig = this.configButtonToggleUsers.label == this.labelUsersPortal ? await this.getUsersPortal(page, this.querySearch, this.filterQuery, true) :
      await this.getUsersApp(page, this.querySearch);
    if (this.configButtonToggleUsers.label == this.labelUsersPortal) this.tableConfig.tableBody = this.getOrganAndProfile(this.tableConfig.tableBody);
    else this.configHandleButton();
    this.tableConfig.currentPage = page;
    this.tableConfig.optionsFirstFilter = this.profiles;
    this.loadingService.dismiss();
  }

  async getOrgans(): Promise<any> {
    return new Promise(resolve => {
      this.usersService.getOrgans().subscribe({
        next: (response) => resolve(response.data),
        error: () => resolve([])
      })
    })
  }

  getOrganAndProfile(array: any[]): any[] {
    array = this.getOrgansById(array);
    array = this.getProfilesById(array);
    return array;
  }

  getOrgansById(array: any): any[] {
    array.forEach((user: any) => {
      let organ: any = this.organs.find(org => org.ID_Orgao == user.organID);
      if (organ) {
        user.organ = organ.NM_Orgao;
        delete user.organID;
      }
    });
    return array;
  }

  getProfilesById(array: any): any[] {
    array.forEach((user: any) => {
      let profile: any = this.profiles.find(prf => prf.value == user.profileID);
      if (profile) {
        user.profile = profile.name;
        delete user.profileID;
      }
    });
    return array;
  }

  private configHandleButton() {
    if (this.tableConfig.showButton) {
      this.tableConfig.buttonTable.handler = (user: any) => {
        this.navToEditUser(user);
      };
    };
  }

  navToEditUser(user: any) {
    this.router.navigate([EXTRAS_PAGES_URLS.EDIT_USER], { relativeTo: this.route, state: { userID: user.id } });
  }

  showRegisterUserButton = () => getTypeUser(this.authService.getAccessToken) == TYPE_USERS.ADMIN

}
