import { AbstractControl } from "@angular/forms";
import { PORTAL_TYPE_URLS } from "./enums/portal-types-urls.enum";
import { saveAs } from 'file-saver';

export const authSecretKey = 'Bearer_Token';

export const cpfMask = function (v: any) {
  v = v.toString();
  v = v.replace(/\D/g, "")
  v = v.replace(/(\d{3})(\d)/, "$1.$2")
  v = v.replace(/(\d{3})(\d)/, "$1.$2")
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  return v
}

export const phoneMask = function (value: any) {
  if (!value) return ""
  value = value.toString();
  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{2})(\d)/, "($1) $2")
  value = value.replace(/(\d)(\d{4})$/, "$1-$2")
  return value
}

export const getPortalTypeFromLink = function (link: string) {
  let portal: string = "";
  if (link.includes(PORTAL_TYPE_URLS.REDE_MULHER)) portal = PORTAL_TYPE_URLS.REDE_MULHER;
  else if (link.includes(PORTAL_TYPE_URLS.RJ_190)) portal = PORTAL_TYPE_URLS.RJ_190;
  else if (link.includes(PORTAL_TYPE_URLS.REDE_ESCOLA)) portal = PORTAL_TYPE_URLS.REDE_ESCOLA;
  return portal;
}

export const ValidateCpf = function (controle: AbstractControl) {
  const cpf = controle.value;

  let soma: number = 0;
  let resto: number;
  let valido: boolean;

  const regex = new RegExp('[0-9]{11}');

  if (
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999' ||
    !regex.test(cpf)
  )
    valido = false;
  else {
    for (let i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(9, 10))) valido = false;

    soma = 0;
    for (let i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(10, 11))) valido = false;
    valido = true;
  }

  if (valido) return null;

  return { cpfInvalido: true };
}

export const generateLoremText = function () {
  let text = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
  return text;
}

export const downloadFile = function (data: any, fileName: string) {
  const blob = new Blob([data], { type: 'application/octet-stream' });
  saveAs(blob, fileName + '.xlsx');
}

export const isTokenExpired = (token: any) => Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000;


export const transformDateUStoBR = function (date: any) {
  var arrDate = date.split("-");
  return arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0];
};

export const getNameUser = (token: any) => (JSON.parse(atob(token.split('.')[1]))).name;

export const dataURItoBlob = (dataURI: any) => {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/png' });
};

export const base64ToBlob = (dataURI: string) => {
  const splitDataURI = dataURI.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++)
    ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeString })
};

export const getDateDDMMYY = () => {
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1; // months from 1-12
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear().toString().substring(2);
  const pMonth = month.toString().padStart(2, "0");
  const pDay = day.toString().padStart(2, "0");
  return `${pDay}${pMonth}${year}`;
};

export const getDateDDMMYYYY = () => {
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1; // months from 1-12
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear().toString();
  const pMonth = month.toString().padStart(2, "0");
  const pDay = day.toString().padStart(2, "0");
  return `${pDay}${pMonth}${year}`;
};


export const formatDateDDMMYYYY = (data: string) => {
  let split = data.split('-');
  return `${split[2]}/${split[1]}/${split[0]}`;
};

export const formatResponseTableReports = (data: any) => {
  let newArray: any = [];
  data.forEach((report: any) => {
    newArray.push({
      author: report.autor,
      data: formatDateDDMMYYYY(report.dataCadastro),
      hour: report.horarioCadastro,
      quantity: report.quantidade,
      resume: report.resumoPost,
      status: report.status,
      id: report.idDenuncia
    });
  });
  return newArray;
}

export const formatResponseTableOpenStatus = (data: any) => {
  let newArray: any = [];
  data.forEach((report: any) => {
    newArray.push({
      author: report.autor,
      data: formatDateDDMMYYYY(report.dataCadastro),
      hour: report.horarioCadastro,
      quantity: report.quantidade,
      resume: report.resumoPost,
      id: report.idDenuncia
    });
  });
  return newArray;
}

export const formatResponseTableClosedStatus = (data: any) => {
  let newArray: any = [];
  data.forEach((report: any) => {
    newArray.push({
      author: report.autor,
      // cpf: report.cpf,
      cpf: sensitiveCPF(generateCPF()),
      phone: report.telefone,
      email: report.email,
      data: formatDateDDMMYYYY(report.dataBloqueio),
      id: report.idDenuncia
    });
  });
  return newArray;
}

export const formatResponseTableEmergency = (data: any) => {
  let newArray: any = [];
  data.forEach((emergency: any) => {
    newArray.push({
      data: formatDateDDMMYYYY(emergency.dataCadastro),
      hour: emergency.horarioCadastro,
      type: emergency.tipoChamado,
      mike: emergency.protocoloExterno,
      status: emergency.status,
      satisfaction: emergency.satisfacao,
      id: emergency.idChamado
    });
  });
  return newArray;
}

export function generateCPF() {
  let total_array = 9;
  let n = 9;
  let [n1, n2, n3, n4, n5, n6, n7, n8, n9] = Array.from(Array(total_array), () => (Math.round(Math.random() * n)));

  let d1 = n9 * 2 + n8 * 3 + n7 * 4 + n6 * 5 + n5 * 6 + n4 * 7 + n3 * 8 + n2 * 9 + n1 * 10;
  d1 = 11 - (mod(d1, 11));
  if (d1 >= 10) d1 = 0;

  let d2 = d1 * 2 + n9 * 3 + n8 * 4 + n7 * 5 + n6 * 6 + n5 * 7 + n4 * 8 + n3 * 9 + n2 * 10 + n1 * 11;
  d2 = 11 - (mod(d2, 11));
  if (d2 >= 10) d2 = 0;
  return `${n1}${n2}${n3}${n4}${n5}${n6}${n7}${n8}${n9}${d1}${d2}`;
}

let mod = (dividendo: any, divisor: any) => Math.round(dividendo - (Math.floor(dividendo / divisor) * divisor));

function sensitiveCPF(cpf: string): string {
  let cpfSens: string;
  cpfSens = maskSens(cpf);
  return cpfSens;
}

const maskSens = (cc: any) => {
  cc = cc.split('');
  cc[3] = '*';
  cc[4] = '*';
  cc[5] = '*';
  cc[6] = '*';
  cc[7] = '*';
  cc[8] = '*';
  cc = cc.join('');
  return cc
}


