import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { SidebarOptionTitleBandComponent } from '../title-band/sidebar-option-title-band.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatComponent } from './chat/chat.component';
import { InteractionDetailsComponent } from './interaction-details/interaction-details.component';
import { PhotosComponent } from './photos/photos.component';
import { DennoucementMapComponent } from './dennoucement-map/dennoucement-map.component';
import { ButtonConfig } from '../dynamic-button/dynamic-button.model';
import { InteractionsService } from '../../../190rj/services/interactions/interactions.service';
import { LoadingService } from '../../services/loading/loading.service';
import { ToastrService } from 'ngx-toastr';
import * as toastMessages from './toastStringsInteractions.json';
import * as toastTitles from '../../../../assets/constants/toastTitlesOrTypes.json';
import { MatTooltipModule } from '@angular/material/tooltip';
import { verifyErrorIsTimeout, verifyErrorIsConection } from '../../utils';

@Component({
  selector: 'app-interactions',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SidebarOptionTitleBandComponent,
    ChatComponent,
    InteractionDetailsComponent,
    PhotosComponent,
    DennoucementMapComponent,
    FormsModule,
    MatTooltipModule
  ],
  templateUrl: './interactions.component.html',
  styleUrl: './interactions.component.scss',
})
export class InteractionsComponent implements OnInit {
  dennoucementsTypes: Array<any> = [];
  dennoucements: Array<any> = [];
  selectedDennoucement: any;
  selectedDennoucementDetails: any;
  selectedDennoucementType: number;
  searchQueryText: string;
  configButtonSearch: ButtonConfig = { label: 'Pesquisar', customWidth: '100%', handler: () => this.searchDennoucements() };
  show: boolean = false;
  isSearch: boolean = false;
  isCollapseDetails: boolean = false;
  isTypeNameDennoucementTruncated: boolean = false;

  constructor(
    private _interactionsService: InteractionsService,
    private loadingService: LoadingService,
    private toastr: ToastrService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isCollapseDetails = window.innerWidth <= 1199;
  }


  async ngOnInit() {
    this.isCollapseDetails = window.innerWidth <= 1199;
    this.loadingService.present();
    this.dennoucementsTypes = await this.getTypesDennoucements();
    this.dennoucements = await this.getDennoucements();
    this.selectedDennoucement = this.dennoucements[0];
    this.selectedDennoucementDetails = await this.getInteractionDetails(this.selectedDennoucement?.denunciaId)
    this.loadingService.dismiss();
    this.show = true;
  }

  getTypesDennoucements(): Promise<any[]> {
    return new Promise((resolve) => {
      let types = [{ ID_TipoDenuncia: 0, NM_TipoDenuncia: 'Todos' }];
      this._interactionsService.getTypesDennoucements().subscribe({
        next: (resAPI) => {
          types = [...types, ...resAPI.data];
          this.selectedDennoucementType = types[0].ID_TipoDenuncia;
          resolve(types);
        },
        error: (err) => {
          if (!verifyErrorIsTimeout(err) && !verifyErrorIsConection(err)) this.toastr.error(toastMessages.error_search_types_dennoucements, toastTitles.error_title)
          resolve([]);
        }
      })
    });
  };

  getDennoucements(dennoucementID?: number, description?: string): Promise<any[]> {
    return new Promise((resolve) => this._interactionsService.getDennoucements(dennoucementID, description).subscribe({
      next: (resAPI) => resolve(resAPI.data),
      error: (err) => {
        if (!verifyErrorIsTimeout(err) && !verifyErrorIsConection(err)) this.toastr.error(toastMessages.error_search_dennoucements, toastTitles.error_title)
        resolve([]);
      }
    }
    ));
  }

  async selectDennoucement(dennoucement: any) {
    if (dennoucement?.denunciaId !== this.selectedDennoucement?.denunciaId) {
      this.loadingService.present();
      this.selectedDennoucement = dennoucement;
      this.selectedDennoucementDetails = await this.getInteractionDetails(this.selectedDennoucement?.denunciaId)
      this.loadingService.dismiss();
    }
  }

  async searchDennoucements() {
    if (this.selectedDennoucementType || this.searchQueryText) {
      this.show = false;
      this.loadingService.present();
      this.selectedDennoucement = {};
      this.dennoucements = await this.getDennoucements(this.selectedDennoucementType, this.searchQueryText);
      this.loadingService.dismiss();
      this.isSearch = this.isSearch && Number(this.selectedDennoucementType) == 0 && !this.searchQueryText ? false : true;
      this.show = true;
    }
  }

  async clearSearch() {
    this.searchQueryText = "";
    if (this.isSearch) {
      this.loadingService.present();
      this.selectedDennoucement = {};
      this.show = false;
      this.selectedDennoucementType = 0;
      this.dennoucements = await this.getDennoucements();
      this.loadingService.dismiss();
      this.isSearch = false;
      this.show = true;
    }
  }

  getInteractionDetails(dennoucementID: number): Promise<any> {
    return new Promise((resolve) => {
      this._interactionsService.getDennoucementsDetails(dennoucementID).subscribe({
        next: (chatDetails) => {
          if (chatDetails?.data?.imagens?.length == 0) chatDetails.data.imagens = [{
            imagemKey: this.selectedDennoucement?.iconeNome.replace('data:image/png', 'data:image/svg+xml'),
            defaultPhoto: true,
          }]
          else if (chatDetails?.data?.imagens) chatDetails.data.imagens = this.fillImagesNulls(chatDetails.data.imagens);
          chatDetails.data.dados.iconDefault = this.selectedDennoucement?.iconeNome;
          resolve(chatDetails?.data);
        },
        error: (err) => {
          if (!verifyErrorIsTimeout(err) && !verifyErrorIsConection(err)) this.toastr.error(toastMessages.error_search_details, toastTitles.error_title);
          resolve([])
        }
      });
    })
  }

  fillImagesNulls(arrayImages: any[]) {
    arrayImages.forEach((image: any) => {
      if (!image.imagemKey) image.imagemKey = this.selectedDennoucement?.iconeNome.replace('data:image/png', 'data:image/svg+xml');
    });
    return arrayImages;
  }

  isNotEmptyObject(obj: any): boolean {
    return obj !== null && typeof obj === 'object' && Object.keys(obj).length > 0;
  }

  checkTextOverflow(element: HTMLElement): void {
    this.isTypeNameDennoucementTruncated = element.scrollWidth > element.clientWidth;
  }

}
