import { Component, Input, OnInit } from '@angular/core';
import { ButtonConfig } from './dynamic-button.model';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';

@Component({
  selector: 'app-dynamic-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dynamic-button.component.html',
  styleUrl: './dynamic-button.component.scss'
})
export class DynamicButtonComponent implements OnInit {
  @Input() buttonConfig: ButtonConfig;
  urlActive: string;
  portalTypes = PORTAL_TYPE_URLS;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.urlActive = this.router.url;
  }

}
