import { Injectable } from '@angular/core';
import Chart from 'chart.js/auto';

import { of } from 'rxjs';
import { UserAnalysisDataset } from '../models/user-analysis-dataset';
import { PORTAL_TYPES } from '../../../../enums/portal-types.enum';

@Injectable({
  providedIn: 'root',
})
export class DailyUserAnalysisChartService {
  chart: any;

  constructor() {}

  getChartData(filter: any, portalType: PORTAL_TYPES) {
    if (portalType === PORTAL_TYPES.RJ_190) {
      return of({
        following: {
          dataset: new UserAnalysisDataset(
            [100, 30],
            ['#FEBF0F', '#f1f2f3'],
            180
          ),
        },
        unfollowing: {
          dataset: new UserAnalysisDataset(
            [100, 25],
            ['#895BF1', '#f1f2f3'],
            0
          ),
        },
      });
    } else if (portalType === PORTAL_TYPES.REDE_ESCOLA) {
      return of({
        following: {
          dataset: new UserAnalysisDataset(
            [100, 30],
            ['#0090FF', '#f1f2f3'],
            180
          ),
        },
        unfollowing: {
          dataset: new UserAnalysisDataset(
            [100, 25],
            ['#26C6DA', '#f1f2f3'],
            0
          ),
        },
      });
    }

    return of({
      following: {
        dataset: new UserAnalysisDataset(
          [100, 30],
          ['#FEBF0F', '#f1f2f3'],
          180
        ),
      },
      unfollowing: {
        dataset: new UserAnalysisDataset([100, 25], ['#895BF1', '#f1f2f3'], 0),
      },
    });
  }

  buildChart(
    labels: string[],
    dataset: UserAnalysisDataset,
    chartName: string
  ) {
    return new Chart('user-analysis-' + chartName, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [dataset],
      },
      options: {
        cutout: 48,
        responsive: true,
        maintainAspectRatio: false,
        events: ['mousemove'],
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    });
  }
}
