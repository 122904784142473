<section class="portal-header--container">
  <app-sidebar-option-title-band></app-sidebar-option-title-band>
</section>
<section class="portal-body--container relative px-0" *ngIf="show">
  <div class="container-fluid">
    <div class="row">
      <div class="col_title col-12">
        <div>
          <p class="portal-body__title">Interações Usuários</p>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row portal-body__interactions--grid">
      <input type="checkbox" id="check-three-points">
      <label for="check-three-points" class="checkbtn">
        <div class="point"></div>
        <div class="point"></div>
        <div class="point"></div>
      </label>
      <div class="col-3 px-0 interactions__users-list--card">
        <div class="w-100 px-25">
          <select class="form-select mb-12" [(ngModel)]="selectedDennoucementType">
            @for (type of dennoucementsTypes; track $index) {
            <option [value]="type.ID_TipoDenuncia">{{type.NM_TipoDenuncia}}</option>
            }
          </select>
        </div>
        <div class="actions_box">
          <div class="w-100 interactions__users_list--form position-relative">
            <input class="pl-10 w-100" [ngClass]="{'align_two_buttons_right': isSearch }" type="text"
              placeholder="Pesquisar por Denúncia" [(ngModel)]="searchQueryText" />
          </div>
          <div class="button_box">
            <button class="button_search" (click)="searchDennoucements()"><img
                src="../../../../assets/imgs/magnifying-glass_white.svg"></button>
            @if(isSearch) {
            <button class="button_clear" (click)="clearSearch()"><img
                src="../../../../assets/imgs/trash_white.svg"></button>
            }
          </div>
        </div>
        <div class="interactions__user-list--container mt-12 ml-25">
          @for(dennoucement of dennoucements; track dennoucement.denunciaId){
          <div class="interactions__dennoucements_type-list--grid"
            [ngClass]="{'selected': dennoucement?.denunciaId === selectedDennoucement?.denunciaId }"
            (click)="selectDennoucement(dennoucement)">
            <div class="position-relative">
              <img [ngStyle]="{'border': '3px solid ' + dennoucement?.tipoDenunciaCor.substring(0, 7) }"
                [src]="dennoucement?.iconeNome.replace('data:image/png', 'data:image/svg+xml')" />
            </div>
            <div #tooltipTrigger matTooltip="{{dennoucement?.tipoDenunciaNome }}"
              [matTooltipDisabled]="!isTypeNameDennoucementTruncated" matTooltipPosition="above"
              matTooltipClass="custom_tooltip" class="ml-5 my-auto username"
              (mouseover)="checkTextOverflow(tooltipTrigger)">
              {{dennoucement?.tipoDenunciaNome }}</div>

            <div class="d-flex align-items-center justify-content-end">
              @if(dennoucement?.quantidadeMensagens){
              <div class="interactions__user-messages-count--circle" matTooltip="Mensagens Enviadas"
                matTooltipPosition="above" matTooltipClass="custom_tooltip">
                <div class="interactions__user-messages-count--value">
                  {{ dennoucement?.quantidadeMensagens }}
                </div>
              </div>
              }
            </div>
          </div>
          } @empty {
          <div class="no_dennoucements--box">
            <span class="no_dennoucements">Sem denúncias</span>
          </div>
          }
        </div>
      </div>
      <div class="col-4 px-0">
        @if(isNotEmptyObject(selectedDennoucement) && dennoucements.includes(selectedDennoucement)) {
        <app-chat [dennoucementID]="selectedDennoucement?.denunciaId"></app-chat>
        }
      </div>

      <div [ngClass]="{'interactions_collapse_details': isCollapseDetails, 'col-4 px-0': !isCollapseDetails}">
        @if(isNotEmptyObject(selectedDennoucement) && dennoucements.includes(selectedDennoucement)) {
        <div class="closebtn">
          <label for="check-three-points"><img src="assets/imgs/close_gray.svg"></label>
        </div>
        <app-interaction-details [dennoucementDetails]="selectedDennoucementDetails?.dados"></app-interaction-details>
        <app-interaction-photos [photos]="selectedDennoucementDetails?.imagens"></app-interaction-photos>
        <app-dennoucement-map [address]="selectedDennoucementDetails?.endereco"></app-dennoucement-map>
        }
      </div>
    </div>
  </div>
</section>
