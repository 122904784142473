export const BAR_CHART_COLORS = {
  RJ_190: {
  USERS: ['#895BF1', '#0091ff50'],
    USERS2: ['#FE6BBA99', '#895BF199'],
  },
  REDE_ESCOLA: {
    USERS: ['#0090FF', '#26C6DA'],
    USERS2: ['#0090FF99', 'rgba(38, 198, 218, 0.4)'],
  },
};
