import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FilterCardsService } from '../../shared/components/filter-card/services/filter-cards.service';
import { PORTAL_TYPES_NAMES } from '../../shared/enums/portal-types-names.enum';
import { PORTAL_TYPES } from '../../shared/enums/portal-types.enum';
import { PortalTypeService } from '../../shared/services/portal-type.service';



@Component({
  selector: 'app-portal-rede-mulher',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './portal-rede-mulher.component.html',
  styleUrl: './portal-rede-mulher.component.scss',
})
export class PortalRedeMulherComponent implements OnInit {
  constructor(
    private readonly _portalFilterCardsService: FilterCardsService,
    private readonly _portalTypeService: PortalTypeService
  ) {}

  ngOnInit(): void {
    this.initializePortalType();
    this.emitNewPortalFilterCards();
  }

  initializePortalType() {
    this._portalTypeService.currentPortalType$.next({
      type: PORTAL_TYPES.REDE_MULHER,
      name: PORTAL_TYPES_NAMES.REDE_MULHER,
    });
  }

  emitNewPortalFilterCards() {
    this._portalFilterCardsService.emitNewPortalFilterCards(
      PORTAL_TYPES.REDE_MULHER
    );
  }
}
