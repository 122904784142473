<div class="portal-body__bar-chart-card position-relative">
  <div class="align-right">
    <div class="line-chart__form">
      <select class="form-select" disabled>
        <option [value]="currentYearAndMonth">{{ currentYearAndMonth }}</option>
      </select>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-between mb-30">
    <div class="line-chart--title">{{ title }}</div>
  </div>

  <section class="chart--container pb-10">
    <canvas id="myChart-Bar"></canvas>
  </section>

  <div class="subtitles">
    @for(subtitle of subtitles; track subtitle) {
    <div class="subtitle__div">
      <div class="colorBlock" [ngStyle]="{'background-color': subtitle.blockColor}"></div>
      <span>{{subtitle.title}}</span>
    </div>
    }
  </div>
</div>
