<section class="portal-header--container">
  <app-sidebar-option-title-band></app-sidebar-option-title-band>
</section>
<div *ngIf="showPage" class="view_user__container--grid">
  <div></div>
  <div>
    <div class="title-box">
      <div class="row border-bottom-0 pb-0">
        <div class="div-col-title col-lg-6 col-xl-6 col-12">
          <p class="portal-body__title mb-0">Usuário</p>
        </div>
        <div class="div-col-back col-lg-6 col-xl-6 col-12">
          <app-dynamic-button [buttonConfig]="configButtonBack"></app-dynamic-button>
        </div>
      </div>
    </div>
    <div class="portal-body__view-user-card">
      <div class="container-fluid">
        <div class="row">
          <div class="user-img-box col-xxl-4 col-12">
            <div class="img" [ngStyle]="{'background-image': imgProfile}">
            </div>
          </div>
          <div class="box-texts col-xxl-8 col-12 no-padding">
            <div class="view_user__row--title col-12 no-padding">{{userData.name}}</div>
            <div class="view_user__row--text col-12 no-padding mb-15">{{userData.age}} anos</div>
            <div class="view_user__row--text col-12 no-padding d-flex mb-15">
              <span class="view_user__row--text "><b>CPF:</b> {{userData.cpf | mask: '000.000.000-00'}}</span>
            </div>
            <div class="view_user__row--text col-12 no-padding d-flex mb-15">
              <span class="view_user__row--text "><b>Telefone:</b> {{userData.phone | mask: '(00) 00000-0000'}}</span>
            </div>
            <div class="view_user__row--text col-12 no-padding d-flex mb-15">
              <span class="view_user__row--text "><b>E-mail:</b> {{userData.email}}</span>
            </div>
            <div class="view_user__row--text col-12 no-padding d-flex mb-15">
              <span class="view_user__row--text "><b>Status:</b> {{userData.status}}</span>
            </div>
            <div class="view_user__row--text col-12 no-padding">
              <span class="view_user__row--text "><b>Device:</b> {{userData.device}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</div>
