import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../rest-api/rest-api.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { authSecretKey, isTokenExpired } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly baseUrl = environment.baseUrlAuth;

  constructor(
    private restApiService: RestApiService,
    private localStorage: LocalStorageService,
  ) { }

  login(email: string, password: string) {
    const endpoint = `${this.baseUrl}auth/login`;
    const body = {
      email: email,
      password: password
    };
    return this.restApiService
      .post(endpoint, body);
  }

  isAuthenticatedUser(): boolean {
    let token: any;
    this.localStorage.getItem(authSecretKey).subscribe((res) => {
      token = res;
    });

    if (token && !isTokenExpired(token)) return true;
    return false;
  }

  logout(): void {
    this.localStorage.removeItem(authSecretKey);
  }
}
