import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';

import { PortalFilterCard } from '../../../models/portal-filter-card';
import { PortalTypeService } from '../../../services/portal-type.service';
import { SidebarOptionService } from '../../sidebar/services/sidebar-option.service';
import { PORTAL_FILTER_CARD_IMAGES } from '../enums/filter-card-images.enum';
import { PORTAL_FILTER_CARD_TITLES } from '../enums/filter-card-titles.enum';
import { FilterCardDTO } from '../models/filter-card.dto';
import { PORTAL_TYPES } from '../../../enums/portal-types.enum';

@Injectable({
  providedIn: 'root',
})
export class FilterCardsService {
  currentPortalFilterCard$: BehaviorSubject<PortalFilterCard | null> =
    new BehaviorSubject<PortalFilterCard | null>(null);

  portalFilterCard$: BehaviorSubject<PortalFilterCard[]> = new BehaviorSubject<
    PortalFilterCard[]
  >([]);

  constructor(
    private _portalTypeService: PortalTypeService,
    private _sidebarOptionService: SidebarOptionService
  ) { }

  updatePortalFilterCard(newPortalFilterCard: PortalFilterCard) {
    this.currentPortalFilterCard$.next(newPortalFilterCard);
  }

  resetPortalFilterCard() {
    this.currentPortalFilterCard$.next(null);
  }

  getValue() {
    return this.currentPortalFilterCard$.getValue();
  }

  emitNewPortalFilterCards(portalType?: PORTAL_TYPES, filterCards?: any) {
    let mock$;
    if (!filterCards) {
      if (portalType === PORTAL_TYPES.RJ_190) {
        mock$ = of([
          { id: 1, title: 'Usuários', value: 0 },
          { id: 2, title: 'Emergências', value: 0 },
          { id: 3, title: 'Denúncias', value: 0 },
          { id: 4, title: 'Desaparecidos', value: 0 },
        ]);
      } else if (portalType === PORTAL_TYPES.REDE_MULHER) {
        mock$ = of([
          { id: 1, title: 'Usuários', value: 0 },
          { id: 2, title: 'Emergências', value: 0 },
          { id: 3, title: 'Reportados', value: 0 },
          { id: 4, title: 'Agressores', value: 0 },
        ]);
      } else {
        mock$ = of([
          { id: 1, title: 'Usuários', value: 10000 },
          { id: 2, title: 'Escolas cadastradas', value: 10000 },
          { id: 3, title: 'Denúncias', value: 10000 },
          { id: 4, title: 'Emergências', value: 10000 },
        ]);
      }
    } else {
      mock$ = of(filterCards);
    }

    return mock$.subscribe((portalCardsDTO: Array<FilterCardDTO>) => {
      const portalCards: Array<PortalFilterCard> = portalCardsDTO.map(
        (portalCard: FilterCardDTO) => {
          return {
            ...portalCard,
            active: true,
            imgName: this.setImageName(portalCard),
            selectedSidebarOption:
              this._sidebarOptionService.currentSidebarOption$.value,
            selectedPortal: this._portalTypeService.currentPortalType$.value,
          };
        }
      );

      this.portalFilterCard$.next(portalCards);
      this.currentPortalFilterCard$.next(portalCards[0]);
    });
  }

  setImageName(portalCard: FilterCardDTO) {
    switch (portalCard.title) {
      //Rj190
      case PORTAL_FILTER_CARD_TITLES.RJ_190_USERS ||
        PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS ||
        PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_USERS:
        return PORTAL_FILTER_CARD_IMAGES.RJ_190_USERS;
      case PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES:
        return PORTAL_FILTER_CARD_IMAGES.RJ_190_EMERGENCIES;
      case PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS:
        return PORTAL_FILTER_CARD_IMAGES.RJ_190_DENOUNCEMENTS;
      case PORTAL_FILTER_CARD_TITLES.RJ_190_MISSING:
        return PORTAL_FILTER_CARD_IMAGES.RJ_190_MISSING;

      case PORTAL_FILTER_CARD_TITLES.RJ_190_REPORTS:
        return PORTAL_FILTER_CARD_IMAGES.RJ_190_REPORTS;
      case PORTAL_FILTER_CARD_TITLES.RJ_190_OPEN_STATUS:
        return PORTAL_FILTER_CARD_IMAGES.RJ_190_OPEN_STATUS;
      case PORTAL_FILTER_CARD_TITLES.RJ_190_BLOCKED:
        return PORTAL_FILTER_CARD_IMAGES.RJ_190_BLOCKED;
      case PORTAL_FILTER_CARD_TITLES.RJ_190_CLOSED_STATUS:
        return PORTAL_FILTER_CARD_IMAGES.RJ_190_CLOSED_STATUS;

      //Rede Mulher
      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES:
        return PORTAL_FILTER_CARD_IMAGES.REDE_MULHER_EMERGENCIES;
      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_REPORTED:
        return PORTAL_FILTER_CARD_IMAGES.REDE_MULHER_REPORTED;
      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR:
        return PORTAL_FILTER_CARD_IMAGES.REDE_MULHER_AGRESSOR;

      //Rede Escola
      case PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_SCHOOLS:
        return PORTAL_FILTER_CARD_IMAGES.REDE_ESCOLA_SCHOOLS;
      case PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS:
        return PORTAL_FILTER_CARD_IMAGES.REDE_ESCOLA_DENOUNCEMENTS;
      case PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_EMERGENCIES:
        return PORTAL_FILTER_CARD_IMAGES.REDE_ESCOLA_EMERGENCIES;

      default:
        return PORTAL_FILTER_CARD_TITLES.RJ_190_USERS;
    }
  }
}
