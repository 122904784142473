import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor() {}

  getChatMessages() {
    return of([
      {
        id: 1,
        userName: 'Lorem Ipsum',
        timeOfMessage: '10:24',
        messageContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      },
      {
        id: 2,
        userName: 'Lorem Ipsum',
        timeOfMessage: '7 minutes ago',
        messageContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      },
      {
        id: 3,
        userName: 'Lorem Ipsum',
        timeOfMessage: '10:24',
        messageContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      },
      {
        id: 4,
        userName: 'Lorem Ipsum',
        timeOfMessage: '7 minutes ago',
        messageContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      },
      {
        id: 5,
        userName: 'Lorem Ipsum',
        timeOfMessage: '10:24',
        messageContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      },
      {
        id: 6,
        userName: 'Lorem Ipsum',
        timeOfMessage: '7 minutes ago',
        messageContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      },
      {
        id: 7,
        userName: 'Lorem Ipsum',
        timeOfMessage: '10:24',
        messageContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      },
    ]);
  }
}
