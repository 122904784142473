import { Injectable } from '@angular/core';
import Chart from 'chart.js/auto';
import { BarDataset } from '../models/bar-dataset';
import { BAR_CHART_COLORS } from '../consts/bar-chart-colors.const';
import { PORTAL_FILTER_CARD_TITLES } from '../../filter-card/enums/filter-card-titles.enum';
import { PORTAL_TYPES } from '../../../enums/portal-types.enum';
import { PortalFilterCard } from '../../../models/portal-filter-card';
import { firstValueFrom } from 'rxjs';
import { RedeMulherService } from '../../../services/rede-mulher/rede-mulher.service';
import { ToastrService } from 'ngx-toastr';
import { Subtitle } from '../../line-chart/models/line-dataset';
import { GeneralPanel190RjService } from '../../../../190rj/services/general-panel190rj/general-panel190RJ.service';
import { verifyErrorIsTimeout, verifyErrorIsConection } from '../../../utils';

@Injectable({
  providedIn: 'root',
})
export class BarChartService {
  chart: any;
  days: any = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  constructor(
    private generalPanelRJ190: GeneralPanel190RjService,
    private redeMulherService: RedeMulherService,
    private toastr: ToastrService
  ) { }

  async getChartData(portalType: PORTAL_TYPES, currentFilterCard: PortalFilterCard) {
    if (
      portalType === PORTAL_TYPES.RJ_190 &&
      currentFilterCard.title === PORTAL_FILTER_CARD_TITLES.RJ_190_USERS
    ) {
      let valuesActives: any[] = [];
      let valuesInactives: any[] = [];
      let labels: any[] = [];
      let subtitles: Subtitle[] = [];
      await firstValueFrom(this.generalPanelRJ190.statiscsMonthlyUsers()).then((response) => {
        //mock
        // response = [
        //   { dia: '01', mes: 2, ano: 2025, ativos: 12, inativos: 10 },
        //   { dia: '02', mes: 2, ano: 2025, ativos: 5, inativos: 11 },
        //   { dia: '03', mes: 2, ano: 2025, ativos: 4, inativos: 2 },
        //   { dia: '04', mes: 2, ano: 2025, ativos: 5, inativos: 3 },
        //   { dia: '05', mes: 2, ano: 2025, ativos: 20, inativos: 5 },
        //   { dia: '06', mes: 2, ano: 2025, ativos: 5, inativos: 20 },
        //   { dia: '07', mes: 2, ano: 2025, ativos: 2, inativos: 6 },
        //   { dia: '08', mes: 2, ano: 2025, ativos: 3, inativos: 21 },
        //   { dia: '09', mes: 2, ano: 2025, ativos: 6, inativos: 5 },
        //   { dia: 10, mes: 2, ano: 2025, ativos: 7, inativos: 2 },
        //   { dia: 11, mes: 2, ano: 2025, ativos: 8, inativos: 3 },
        //   { dia: 12, mes: 2, ano: 2025, ativos: 4, inativos: 10 },
        //   { dia: 13, mes: 2, ano: 2025, ativos: 2, inativos: 10 },
        //   { dia: 14, mes: 2, ano: 2025, ativos: 4, inativos: 5 },
        //   { dia: 15, mes: 2, ano: 2025, ativos: 4, inativos: 10 },
        //   { dia: 16, mes: 2, ano: 2025, ativos: 9, inativos: 54 },
        //   { dia: 17, mes: 2, ano: 2025, ativos: 4, inativos: 39 },
        //   { dia: 18, mes: 2, ano: 2025, ativos: 22, inativos: 10 },
        //   { dia: 19, mes: 2, ano: 2025, ativos: 21, inativos: 23 },
        //   { dia: 20, mes: 2, ano: 2025, ativos: 25, inativos: 10 },
        //   { dia: 21, mes: 2, ano: 2025, ativos: 24, inativos: 19 },
        //   { dia: 22, mes: 2, ano: 2025, ativos: 17, inativos: 18 },
        //   { dia: 23, mes: 2, ano: 2025, ativos: 12, inativos: 17 },
        //   { dia: 24, mes: 2, ano: 2025, ativos: 12, inativos: 16 },
        //   { dia: 25, mes: 2, ano: 2025, ativos: 16, inativos: 9 },
        //   { dia: 26, mes: 2, ano: 2025, ativos: 15, inativos: 8 },
        //   { dia: 27, mes: 2, ano: 2025, ativos: 22, inativos: 2 },
        //   { dia: 28, mes: 2, ano: 2025, ativos: 21, inativos: 1 },
        //   { dia: 29, mes: 2, ano: 2025, ativos: 34, inativos: 6 },
        //   { dia: 30, mes: 2, ano: 2025, ativos: 34, inativos: 12 },
        //   { dia: 31, mes: 2, ano: 2025, ativos: 39, inativos: 11 },
        // ];
        const d = new Date();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        response.forEach((element: any) => {
          if (element.ano == year) {
            if (element.mes == month) {
              var d = new Date(`${month}/${element.dia}/${year}`);
              var dayName = this.days[d.getDay()];
              valuesActives.push(element.ativos);
              valuesInactives.push(-parseInt(element.inativos));
              if (element.dia.toString().length < 2) element.dia = `0${element.dia}`
              labels.push(`${element.dia}. ${dayName}`);
            }
          }
        });
        subtitles.push({ blockColor: BAR_CHART_COLORS.RJ_190.USERS[0], title: 'Ativos' }, { blockColor: BAR_CHART_COLORS.RJ_190.USERS[1], title: 'Inativos' })
      }).catch((err) => {
        if (!verifyErrorIsTimeout(err) && !verifyErrorIsConection(err)) this.openToastError('Erro ao buscar estatísticas mensal de usuários!');
        valuesActives = [];
        valuesInactives = [];
      });
      return [
        new BarDataset(
          valuesActives,
          BAR_CHART_COLORS.RJ_190.USERS[0],
          labels,
          20,
          20,
          subtitles
        ),
        new BarDataset(
          valuesInactives,
          BAR_CHART_COLORS.RJ_190.USERS[1],
          []
        ),
      ];
    }

    else if (
      portalType === PORTAL_TYPES.REDE_ESCOLA &&
      currentFilterCard.title === PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_USERS
    ) {
      let labels: any[] = [
        '01. Dom',
        '02. Seg',
        '03. Ter',
        '04. Qua',
        '05. Qui',
        '06. Sex',
        '07. Sáb',
        '08. Dom',
        '09. Seg',
        '10. Ter',
        '11. Qua',
        '12. Qui',
        '13. Sex',
        '14. Sáb',
        '15. Dom',
        '16. Seg',
        '17. Ter',
        '18. Qua',
        '19. Qui',
        '20. Sex',
        '21. Sáb',
        '22. Dom',
        '23. Seg',
        '24. Ter',
        '25. Qua',
        '26. Qui',
        '27. Sex',
        '28. Sáb',
        '29. Dom',
        '30. Seg',
        '31. Ter'];
      return [
        new BarDataset(
          [500, 1000, 500, 1000, 500, 500, 1000, 750, 1000, 500],
          BAR_CHART_COLORS.REDE_ESCOLA.USERS[0],
          labels
        ),
        new BarDataset(
          [-500, -750, -500, -500, -500, -500, -750, -500, -750, -500],
          BAR_CHART_COLORS.REDE_ESCOLA.USERS2[0],
          []
        ),
      ];
    }

    return [
      new BarDataset(
        [500, 1000, 500, 1000, 500, 500, 1000, 750, 1000, 500],
        BAR_CHART_COLORS.RJ_190.USERS[0],
        []
      ),
      new BarDataset(
        [-500, -750, -500, -500, -500, -500, -750, -500, -750, -500],
        BAR_CHART_COLORS.RJ_190.USERS2[0],
        []
      ),
    ];
  }

  buildChart(labels: string[], dataset: BarDataset[]): any {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart('myChart-Bar', {
      type: 'bar',
      data: {
        labels: labels,
        datasets: dataset,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        events: ['mousemove'],
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            backgroundColor: '#85A3BBCC',
            displayColors: true,
            cornerRadius: 4,
            callbacks: {
              label: function (context) {
                let label = '';
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('pt-BR').format(
                    context.parsed.y
                  );
                }
                return label;
              },
              labelColor: function (context) {
                return {
                  borderColor: '#85A3BBCC',
                  backgroundColor: '',
                  borderWidth: 0,
                  borderRadius: 4,
                };
              },
              labelTextColor: function (context) {
                return '#FFFFFF';
              },
            },
          },
        },
        scales: {
          y: {
            stacked: true,
            grid: {
              lineWidth: ({ tick }) => (tick.value == 0 ? 5 : 1),
              color: ({ tick }) => (tick.value == 0 ? '#99B2C6' : '#D9E1E7'),
            },
            ticks: {
              color: '#B3B8BD',
              callback: function (value, index, ticks) {
                let formatedValue = value.toString();
                if (Math.abs(+value) >= 1000) {
                  formatedValue = +value / 1000 + 'k';
                }
                return formatedValue;
              },
            },
          },
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            beginAtZero: true,
            ticks: {
              color: '#B3B8BD',
            },
          },
        },
      },
    });
  }

  openToastError(text: string): void {
    this.toastr.error(text, 'Erro!');
  }
}
