import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { BarDataset } from './models/bar-dataset';
import { FilterCardsService } from '../filter-card/services/filter-cards.service';
import { PortalFilterCard } from '../../models/portal-filter-card';
import { BarChartService } from './services/bar-chart.service';
import { PortalTypeService } from '../../services/portal-type.service';
import { PORTAL_TYPES } from '../../enums/portal-types.enum';
import { first } from 'rxjs';
import { MONTHS } from '../line-chart/consts/months.const';
import { Subtitle } from '../line-chart/models/line-dataset';

@Component({
  selector: 'app-bar-chart',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.scss',
})
export class BarChartComponent implements OnInit {
  title: string =
    'Estatística Mensal ' +
    this._filterCardsService.currentPortalFilterCard$.value?.title;
  @Input() hasDateFilter: boolean = true;
  @Input() hasTypeFilter: boolean = true;

  formBuilder: FormBuilder = new FormBuilder();
  formGroup: FormGroup = new FormGroup([]);
  currentYearAndMonth: string;
  subtitles: Subtitle[] | undefined = [];

  constructor(
    private readonly _barChartService: BarChartService,
    private readonly _filterCardsService: FilterCardsService,
    private _portalTypeService: PortalTypeService
  ) { }

  ngOnInit() {
    this.listenToChangesOnFilterCards();
    this.currentYearAndMonth = `${MONTHS[new Date().getMonth()]}/${new Date().getFullYear()}`
  }


  listenToChangesOnFilterCards() {
    this._filterCardsService.currentPortalFilterCard$.pipe(
      first()
    ).subscribe(
      {
        next: async (selectedPortalFilterCard: PortalFilterCard | null) => {
          if (
            selectedPortalFilterCard &&
            document.getElementById('myChart-Bar')
          ) {
            this.title = 'Estatística Mensal ' + selectedPortalFilterCard.title;
            const portalType: PORTAL_TYPES =
              this._portalTypeService.currentPortalType$.value.type;
            await this.updateDataset(portalType, selectedPortalFilterCard);
          }
        },
      }
    );
  }

  async updateDataset(portalType: PORTAL_TYPES, currentFilterCard: PortalFilterCard) {
    const datasets: BarDataset[] = await this._barChartService.getChartData(
      portalType,
      currentFilterCard
    );
    this.subtitles = datasets[0].subtitles;
    this._barChartService.buildChart(datasets[0].labels, datasets);
  }
}
