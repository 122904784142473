import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { ModalService } from '../modal/services/modal.service';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { SidebarOptionTitleBandComponent } from '../title-band/sidebar-option-title-band.component';
import { EXTRAS_PAGES_URLS } from '../../enums/extras-pages-urls.enum';
import { LoadingService } from '../../services/loading/loading.service';
import { AnnoucementsService } from '../../services/190rj/annoucements-page/annoucements.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-announcement',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SidebarOptionTitleBandComponent],
  templateUrl: './announcement.component.html',
  styleUrl: './announcement.component.scss',
})
export class AnnouncementComponent implements OnInit, OnDestroy {
  formGroup: FormGroup = new FormGroup({});
  annoucements: any = null;
  disableButtons: boolean = true;
  currentPage: number = 1;
  totalPageIndexData: number;
  numberPages: any;
  pages: Array<number> = [];
  lastPageIndexData: any;
  initPageIndexData: any;
  portalURLS = PORTAL_TYPE_URLS;
  currentURL: string = '';
  headerTitles = ['', 'Imagem', 'Ordem', 'Nome do arquivo', 'Link', 'Status', ''];
  show: boolean = false;
  onlyTruesBanners: Array<string> = [];
  subscribeForm: Subscription;

  constructor(
    private _formBuilder: FormBuilder,
    private annoucementsService: AnnoucementsService,
    private _router: Router,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private toastr: ToastrService
  ) { }

  async ngOnInit() {
    this.loadingService.present();
    this.setCurrentURL();
    this.initializeForm();
    await this.getAnnoucements(this.currentPage);
    this.show = true;
    this.loadingService.dismiss();
  };

  ngOnDestroy(): void {
    if (this.subscribeForm) this.subscribeForm.unsubscribe();
  }

  initializeForm() {
    this.formGroup = this._formBuilder.group({});
  }

  setCurrentURL() {
    this.currentURL = this._router.url;
  }

  getAnnoucements(queryPage: number): Promise<boolean> {
    return new Promise(async resolve => {
      let responseAPI = await this.getAnnouncementsData(queryPage);
      if (responseAPI.data.length > 0) {
        this.clearFormGroup();
        this.getNumPages(responseAPI);
        this.annoucements = responseAPI.data;
        this.annoucements.forEach((annoucement: any) => {
          this.formGroup.addControl(annoucement.idBanner, new FormControl(false));
        });
        this.subscribeForm = this.formGroup.valueChanges.subscribe((annoucementsClicks: any) => {
          this.disableButtons = Object.values(annoucementsClicks).indexOf(true) === -1;
          this.clickAnnoucementGetIds();
        });
        resolve(true);
      } else {
        this.annoucements = [];
        resolve(true);
      }
    })
  };


  clickAnnoucementGetIds() {
    for (const property in this.formGroup.value) {
      if (this.formGroup.value[property] && !this.onlyTruesBanners.includes(property)) this.onlyTruesBanners.push(property);
      else if (!this.formGroup.value[property]) this.onlyTruesBanners = this.onlyTruesBanners.filter(idBanner => idBanner !== property);
    };
  }
  clearFormGroup() {
    Object.keys(this.formGroup.controls).forEach((controlName) => {
      this.formGroup.removeControl(controlName);
    });
  };

  public getNumPages(responseAPI: any) {
    this.numberPages = Math.ceil(responseAPI.total / 5);
    this.pages = this.paginateLinks(this.numberPages);
    this.buildNumberIndexData(responseAPI);
  };

  public paginateLinks(links: any) {
    let newLinksArray: any = [];
    links = Array.from({ length: links }, (_, i) => i + 1);
    let max: number = 0;
    this.currentPage == 1 ? max = this.currentPage + 2 : max = this.currentPage + 1;
    let min = this.currentPage - 1;
    links.forEach((link: any) => {
      if (link >= min && link <= max) {
        newLinksArray.push(link);
      }
    });
    return newLinksArray;
  };

  public buildNumberIndexData(responseAPI: any) {
    this.lastPageIndexData = this.currentPage * 5;
    this.initPageIndexData = this.currentPage * 5 - 5;
    this.totalPageIndexData = responseAPI.data.length;
    if (this.currentPage == 1) this.initPageIndexData = 1;
    if (this.lastPageIndexData > responseAPI.data.length) this.lastPageIndexData = responseAPI.data.length;
  }

  public swapPage(nextPage: boolean) {
    if (nextPage) this.currentPage += 1;
    else this.currentPage -= 1;
    this.changeAnnoucementsArray();
  };

  private async changeAnnoucementsArray() {
    this.subscribeForm.unsubscribe();
    this.loadingService.present();
    this.clearFormGroup();
    let responseAPI = await this.getAnnouncementsData(this.currentPage);
    this.annoucements = responseAPI.data;
    if (this.annoucements.length > 0) {
      this.annoucements.forEach((annoucement: any) => {
        this.formGroup.addControl(annoucement.idBanner, new FormControl(false));
      });
      this.subscribeForm = this.formGroup.valueChanges.subscribe((annoucementsClicks: any) => {
        this.disableButtons = Object.values(annoucementsClicks).indexOf(true) === -1;
        this.clickAnnoucementGetIds();
      });
    }
    this.loadingService.dismiss();
  }


  public activePage(pageNumber: number, click?: boolean) {
    if (pageNumber != this.currentPage) {
      this.currentPage = pageNumber;
      if (click) this.changeAnnoucementsArray();
    };
  };

  getAnnouncementsData(page: number): Promise<any> {
    return new Promise(resolve => {
      this.annoucementsService.getAnnoucements(5, page).subscribe({
        next: (annoucements: any) => {
          resolve(annoucements);
        },
        error: () => resolve({ data: [] }),
      });
    });
  }



  async changeAnnoucementsState(operation: string) {
    this.loadingService.present();
    let changeArray = false;
    if (operation === 'activate') {
      await firstValueFrom(this.annoucementsService.activeBanner(this.onlyTruesBanners)).then(() => {
        changeArray = true;
      }).catch(() => this.toastr.error('Erro ao ativar os banners!', 'Erro!'));
    } else if (operation === 'deactivate') {
      await firstValueFrom(this.annoucementsService.inactiveBanner(this.onlyTruesBanners)).then(() => {
        changeArray = true;
      }).catch(() => this.toastr.error('Erro ao inativar os banners!', 'Erro!'));
    } else {
      await firstValueFrom(this.annoucementsService.deleteBanner(this.onlyTruesBanners)).then(() => {
        changeArray = true;
      }).catch(() => this.toastr.error('Erro ao deletar os banners!', 'Erro!'));
    };
    if (changeArray) {
      this.updateArray(operation);
      this.formGroup.reset();
    };
    this.loadingService.dismiss();
  };

  private updateArray(operation: string) {
    this.onlyTruesBanners.forEach(idBannerClick => {
      if (operation === 'activate') {
        this.annoucements.find(
          (annoucement: any) => annoucement.idBanner == idBannerClick
        ).status = 'true';
      } else if (operation === 'deactivate') {
        this.annoucements.find(
          (annoucement: any) => annoucement.idBanner == idBannerClick
        ).status = 'false';
      } else {
        const deleteIndex: number = this.annoucements.findIndex(
          (annoucement: any) => annoucement.idBanner == idBannerClick
        );
        console.log(deleteIndex)
        this.annoucements.splice(deleteIndex, 1);
      }
    });
  }

  goToAnnoucementDetail(idBanner?: any) {
    this._router.navigate([this._router.url + '/' + EXTRAS_PAGES_URLS.ADD_ANNOUNCEMENTS], { queryParams: { id: idBanner } });
  }

  openModal(modalTemplate: TemplateRef<any>) {
    this.modalService.open(modalTemplate).subscribe((action: any) => {
      if (action) {
        this.changeAnnoucementsState('delete');
      }
    });
  };
}
