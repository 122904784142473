import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExpandImageComponent } from '../../expand-image/expand-image.component';
import { setImageDimensions } from '../../../utils';

@Component({
  selector: 'app-interaction-photos',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './photos.component.html',
  styleUrl: './photos.component.scss',
})
export class PhotosComponent implements OnInit, OnChanges {
  @Input() photos: any[] = [];
  photoGroups: any[] = [];
  currentIndex: number = 0;
  imagesPerSlide: number = 3;


  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    this.splitPhotosIntoGroups();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['photos']) {
      this.splitPhotosIntoGroups();
    }
  }

  splitPhotosIntoGroups() {
    const totalPhotos = this.photos?.length;
    this.photoGroups = [];
    for (let i = 0; i < totalPhotos; i += this.imagesPerSlide) {
      this.photoGroups.push(this.photos.slice(i, i + this.imagesPerSlide));
    }
  }

  prevSlide() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.photoGroups.length - 1;
    }
  }

  nextSlide() {
    if (this.currentIndex < this.photoGroups.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
  }

  async expandPage(imageBase64: string) {
    if (imageBase64) {
      let dimensions = await setImageDimensions(imageBase64);
      const dialogRef = this.dialog.open(ExpandImageComponent, {
        data: {
          image: imageBase64,
          dimensions: dimensions
        },
        width: `${dimensions.width.toString()}px`,
        height: `${dimensions.height.toString()}px`,
        maxHeight: 'calc(90vh - 110px)',
        maxWidth: 'calc(90vw - 110px)'
      });
      dialogRef.afterOpened().subscribe({
        next: () => {
          const body = document.getElementsByTagName("body")[0].style.overflowY = "hidden";
        },
      });
      dialogRef.afterClosed().subscribe({
        next: () => {
          const body = document.getElementsByTagName("body")[0].style.overflowY = "scroll";
        },
      })
    }
  }
}
