import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { SIDEBAR_OPTION } from '../../../models/sidebar-option';
import { SIDEBAR_OPTIONS_URLS } from '../../../enums/sidebar-option-urls.enum';
import { SIDEBAR_OPTIONS } from '../consts/sidebar-options.const';

@Injectable({
  providedIn: 'root',
})
export class SidebarOptionService {
  private defaultSidebarOption: SIDEBAR_OPTION = SIDEBAR_OPTIONS.GENERAL_PANEL;

  currentSidebarOption$: BehaviorSubject<SIDEBAR_OPTION> =
    new BehaviorSubject<SIDEBAR_OPTION>(this.defaultSidebarOption);

  constructor(private _router: Router) {
    this.initializeCurrentSidebarOption();
  }

  initializeCurrentSidebarOption() {
    const urlSegments: string[] = this._router.url.split('/');
    const sidebarOption: string = urlSegments[2];
    switch (sidebarOption) {
      case SIDEBAR_OPTIONS_URLS.GENERAL_PANEL:
        this.currentSidebarOption$ = new BehaviorSubject<SIDEBAR_OPTION>(
          SIDEBAR_OPTIONS.GENERAL_PANEL
        );
        break;
      case SIDEBAR_OPTIONS_URLS.REPORTED:
        this.currentSidebarOption$ = new BehaviorSubject<SIDEBAR_OPTION>(
          SIDEBAR_OPTIONS.REPORTED
        );
        break;
      case SIDEBAR_OPTIONS_URLS.EMERGENCY:
        this.currentSidebarOption$ = new BehaviorSubject<SIDEBAR_OPTION>(
          SIDEBAR_OPTIONS.EMERGENCY
        );
        break;
      case SIDEBAR_OPTIONS_URLS.ANNOUNCEMENTS:
        this.currentSidebarOption$ = new BehaviorSubject<SIDEBAR_OPTION>(
          SIDEBAR_OPTIONS.ANNOUNCEMENTS
        );
        break;
      case SIDEBAR_OPTIONS_URLS.INTERACTIONS:
        this.currentSidebarOption$ = new BehaviorSubject<SIDEBAR_OPTION>(
          SIDEBAR_OPTIONS.INTERACTIONS
        );
        break;
      case SIDEBAR_OPTIONS_URLS.USERS:
        this.currentSidebarOption$ = new BehaviorSubject<SIDEBAR_OPTION>(
          SIDEBAR_OPTIONS.USERS
        );
        break;
    }
  }

  updateSidebarOption(newSidebarOption: SIDEBAR_OPTION) {
    this.currentSidebarOption$.next(newSidebarOption);
  }

  resetSidebarOption() {
    this.currentSidebarOption$.next(this.defaultSidebarOption);
  }
}
