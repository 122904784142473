import { HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError, timeout } from 'rxjs';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as errorTitleMessages from '../../../../assets/constants/toastTitlesOrTypes.json';
import * as errorMessages from '../../../../assets/constants/errorsWarnings.json';
import { verifyErrorIsConection, verifyErrorIsTimeout } from '../../utils';
const TIMEOUT: number = 7000;
export const globalErrorHandler: HttpInterceptorFn = (req, next) => {
  const toastrService = inject(ToastrService);
  return next(req).pipe(
    timeout(TIMEOUT),
    catchError(err => {
      if (verifyErrorIsTimeout(err)) {
        showWarning(errorMessages.error_timeout, toastrService);
      } else if (verifyErrorIsConection(err)) {
        showWarning(errorMessages.error_connection, toastrService);
      }
      return throwError(() => err);
    })
  );
};

function showWarning(message: string, service: any) {
  service.warning(message, errorTitleMessages.info_title);
}
