import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DynamicButtonComponent } from '../../../shared/components/dynamic-button/dynamic-button.component';
import { ButtonConfig } from '../../../shared/components/dynamic-button/dynamic-button.model';
import { HeaderBoxTableComponent } from '../../../shared/components/header-box-table/header-box-table.component';
import { SidebarOptionTitleBandComponent } from '../../../shared/components/title-band/sidebar-option-title-band.component';
import { EmergencyService } from '../../services/emergency-page/emergency.service';
import { LoadingService } from '../../../shared/services/loading/loading.service';
import { downloadFile, getDateDDMMYYYY, verifyErrorIsConection, verifyErrorIsTimeout } from '../../../shared/utils';
import { TableEmergency190RJService } from '../../services/dynamic-table/dynamic-table__emergency-page-190rj.service';
import { SearchTableModel } from '../../../shared/components/header-box-table/search-table.model';


@Component({
  selector: 'app-emergency-page190rj',
  standalone: true,
  imports: [HeaderBoxTableComponent, SidebarOptionTitleBandComponent, DynamicButtonComponent],
  templateUrl: './emergency-page190rj.component.html',
  styleUrl: './emergency-page190rj.component.scss'
})
export class EmergencyPage190rjComponent implements OnInit {
  emergencysData: any[];
  configButtonRegister: ButtonConfig = { label: 'Excel', imgSrc: "assets/imgs/excel_ic.svg", handler: () => this.exportExcel(), customColor: { backgroundColor: "#00954B" } };
  tableConfig: any = {};
  showTable: boolean = false;
  status: any[] = [];
  types: any[] = [];
  textQuery: string | any = "";
  initDateFilter: string | any = "";
  finalDateFilter: string | any = "";

  constructor(
    private tableService: TableEmergency190RJService,
    private loadingService: LoadingService,
    private emergencyPageRJ190: EmergencyService,
    private toastr: ToastrService
  ) { }

  async ngOnInit() {
    this.loadingService.present();
    this.tableConfig = await this.getData();
    this.types = this.tableConfig.optionsFirstFilter;
    this.status = this.tableConfig.optionsSecondFilter;
    this.tableConfig.currentPage = 1;
    this.emergencysData = this.tableConfig.tableBody;
    this.showTable = true;
    this.loadingService.dismiss();
  }


  public async searchAndFilterItens(event: SearchTableModel) {
    this.textQuery = event.searchValue;
    this.initDateFilter = event.filterByDateValue;
    this.finalDateFilter = event.secondFilterByDateValue;
    if (this.textQuery || this.initDateFilter || this.finalDateFilter) {
      this.loadingService.present();
      this.tableConfig = await this.getData(1, this.textQuery, this.initDateFilter, this.finalDateFilter);
      this.tableConfig.currentPage = 1;
      this.tableConfig.optionsFirstFilter = this.types;
      this.tableConfig.optionsSecondFilter = this.status;
      this.loadingService.dismiss();
    }
  }

  public async clearSearchTable() {
    this.loadingService.present();
    this.textQuery = "";
    this.initDateFilter = "";
    this.finalDateFilter = "";
    this.tableConfig = await this.getData(1);
    this.tableConfig.currentPage = 1;
    this.tableConfig.optionsFirstFilter = this.types;
    this.tableConfig.optionsSecondFilter = this.status;
    this.loadingService.dismiss();
  }

  private getData(page?: number, textSearch?: string, initDate?: string, finalDate?: string): Promise<any> {
    return this.tableService.getSettingsTable(page, textSearch, initDate, finalDate);
  };

  async swapPage(page: number) {
    this.loadingService.present();
    this.tableConfig = await this.getData(page, this.textQuery, this.initDateFilter, this.finalDateFilter,);
    this.tableConfig.optionsFirstFilter = this.types;
    this.tableConfig.optionsSecondFilter = this.status;
    this.tableConfig.currentPage = page;
    this.loadingService.dismiss();
  };

  exportExcel() {
    this.loadingService.present();
    this.emergencyPageRJ190.emergencyExcel().subscribe({
      next: (res) => {
        downloadFile(res, `190RJ_RelatórioEmergência_${getDateDDMMYYYY()}`);
      },
      error: (err) => {
        if (!verifyErrorIsTimeout(err) && !verifyErrorIsConection(err)) this.toastr.error('Erro ao baixar arquivo Excel!', 'Erro!')
      }
    })
    this.loadingService.dismiss();
  };
}
