import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderBoxTableComponent } from '../../shared/components/header-box-table/header-box-table.component';
import { SidebarOptionTitleBandComponent } from '../../shared/components/title-band/sidebar-option-title-band.component';
import { DynamicButtonComponent } from '../../shared/components/dynamic-button/dynamic-button.component';
import { OptionFilter } from '../../shared/components/header-box-table/header-box-table.model';
import { ButtonConfig } from '../../shared/components/dynamic-button/dynamic-button.model';
import { EXTRAS_PAGES_URLS } from '../../shared/enums/extras-pages-urls.enum';
import { TableUsersRedeMulherService } from '../services/dynamic-table__users-page-rede-mulher.service';
import { LoadingService } from '../../shared/services/loading/loading.service';
import { getTypeUser } from '../../shared/utils';
import { TYPE_USERS } from '../../shared/enums/type-users';
import { AuthService } from '../../shared/services/auth/auth.service';


@Component({
  selector: 'app-users-page-rede-mulher',
  standalone: true,
  imports: [HeaderBoxTableComponent, SidebarOptionTitleBandComponent, DynamicButtonComponent],
  templateUrl: './users-page-rede-mulher.component.html',
  styleUrl: './users-page-rede-mulher.component.scss'
})
export class UsersPageRedeMulherComponent {
  usersData: any;
  filtersOptions: OptionFilter[] = [
    { name: "Perfil", value: "", default: true },
    { name: "Administrador", value: "admin" },
    { name: "Suporte", value: "sup" },
    { name: "Observador", value: "obs" },
  ];
  configButtonRegister: ButtonConfig = { label: 'Cadastrar', imgSrc: "assets/imgs/icon_more.svg", handler: () => this.registerUser(), customColor: { backgroundColor: "#F7A01D" } };
  tableConfig: any = {};
  show: boolean = false;
  querySearch: string = "";

  constructor(
    private _tableService: TableUsersRedeMulherService,
    public router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private authService: AuthService
  ) { }


  async ngOnInit() {
    this.loadingService.present();
    this.tableConfig = await this.getUsersApp();
    this.usersData = this.tableConfig.tableBody;
    this.tableConfig.currentPage = 1;
    this.configHandleButton();
    this.show = true;
    this.loadingService.dismiss();
  }


  public async searchAndFilterItens(event: any) {
    this.querySearch = event.searchValue;
    if (this.querySearch) {
      this.loadingService.present();
      this.tableConfig = await this.getUsersApp(1, this.querySearch);
      this.tableConfig.currentPage = 1;
      this.configHandleButton();
      this.loadingService.dismiss();
    }
  }

  public async clearSearchTable() {
    this.loadingService.present();
    this.querySearch = "";
    this.tableConfig = await this.getUsersApp();
    this.tableConfig.currentPage = 1;
    this.configHandleButton();
    this.loadingService.dismiss();
  }

  public registerUser() {
    this.router.navigate([EXTRAS_PAGES_URLS.NEW_USER], { relativeTo: this.route });
  }

  private async getUsersApp(page?: number, name?: string): Promise<any> {
    return this._tableService.getSettingsTableUsersApp(page, name);
  };

  async swapPage(page: number) {
    this.loadingService.present();
    this.tableConfig = await this.getUsersApp(page, this.querySearch);
    this.configHandleButton();
    this.tableConfig.currentPage = page;
    this.loadingService.dismiss();
  }


  private configHandleButton() {
    if (this.tableConfig.showButton) {
      this.tableConfig.buttonTable.handler = (user: any) => {
        this.navToEditUser(user);
      };
    };
  }

  navToEditUser(user: any) {
    this.router.navigate([EXTRAS_PAGES_URLS.EDIT_USER], { relativeTo: this.route, state: { userID: user.id } });
  }

  showRegisterUserButton = () => getTypeUser(this.authService.getAccessToken) == TYPE_USERS.ADMIN

}
