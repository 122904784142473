export class DoughnutDataset {
  constructor(
    data: Array<number>,
    backgroundColor: Array<string> = [],
    tension: number = 4,
    borderWidth: number = 1
  ) {
    this.data = data;
    this.backgroundColor = backgroundColor;
    this.tension = tension;
    this.borderWidth = borderWidth;
  }

  data: Array<number>;
  backgroundColor: Array<string>;
  tension: number;
  borderWidth: number;
}
