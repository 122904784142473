import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { LineDataset } from './models/line-dataset';
import { MONTHS } from './consts/months.const';
import { LineChartService } from './services/line-chart.service';
import { FilterCardsService } from '../filter-card/services/filter-cards.service';
import { PortalFilterCard } from '../../models/portal-filter-card';
import { PORTAL_TYPES } from '../../enums/portal-types.enum';
import { FormsModule } from '@angular/forms';
import { PORTAL_FILTER_CARD_TITLES } from '../filter-card/enums/filter-card-titles.enum';

@Component({
  selector: 'app-line-chart',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './line-chart.component.html',
  styleUrl: './line-chart.component.scss',
})
export class LineChartComponent implements OnInit {
  @Input() hasDateFilter: boolean = true;
  @Input() hasTypeFilter: boolean = true;

  title: string =
    'Estatística Anual ' +
    this._filterCardsService.currentPortalFilterCard$.value?.title;

  currentPortalFilterCard: PortalFilterCard | null =
    this._filterCardsService.currentPortalFilterCard$.value;
  periods: any = [];
  currentPeriod: any;
  types: any = [];
  currentType: any;
  showFilterType: boolean = false;
  labels: string[] = MONTHS;

  constructor(
    private readonly _lineChartService: LineChartService,
    private readonly _filterCardsService: FilterCardsService,
  ) { }

  ngOnInit() {
    this.listenToChangesOnFilterCards();
    this.getPeriods();
  };

  getPeriods() {
    this.periods = [];
    let currentYear: any = new Date().getFullYear();
    let dates = [
      currentYear,
      currentYear - 1,
      currentYear - 2,
      currentYear - 3,
      currentYear - 4,
      currentYear - 5,
    ];
    dates.forEach((year: number) => {
      this.periods.push(year);
    });
  };

  listenToChangesOnFilterCards() {
    this._filterCardsService.currentPortalFilterCard$.subscribe(
      (filterCard: PortalFilterCard | null) => {
        this.currentPeriod = 0;
        this.currentType = 0;
        if (
          filterCard?.selectedPortal.type === PORTAL_TYPES.RJ_190 ||
          filterCard?.selectedPortal.type === PORTAL_TYPES.REDE_ESCOLA
        ) {
          if (filterCard?.title == PORTAL_FILTER_CARD_TITLES.RJ_190_USERS) {
            this.showFilterType = false;
          } else this.showFilterType = true;
          this.title = 'Estatística Anual ' + filterCard?.title;
        } else {
          this.title = filterCard?.title || '';
          this.showFilterType = false;
        };
        this.currentPortalFilterCard = filterCard;
        this.updateDataset();
      }
    );
  };

  async updateDataset(period?: number, type?: string) {
    const datasets: LineDataset[] = await this._lineChartService.getChartData(
      this.currentPortalFilterCard,
      !period ? new Date().getFullYear() : period,
      !type ? 0 : type
    );
    this.types = datasets[0].types;
    if (this.types && !this.currentType) this.currentType = this.types[0].value;
    if (!this.currentPeriod) this.currentPeriod = this.periods[0];
    if (document.getElementById('myChart')) {
      this._lineChartService.buildChart(this.labels, datasets);
    };
  }

  changePeriod(event: any) {
    this.currentPeriod = Number(event.target.value);
    this.currentType = 0;
    this.updateDataset(this.currentPeriod);
  }

  changeType(event: any) {
    this.currentType = event;
    this.updateDataset(this.currentPeriod, this.currentType);
  }
}
