import { Injectable } from '@angular/core';
import { PORTAL_TYPE_URLS } from '../../../enums/portal-types-urls.enum';
import { SIDEBAR_OPTIONS_URLS } from '../../../enums/sidebar-option-urls.enum';
import { Observable, firstValueFrom, of } from 'rxjs';
import { HeaderTableSettings } from '../../header-box-table/header-box-table.model';
import { PORTAL_FILTER_CARD_TITLES } from '../../filter-card/enums/filter-card-titles.enum';
import { MockDataTable } from './dynamic-table.mocks';
import { RedeMulherService } from '../../../services/rede-mulher/rede-mulher.service';
@Injectable({
  providedIn: 'root'
})
export class DynamicTableService {

  constructor(
    private redeMulherService: RedeMulherService,
  ) { }


  public async getObjectConfigTable(portal: string, page: string, card?: any) {
    if (portal.includes(PORTAL_TYPE_URLS.REDE_MULHER)) return await this.getSettingsTableMulher(page, card);
    else if (portal.includes(PORTAL_TYPE_URLS.REDE_ESCOLA)) return this.getSettingsTableEscola(page, card);
    return {};
  }

  public async getSettingsTableMulher(page: string, clickedCard: any) {
    let config: HeaderTableSettings = {};
    if (page == SIDEBAR_OPTIONS_URLS.EMERGENCY) {
      let responseEmergency = await this.getTableMulher(page);
      config.headerTitle = "Emergências";
      config.titleSearch = "Pesquisar por nome";
      config.titlesTables = ['Protocolo', 'Data', 'Hora', 'Tipo', 'Vítima', 'Status', 'Classificação'];
      config.itensPerPage = 10;
      config.showTextBox = true;
      config.showFilterByDate = true;
      config.showSecondFilterByDate = true;
      config.showFirstFilterBox = true;
      config.optionsFirstFilter = [
        { name: "Todos", value: "todos" },
        { name: "Violência Sexual", value: "Violência Sexual" },
        { name: "Violência Verbal", value: "Violência Verbal" },
        { name: "Violência Psicologica", value: "Violência Psicologica" },
      ];
      config.showButton = false;
      config.tableBody = responseEmergency;
    };
    return config;
  }

  public getSettingsTableEscola(page: string, clickedCard: any): Observable<any> {
    let config: HeaderTableSettings = {};
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      this.getTableEscola(page, clickedCard).subscribe({
        next: (responseReported) => {
          if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_SCHOOLS) {
            config.headerTitle = clickedCard.title;
            config.itensPerPage = 10;
            config.showTextBox = true;
            config.titleSearch = 'Pesquise por nome';
            config.showFirstFilterBox = true;
            config.optionsFirstFilter = [
              { name: 'Tipo', value: '', default: true },
              { name: 'Pública', value: 'Pública' },
              { name: 'Municipal', value: 'Municipal' },
              { name: 'Estadual', value: 'Estadual' },
              { name: 'Federal', value: 'Federal' },
              { name: 'Privada', value: 'Privada' },
            ];
            config.titlesTables = [
              'Nome da escola',
              'Tipo',
              'Endereço',
              'Telefone',
              'E-mail',
            ];
            config.showButton = false;
            config.tableBody = responseReported;

          } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS) {
            config.headerTitle = clickedCard.title;
            config.itensPerPage = 4;
            config.showTextBox = false;
            config.showFirstFilterBox = true;
            config.showExcelButton = true;
            config.optionsFirstFilter = [
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
            ];
            config.titlesTables = [
              'Nome da escola',
              'Nome do usuário',
              'Telefone',
              'Data da denúncia',
              'Ver denúncia'
            ];
            config.showButton = true;
            config.buttonTable = { label: 'Visualizar' };
            config.tableBody = responseReported;

          } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_EMERGENCIES) {
            config.headerTitle = clickedCard.title;
            config.itensPerPage = 5;
            config.showTextBox = false;
            config.showFirstFilterBox = true;
            config.showExcelButton = true;
            config.optionsFirstFilter = [
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
            ];
            config.titlesTables = [
              'Tipo',
              'Jan',
              'Fev',
              'Mar',
              'Abr',
              'Mai',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez',
              'Total'];
            config.showButton = false;
            config.tableBody = responseReported;

          };
        },
        error: () => {
          config = {};
        },
      });
    } else if (SIDEBAR_OPTIONS_URLS.EMERGENCY) {
    };
    return of(config);
  }

  private getTableEscola(page: string, cardOption?: any): Observable<any> {
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      let response: any;
      if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_SCHOOLS) {
        response = MockDataTable.REDE_ESCOLA.GENERAL_PANEL.SCHOOLS;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS) {
        response = MockDataTable.REDE_ESCOLA.GENERAL_PANEL.DENOUNCEMENTS;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_EMERGENCIES) {
        response = MockDataTable.REDE_ESCOLA.GENERAL_PANEL.EMERGENCIES;
        response.forEach((element: any) => {
          element.total =
            element.jan +
            element.fev +
            element.mar +
            element.abr +
            element.mai +
            element.jun +
            element.jul +
            element.ago +
            element.set +
            element.out +
            element.nov +
            element.dez;
        });
      };
      return of(response);
    }
    return of({});
  }

  private async getTableMulher(page: string, cardOption?: any) {
    if (page == SIDEBAR_OPTIONS_URLS.EMERGENCY) {
      return MockDataTable.REDE_MULHER.EMERGENCY
    }
    return of({});
  };

}
