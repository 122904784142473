<div class="interactions__details--card">
  @if(dennoucementDetails) {
  <div class="d-flex align-items-center mb-20">
    <img class="user-picture" [src]="chatDetails?.iconDefault?.replace('data:image/png', 'data:image/svg+xml')" />
    <div class="username ml-30 flex-grow-1" matTooltip="{{chatDetails?.tipoDenunciaNome}}" matTooltipPosition="below"
      matTooltipClass="custom_tooltip">
      <div class="p-0 label">
        {{ chatDetails?.tipoDenunciaNome }}
      </div>
    </div>
    <div class="">
      <span></span>
    </div>
  </div>
  <div class="w-100 mw-100 d-flex align-items-center mb-15">
    <img class="mr-10" src="./../../../../assets/imgs/blue-white-circle.svg" />
    <div class="interaction-type--label mr-5">Descrição:</div>
    <div class="interaction-type" matTooltip="{{chatDetails?.denunciaObs}}"
      [matTooltipDisabled]="!chatDetails?.denunciaObs" matTooltipPosition="below" matTooltipClass="custom_tooltip">
      <div class="p-0 label">
        {{ chatDetails?.denunciaObs ? chatDetails?.denunciaObs : '-' }}
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center mb-15">
    <img class="mr-10" src="./../../../../assets/imgs/blue-white-circle.svg" />
    <div class="interaction-date--label mr-5">Data:</div>
    <div class="interaction-date">
      <div class="p-0 label ">
        {{ chatDetails?.date }}
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center mb-15">
    <img class="mr-10" src="./../../../../assets/imgs/blue-white-circle.svg" />
    <div class="interaction-time--label mr-5">Horário:</div>
    <div class="interaction-time">
      <div class="p-0 label ">
        {{ chatDetails?.hour }}
      </div>
    </div>
  </div>
  } @else {
  <div class="interactions__details--title mb-20">Dados</div>
  <div class="no_data">
    <span>Sem dados disponível</span>
  </div>
  }
</div>
