import { Injectable } from '@angular/core';
import { RestApiService } from '../rest-api/rest-api.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedeMulherService {
  private readonly baseUrl = environment.baseUrlRedeMulher;
  constructor(
    private restApiService: RestApiService,
  ) { }

  numericData() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'painel/total',
    };
    return this.restApiService.get(request);
  };
  statiscsMonthlyUsers() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/usuarios/diario-mensal',
    };
    return this.restApiService.get(request);
  };

  statiscsMonthlyEmergencys() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/ocorrencias/diario-mensal',
    };
    return this.restApiService.get(request);
  };


  statiscsAnualsAgressor() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/agressor/por-mes',
    };
    return this.restApiService.get(request);
  };



  emergencyTops() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'emergencias/top-rank',
    };
    return this.restApiService.get(request);
  };

  emengercyTable() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/ocorrencias/tabela',
    };
    return this.restApiService.get(request);
  };

  generalPanelEmergencyTableExcelExport() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `emergencias/detalhes-anual/excel`,
      responseType: 'blob'
    };
    return this.restApiService.get(request);
  }

  reportedTops() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/ocorrencias/top-5',
    };
    return this.restApiService.get(request);
  };

  reportedTable() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/ocorrencias/tabela',
    };
    return this.restApiService.get(request);
  };

  agressorTable() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/agressor/tabela',
    };
    return this.restApiService.get(request);
  };

  usersTable() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/usuarios/tabela',
    };
    return this.restApiService.get(request);
  };

  generalPanelUsersTableExcelExport() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `users/detalhes-anual/excel`,
      responseType: 'blob'
    };
    return this.restApiService.get(request);
  }

  getUsersApp(limit?: number, page?: number, filter?: string): Observable<any> {
    if (!limit) limit = 10;
    if (!page) page = 1;
    const params: string[] = [];
    if (filter) params.push(`filtro=${filter}`);
    params.push(`page=${page}`);
    params.push(`limit=${limit}`);
    const queryString = params.length > 0 ? `?${params.join('&')}` : '';
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `users${queryString}`,
    };
    return this.restApiService.get(request);
  };

  getUserAppData(id: string | null): Observable<any> {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `users/${id}`,
    };
    return this.restApiService.get(request);
  };

  editUserAppData(id: string | null, body: any): Observable<any> {
    let endpoint = this.baseUrl + `users/${id}`;
    return this.restApiService.put(endpoint, body);
  };

}
