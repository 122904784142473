import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InteractionsService } from '../../../../190rj/services/interactions/interactions.service';
import { getLastFiveYears, verifyErrorIsConection, verifyErrorIsTimeout } from '../../../utils';
import { ToastrService } from 'ngx-toastr';
import * as toastMesssages from '../toastStringsInteractions.json';
import * as toastTitlesOrTypes from '../../../../../assets/constants/toastTitlesOrTypes.json';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss',
})
export class ChatComponent implements OnInit, OnChanges {
  formGroup: FormGroup = new FormGroup({});
  chatMessages: Array<any> = [];
  @Input() dennoucementID: number;
  currentYear: number;
  dates: any[] = [];
  isLoading: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _interactionsService: InteractionsService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.initializeFormGroup();
    this.dates = getLastFiveYears();
    this.dates.unshift(0);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['dennoucementID'].currentValue) {
      this.isLoading = true;
      this.currentYear = 0;
      this.chatMessages = await this.getChatMessages(changes['dennoucementID'].currentValue, this.currentYear);
      this.chatMessages = await this.addFlagDirectionRight(this.chatMessages);
      this.isLoading = false;
    }
  };


  initializeFormGroup() {
    this.formGroup = this._formBuilder.group({
      message: ['', []],
    });
  }

  getChatMessages(dennoucementID: number, year: number): Promise<any> {
    return new Promise((resolve) => {
      if (year == 0) year = NaN;
      this._interactionsService.getDennoucementComments(dennoucementID, year).subscribe({
        next: (chatMessages) => resolve(chatMessages?.data),
        error: (err) => {
          if (!verifyErrorIsTimeout(err) && !verifyErrorIsConection(err) && err?.error?.statusCode != 404)
            this.toastr.error(toastMesssages.error_search_comments, toastTitlesOrTypes.error_title);
          resolve([]);
        }
      });
    })
  }

  async changeYear(event: any) {
    this.currentYear = event;
    this.isLoading = true;
    this.chatMessages = await this.getChatMessages(this.dennoucementID, this.currentYear);
    this.chatMessages = await this.addFlagDirectionRight(this.chatMessages);
    this.isLoading = false;
  }

  addFlagDirectionRight(array: any[]): Promise<any[]> {
    let previousUsuarioID: string | null = null;
    let currentDirectionRight = true;

    return new Promise((resolve) => {
      for (let item of array) {
        if (item.usuarioId !== previousUsuarioID) {
          currentDirectionRight = !currentDirectionRight;
        }
        item.directionRight = currentDirectionRight;
        previousUsuarioID = item.usuarioId;
      }
      resolve(array);
    });
  }
}
