import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-image-upload',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-upload.component.html',
  styleUrl: './image-upload.component.scss',
})
export class ImageUploadComponent implements AfterViewInit, OnInit {
  @Output() fileSent: EventEmitter<any> = new EventEmitter<any>();
  @Input() bannerFile: string;
  @ViewChild('fileUpload')
  private fileUpload: ElementRef;
  showLabelUpImg: boolean = true;
  globalInstance: any;
  constructor(private _renderer: Renderer2) { }

  ngOnInit() {
    if (this.bannerFile) {
      this.showLabelUpImg = false;
      this.fillInputFile();
    }
  }


  ngAfterViewInit(): void {
    this.globalInstance = this._renderer.listen(
      this.fileUpload.nativeElement,
      'change',
      (e: any) => {
        this.fileSelectHandler(e);
      }
    );
  }

  async fillInputFile() {
    const file = new File([this.bannerFile], 'img.png', { type: 'image/png', lastModified: new Date().getTime() });
    const files = [file];
    this.fileSelectHandler({ target: { files: files } });
  }

  fileSelectHandler(e: any) {
    this.parseFile(e?.target?.files[0]);
    this.uploadFile(e?.target?.files[0]);
  }

  uploadFile(file: File) {
    this.fileSent.emit(file);

    const xhr = new XMLHttpRequest();
    const fileSizeLimit = 1024;

    if (xhr.upload) {
      if (file.size <= fileSizeLimit * 1024 * 1024) {
        const formUploadForm = document.getElementById(
          'file-upload-form'
        ) as HTMLFormElement;

        xhr.open('POST', formUploadForm.action, true);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.showLabelUpImg = false;
        };
      }
    }
  }
  parseFile(file: any) {
    var imageName = file.name;
    var isGood = /\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
    if (isGood) {
      document.getElementById('start')?.classList.add('hidden');
      document.getElementById('response')?.classList.remove('hidden');
      document.getElementById('notimage')?.classList.add('hidden');
      const fileImage = document.getElementById(
        'file-image'
      ) as HTMLImageElement;
      fileImage?.classList.remove('hidden');
      fileImage.src = URL.createObjectURL(file);
    } else {
      document.getElementById('file-image')?.classList.add('hidden');
      document.getElementById('notimage')?.classList.remove('hidden');
      document.getElementById('start')?.classList.remove('hidden');
      document.getElementById('response')?.classList.add('hidden');

      const formUploadForm = document.getElementById('file-upload-form') as any;
      formUploadForm.reset();
    }
  }

  clearFile() {
    this.fileUpload.nativeElement.value = '';
    this.showLabelUpImg = true;
    const fileImage = document.getElementById('file-image') as HTMLImageElement;
    fileImage.src = '';
    fileImage.classList.add('hidden');
    document.getElementById('response')?.classList.add('hidden');
    document.getElementById('start')?.classList.remove('hidden');
    document.getElementById('notimage')?.classList.add('hidden');
    this.fileSent.emit('');
  }

  clickLabelOrIc() {
    document.getElementById('file-upload')?.click();
  }
}
