import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { HeaderTableSettings } from "../../../shared/components/header-box-table/header-box-table.model";
import { formatResponseTableEmergency } from "../../../shared/utils";
import { EmergencyService } from "../emergency-page/emergency.service";

@Injectable({
  providedIn: 'root'
})

export class TableEmergency190RJService {
  constructor(
    private emergencyService: EmergencyService,
  ) { }

  public async getSettingsTable(pageNumber?: number, textSearch?: string, initDate?: string, finalDate?: string) {
    let config = {} as HeaderTableSettings;
    let responseEmergency = await this.getDataTableByCard(pageNumber, textSearch, initDate, finalDate);
    if (responseEmergency?.data?.length > 0) {
      responseEmergency.data = formatResponseTableEmergency(responseEmergency.data);
    }
    config.headerTitle = "Emergência";
    config.itensPerPage = 10;
    config.totalItens = responseEmergency.total;
    config.showTextBox = true;
    config.showFilterByDate = true;
    config.titleSearch = "Pesquisar por nome";
    config.showSecondFilterByDate = true;
    config.showClearButton = true;
    config.titlesTables = ['Data', 'Hora', 'Tipo', 'Mike 190', 'Status', 'Satisfação'];
    config.tableBody = responseEmergency.data;
    return config;
  }

  private async getDataTableByCard(pageNumber?: number, textSearch?: string, initDate?: string, finalDate?: string) {
    let response: any;
    await firstValueFrom(this.emergencyService.emergencyPageTable(10, pageNumber, textSearch, initDate, finalDate)).then((responseAPI) => response = responseAPI).catch(() => response = []);
    return response;
  }

}
