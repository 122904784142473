<div class="side-bar-collapse" [ngClass]="currentPortal.type">

  <div class="container-fluid">
    <div class="actions-top">
      <div role="button" class="header--logout-img" (click)="logout()">
        <img src="./../../../assets/imgs/power.svg" />
      </div>

      <div class="closebtn">
        <label (click)="closeCollapseMenu()" for="check-menu-hamburguer"><img
            src="./../../../assets/imgs/close.svg"></label>
      </div>

    </div>

    <div class="row">
      <div class="d-flex align-items-center justify-content-center">
        <section class="header--username-container">
          <span class="header--username">{{nameUser}}</span>
        </section>

      </div>
    </div>


    <div class="row d-flex justify-content-center align-items-center">
      <section class="sidebar-logo--container d-flex justify-content-center align-items-center">
        <img class="sidebar-logo--image" src="./../../../assets/imgs/{{ currentPortal.type }}.svg" />
      </section>
      <div class="dropdown" (click)="onClickOutside($event)">
        <img #dropdownButton src="../../../../assets/imgs/arrow_down_white.svg" (click)="toggleDropdown($event)">
        <div @dropdownAnimation #dropdownContent class="dropdown-content" *ngIf="dropdownVisible">
          @for(portal of portals; track portal ){
          <a (click)="updatePortalType(portal.src)">
            <div class="header--portal-type--img-container position-relative" [ngClass]="portal.src">
              <img class="header--portal-type--img position-absolute"
                src="./../../../assets/imgs/{{ portal.src }}-small.svg" />
            </div> {{portal.name}}
          </a>
          }
        </div>
      </div>
    </div>

    <div class="row">
      <section class="menu-option list-group pt-20">
        @for (option of sidebarOptions; track option.id) { @if(!option.portals ||
        (option.portals.length && option.portals.includes(currentPortal.name))){
        <a class="menu-option--text-container {{ currentPortal.type }}" [ngClass]="option.active ? 'active' : ''"
          (click)="updateSidebarOption(option)">
          <div class="container-fluid">
            <div class="row no-padding">
              <div class="col-12 gap-2 no-padding d-flex justify-content-center align-items-center">
                <div class="box-labels">
                  <div class="img">
                    <img class="menu-option--img my-auto"
                      src="./../../../assets/imgs/sidebar-{{ option.img }}-active.svg" />
                  </div>
                  <label [ngClass]="option.active ? 'active' : ''" class="sidebar__button-label--default-color my-auto">
                    {{ option.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </a>
        } }
      </section>
    </div>

  </div>

</div>
