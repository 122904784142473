<div class="c-modal {{ size }}">
  <div class="modal-content">
    <ng-content></ng-content>
  </div>
  <div class="d-flex justify-content-center mt-auto">
    <button class="modal-button yes mr-20" (click)="submit()">SIM</button>
    <button class="modal-button no" (click)="close()">NÃO</button>
  </div>
</div>

<div class="modal-backdrop" (click)="close()"></div>
