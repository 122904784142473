<div class="login--container">
  <section class="login--container--grid">
    <div class="rj-190--col">
      <img src="./../../../assets/imgs/190-rj-login.svg" class="icon" />
    </div>
    <div class="rede-mulher--col">
      <div class="d-flex flex-column">
        <img src="./../../../assets/imgs/rede-mulher.svg" class="icon align-self-center" />
        <form class="form mt-auto" [formGroup]="loginFormGroup">
          @if(!token) {
          <div class="login--input--container">
            <label for="email" class="login--form--label d-block">Email</label>
            <input class="login--input--control" type="text" id="email" autocomplete="off" formControlName="email"
              placeholder="admin@admin.com" />
          </div>
          <div class="login--input--container position-relative">
            <label for="password" class="login--form--label d-block">Senha</label>
            <input class="login--input--control" [type]="passType" id="password" formControlName="senha"
              placeholder="********" (keypress)="preventSpace($event)" />
            <img class="login--input--img" (click)="passType == 'password' ? passType = 'text' : passType = 'password' "
              [src]="passType == 'password' ? './../../../assets/imgs/esconder-senha.svg' : './../../../assets/imgs/mostrar-senha.svg'" />
          </div>
          <div>
            <button class="login--button" (click)="login()">
              <span class="login--button-text">Entrar</span>
            </button>
          </div>
          <div class="login--forgot-password--container">
            <div class="login--forgot-password">
              Esqueceu a senha ou é seu primeiro acesso?
            </div>
            <div class="text-center login--forgot-password--btn cursor-pointer" (click)="openModalForgotPass()">
              Clique aqui
            </div>
          </div>
          } @else {
          <div class="login--input--container">
            <label for="email" class="login--form--label d-block">Token</label>
            <input class="login--input--control" type="text" id="email" autocomplete="off" formControlName="token"
              placeholder="1A2B3C" maxlength="6" />
          </div>
          <div class="login--input--container position-relative">
            <label for="newPass" class="login--form--label d-block">Nova Senha</label>
            <input class="login--input--control" [type]="passType" id="newPass" formControlName="newPass"
              placeholder="********" (keypress)="preventSpace($event)" />
            <img class="login--input--img" (click)="passType == 'password' ? passType = 'text' : passType = 'password' "
              [src]="passType == 'password' ? './../../../assets/imgs/esconder-senha.svg' : './../../../assets/imgs/mostrar-senha.svg' " />

            <div class="input_warning mt-10" [ngClass]="{'ok_warning': newPass?.value && newPass?.value.length >= 8 }">
              <img
                [src]="newPass?.value && newPass?.value.length >= 8 ? '../../../../assets/imgs/circle-check_green.svg' : '../../../../assets/imgs/circle-check.svg'">
              <span>A senha deve ter pelo menos 8 caracteres.</span>
            </div>

            <div class="input_warning" [ngClass]="{'ok_warning': newPass?.value && !verifyPassContainQuotation()}">
              <img
                [src]="newPass?.value && !verifyPassContainQuotation() ? '../../../../assets/imgs/circle-check_green.svg' : '../../../../assets/imgs/circle-check.svg'">
              <span>A senha não pode conter aspas.</span>
            </div>

            <div class="input_warning"
              [ngClass]="{'ok_warning': newPass?.value && !verifyPassNoContainNumberAndLetters()}">
              <img
                [src]="newPass?.value && !verifyPassNoContainNumberAndLetters() ? '../../../../assets/imgs/circle-check_green.svg' : '../../../../assets/imgs/circle-check.svg'">
              <span> A senha deve conter letras e números.</span>
            </div>

            <div class="input_warning"
              [ngClass]="{'ok_warning': newPass?.value && verifyPassContainLowerAndUpperCase()}">
              <img
                [src]="newPass?.value && verifyPassContainLowerAndUpperCase() ? '../../../../assets/imgs/circle-check_green.svg' : '../../../../assets/imgs/circle-check.svg'">
              <span> A senha deve conter pelo menos uma letra maiúscula e uma minúscula.</span>
            </div>

            <div class="input_warning" [ngClass]="{'ok_warning': newPass?.value && !verifyPassContainSequence()}">
              <img
                [src]="newPass?.value && !verifyPassContainSequence() ? '../../../../assets/imgs/circle-check_green.svg' : '../../../../assets/imgs/circle-check.svg'">
              <span>A senha não pode conter números sequenciais. Ex: 123.</span>
            </div>
          </div>
          <div>
            <button [disabled]="!loginFormGroup.valid" class="login--button" (click)="resetPass()">
              <span class="login--button-text">Redefinir Senha</span>
            </button>
          </div>
          }
        </form>
        <div class="mb-20 mt-30 d-flex justify-content-center">
          <img class="mr-10" src="./../../assets/imgs/login-arrow-down.png" />
          <img class="mr-10" src="./../../assets/imgs/login-apple.png" />
          <img class="" src="./../../assets/imgs/login-arrow-right.png" />
        </div>
      </div>
    </div>
    <div class="rede-escola--col">
      <img src="./../../../assets/imgs/rede-escola.svg" class="icon" />
    </div>
  </section>
  <div class="footer--text text-end relative">
    <img class="footer--pmerj-symbol" src="./../../../assets/imgs/pmerj-login.svg" />
    POLÍCIA MILITAR DO ESTADO DO RIO DE JANEIRO
  </div>
</div>
<div class="login--container__mobile">
  <div class="slides fade">
    <img src="./../../../assets/imgs/190-rj-login.svg" class="icon__mobile" />
    <img class="mobile--img__cover" src="./../../assets/imgs/190-rj-login.png" style="width:100%">
  </div>
  <div class="slides fade">
    <img src="./../../../assets/imgs/rede-mulher.svg" class="icon__mobile align-self-center" />
    <img class="mobile--img__cover" src="./../../assets/imgs/rede-mulher-login.png" style="width:100%" />
  </div>
  <div class="slides fade">
    <img src="./../../../assets/imgs/rede-escola.svg" class="icon__mobile" />
    <img class="mobile--img__cover" src="./../../assets/imgs/rede-escola-login.png" style="width:100%">
  </div>
  <form class="form__mobile mt-auto" [formGroup]="loginFormGroup">
    @if(!token) {
    <div class="login--input--container">
      <label for="email" class="login--form--label d-block">Email</label>
      <input class="login--input--control" type="text" id="email" autocomplete="off" formControlName="email"
        placeholder="admin@admin.com" />
    </div>
    <div class="login--input--container position-relative">
      <label for="password" class="login--form--label d-block">Senha</label>
      <input class="login--input--control" [type]="passType" id="password" formControlName="senha"
        placeholder="********" (keypress)="preventSpace($event)" />
      <img class="login--input--img" (click)="passType == 'password' ? passType = 'text' : passType = 'password' "
        src="./../../../assets/imgs/esconder-senha.svg" />
    </div>
    <div>
      <button class="login--button" (click)="login()">
        <span class="login--button-text">Entrar</span>
      </button>
    </div>
    <div class="login--forgot-password--container">
      <div class="login--forgot-password">
        Esqueceu a senha ou é seu primeiro acesso?
      </div>
      <div class="text-center login--forgot-password--btn cursor-pointer" (click)="openModalForgotPass()">
        Clique aqui
      </div>
    </div>
    } @else {
    <div class="login--input--container">
      <label for="email" class="login--form--label d-block">Token</label>
      <input class="login--input--control" type="text" id="email" autocomplete="off" formControlName="token"
        placeholder="1A2B3C" maxlength="6" />
    </div>
    <div class="login--input--container position-relative">
      <label for="newPass" class="login--form--label d-block">Nova Senha</label>
      <input class="login--input--control" [type]="passType" id="newPass" formControlName="newPass"
        placeholder="********" (keypress)="preventSpace($event)" />
      <img class="login--input--img" (click)="passType == 'password' ? passType = 'text' : passType = 'password' "
        [src]="passType == 'password' ? './../../../assets/imgs/esconder-senha.svg' : './../../../assets/imgs/mostrar-senha.svg' " />

      <div class="input_warning mt-10" [ngClass]="{'ok_warning': newPass?.value && newPass?.value.length >= 8 }">
        <img
          [src]="newPass?.value && newPass?.value.length >= 8 ? '../../../../assets/imgs/circle-check_green.svg' : '../../../../assets/imgs/circle-check.svg'">
        <span>A senha deve ter pelo menos 8 caracteres.</span>
      </div>

      <div class="input_warning" [ngClass]="{'ok_warning': newPass?.value && !verifyPassContainQuotation()}">
        <img
          [src]="newPass?.value && !verifyPassContainQuotation() ? '../../../../assets/imgs/circle-check_green.svg' : '../../../../assets/imgs/circle-check.svg'">
        <span>A senha não pode conter aspas.</span>
      </div>

      <div class="input_warning" [ngClass]="{'ok_warning': newPass?.value && !verifyPassNoContainNumberAndLetters()}">
        <img
          [src]="newPass?.value && !verifyPassNoContainNumberAndLetters() ? '../../../../assets/imgs/circle-check_green.svg' : '../../../../assets/imgs/circle-check.svg'">
        <span> A senha deve conter letras e números.</span>
      </div>

      <div class="input_warning"
        [ngClass]="{'ok_warning': newPass?.value && verifyPassContainLowerAndUpperCase()}">
        <img
          [src]="newPass?.value && verifyPassContainLowerAndUpperCase() ? '../../../../assets/imgs/circle-check_green.svg' : '../../../../assets/imgs/circle-check.svg'">
        <span> A senha deve conter pelo menos uma letra maiúscula e uma minúscula.</span>
      </div>

      <div class="input_warning" [ngClass]="{'ok_warning': newPass?.value && !verifyPassContainSequence()}">
        <img
          [src]="newPass?.value && !verifyPassContainSequence() ? '../../../../assets/imgs/circle-check_green.svg' : '../../../../assets/imgs/circle-check.svg'">
        <span>A senha não pode conter números sequenciais. Ex: 123.</span>
      </div>
    </div>
    <div>
      <button [disabled]="!loginFormGroup.valid" class="login--button" (click)="resetPass()">
        <span class="login--button-text">Redefinir Senha</span>
      </button>
    </div>
    }
  </form>
  <div class="footer--text__mobile d-flex align-items-end text-end">
    POLÍCIA MILITAR DO ESTADO DO RIO DE JANEIRO
    <img class="footer--pmerj-symbol__mobile ml-10" src="./../../../assets/imgs/pmerj-login.svg" />
  </div>
</div>
