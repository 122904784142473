<div class="container-fluid">
  <div class="row">
    <div class="col-12 d-flex align-items-center justify-content-center flex-column">
      <span class="title">Esqueci minha senha</span>
      <span class="subtitle">Enviaremos um código para seu email para que possa redefinir a sua senha.</span>
    </div>
    <div class="col-12">
      <form class="form__mobile mt-auto" [formGroup]="forgotPassForm">
        <div class="login--input--container">
          <label for="email" class="login--form--label d-block">
            Email<span
              [ngClass]="{'text-danger': !email?.value || email?.value && email?.touched && email?.status == 'INVALID'}">*</span>
          </label>
          <input class="login--input--control" type="text" id="email" autocomplete="off" formControlName="email"
            placeholder="Informe seu e-mail" />
        </div>
        <div class="input_warning">
          @if(!email?.value) {
          <span class="text-danger opacity-100">Campo obrigatório.</span>
          } @else {
          <span class="text-danger"
            [ngClass]="{'opacity-100': email?.value && email?.touched && email?.status == 'INVALID'}">Email
            inválido.</span>
          }
        </div>
      </form>
    </div>
    <div class="col-12">
      <button class="send_button" (click)="sendEmail()" [disabled]="isLoading || !forgotPassForm.valid">
        <span *ngIf="!isLoading">Enviar</span>
        <div *ngIf="isLoading" class="spinner"></div>
      </button>

    </div>
  </div>
</div>
