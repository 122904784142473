import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { GeneralPanelRedeMulherService } from "../../shared/services/general-panelRedeMulher/general-panelRedeMulher.service";
import { PORTAL_FILTER_CARD_TITLES } from "../../shared/components/filter-card/enums/filter-card-titles.enum";
import { HeaderTableSettings } from "../../shared/components/header-box-table/header-box-table.model";
import { getCPFMasked, getEmailMasked, getLastFiveYears, getOnlyDDNumber, getOnlyFirstName, getTypeUser } from "../../shared/utils";
import { TYPE_USERS } from "../../shared/enums/type-users";
import { AuthService } from "../../shared/services/auth/auth.service";


@Injectable({
  providedIn: 'root'
})

export class TableGeneralPanelRedeMulherService {
  constructor(private generalPanelService: GeneralPanelRedeMulherService, private authService: AuthService) { }

  public async getSettingsTable(page?: number, clickedCard?: any, queryText?: string, filterID?: string) {
    let config: HeaderTableSettings = {};
    let responseAPI = await this.getDataTableByCard(page, clickedCard, queryText, filterID);
    if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS) {
      let newArray: any[] = [];
      responseAPI?.data?.forEach((user: any) => {
        let newObject = {
          name: user?.nome ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getOnlyFirstName(user.nome) : user.nome : '',
          cpf: user?.cpf ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getCPFMasked(user.cpf) : user.cpf : '',
          phone: user?.telefone ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getOnlyDDNumber(user.telefone) : user.telefone : 'Telefone invalído',
          email: user?.email ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getEmailMasked(user.email) : user.email : '',
          status: user?.status,
        };
        newArray.push(newObject);
      });
      config.totalItens = responseAPI?.total;
      config.headerTitle = 'Todos os ' + clickedCard.title;
      config.itensPerPage = 10;
      config.showTextBox = true;
      config.titleSearch = 'Pesquise por nome';
      config.showFirstFilterBox = true;
      config.showClearButton = true;
      config.showExcelButton = true;
      config.optionsFirstFilter = [
        { name: 'Todos', value: 'todos' },
        { name: 'Ativo', value: 'true' },
        { name: 'Inativo', value: 'false' },
      ];
      config.titlesTables = [
        'Nome',
        'CPF',
        'Telefone',
        'E-mail',
        'Status',
        'Ver Perfil',
      ];
      config.showButton = true;
      config.buttonTable = { label: 'Visualizar' };
      config.tableBody = newArray;
    } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES) {
      config.totalItens = responseAPI?.total;
      config.headerTitle = clickedCard.title;
      config.itensPerPage = 5;
      config.showFirstFilterBox = true;
      config.showExcelButton = true;
      config.hideButtonSearch = true;
      config.optionsFirstFilter = [];
      let dates = getLastFiveYears();
      dates.forEach((year: number) => {
        config?.optionsFirstFilter?.push({ name: `${year}`, value: `${year}` });
      });
      config.titlesTables = [
        'Tipo',
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
        'Total'];
      config.showButton = false;
      config.tableBody = responseAPI?.data ? responseAPI?.data : [];
    } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_SPECIALIZED_CENTER) {
      config.totalItens = responseAPI?.total;
      config.headerTitle = clickedCard.title;
      config.itensPerPage = 10;
      config.showTextBox = true;
      config.titleSearch = 'Pesquisar por nome ou palavra';
      config.showFirstFilterBox = false;
      config.showSecondFilterBox = false;
      config.showClearButton = true;
      config.titlesTables = [
        'Autor',
        'Data',
        'Horário',
        'Qt. de Reports',
        'Resumo do Post',
        'Ver Perfil',
      ];
      config.showButton = true;
      config.buttonTable = { label: 'Visualizar' };
      config.tableBody = responseAPI?.data ? responseAPI?.data : [];

    } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR) {
      config.totalItens = responseAPI?.total;
      config.headerTitle = 'Todos os' + ' ' + clickedCard.title;
      config.itensPerPage = 10;
      config.showTextBox = true;
      config.titleSearch = 'Pesquisar por nome do agressor';
      config.showFirstFilterBox = false;
      config.showSecondFilterBox = false;
      config.showClearIc = true;
      config.titlesTables = [
        'Agressor',
        'CPF',
        'Medida Protetiva',
        'Data do Vencimento',
        'Vítima',
      ];
      config.showButton = false;
      config.tableBody = responseAPI?.data ? responseAPI?.data : [];
    };
    return config;
  }

  private async getDataTableByCard(page?: number, cardOption?: any, queryText?: string, filterID?: string) {
    let response: any;
    if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS) {
      await firstValueFrom(this.generalPanelService.usersTable(10, page, queryText, filterID)).then((responseAPI: any) => response = responseAPI).catch(() => response = null);
    } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES) {
      await firstValueFrom(this.generalPanelService.emergencyTable(5, page, filterID)).then((responseAPI: any) => response = responseAPI).catch(() => response = null);
      if (response) {
        let newResponse: any = [];
        response?.data?.forEach((element: any) => {
          let newObject = {
            type: element?.tipoChamado,
            jan: element?.Jan ? element.Jan : 0,
            fev: element?.Fev ? element.Fev : 0,
            mar: element?.Mar ? element.Mar : 0,
            abr: element?.Abr ? element.Abr : 0,
            mai: element?.Mai ? element.Mai : 0,
            jun: element?.Jun ? element.Jun : 0,
            jul: element?.Jul ? element.Jul : 0,
            ago: element?.Ago ? element.Ago : 0,
            set: element?.Set ? element.Set : 0,
            out: element?.Out ? element.Out : 0,
            nov: element?.Nov ? element.Nov : 0,
            dez: element?.Dez ? element.Dez : 0,
            total: element?.totalGeral
          };
          newResponse.push(newObject);
        });
        response.data = newResponse;
      }
    } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_SPECIALIZED_CENTER) {
      response = [];
      // await firstValueFrom(this.generalPanelService.reportedTable()).then((resService: any) => {
      //   response = resService.Results;
      // }).catch(() => {
      //   response = [];
      // });
    } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR) {
      response = [];
      // await firstValueFrom(this.generalPanelService.agressorTable()).then((resService: any) => {
      //   response = resService.Results;
      // }).catch(() => {
      //   response = [];
      // });
    };
    return response;
  }

}
