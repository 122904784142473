import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { RestApiService } from '../../rest-api/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class EmergencyService {
  private readonly baseUrl = environment.baseUrl190RJ;
  constructor(
    private restApiService: RestApiService,
  ) { }

  emergencyPageTable(limit?: number, page?: number, type?: string, status?: string, date?: string) {
    if (!limit) limit = 10;
    if (!page) page = 1;
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `tabela-emergencia?limit=${limit}&page=${page}${status ? `&status=${status}` : ''}${type ? `&tipoChamado=${type}` : ''}${date ? `&dataCadastro=${date}` : ''}`,
    };
    return this.restApiService.get(request);
  };

  emergencyGetTypes() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `tabela-emergencia/listar-tipos-chamados`,
    };
    return this.restApiService.get(request);
  };

  emergencyGetStatus() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `tabela-emergencia/listar-status`,
    };
    return this.restApiService.get(request);
  };

  emergencyExcel() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `tabela-emergencia/excel`,
      responseType: 'blob'
    };
    return this.restApiService.get(request);
  };
}
