import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { PORTAL_TYPES_NAMES } from '../../enums/portal-types-names.enum';
import { PORTAL_TYPES } from '../../enums/portal-types.enum';
import { PortalTypeService } from '../../services/portal-type.service';
import { SidebarOptionService } from '../sidebar/services/sidebar-option.service';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { SidebarCollpseComponent } from '../sidebar-collpse/sidebar-collpse.component';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { authSecretKey, getNameUser } from '../../utils';
import { AuthService } from '../../services/auth/auth.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, SidebarCollpseComponent],
  animations: [
    trigger('dropdownAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-10px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(-10px)' }))
      ])
    ])
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  currentPortalType: PORTAL_TYPES =
    this._portalTypeService.currentPortalType$.value.type;
  currentPortalName: PORTAL_TYPES_NAMES =
    this._portalTypeService.currentPortalType$.value.name;
  nameUser: string;
  dropdownVisible: boolean = false;
  @ViewChild('dropdownButton') dropdownButton: ElementRef;
  @ViewChild('dropdownContent') dropdownContent: ElementRef;
  portals: any[] = [
    { name: PORTAL_TYPES_NAMES.RJ_190, src: PORTAL_TYPES.RJ_190 },
    { name: PORTAL_TYPES_NAMES.REDE_MULHER, src: PORTAL_TYPES.REDE_MULHER },
    { name: PORTAL_TYPES_NAMES.REDE_ESCOLA, src: PORTAL_TYPES.REDE_ESCOLA }
  ]
  portalNames: any = PORTAL_TYPES_NAMES;

  constructor(
    private readonly _portalTypeService: PortalTypeService,
    private readonly _sidebarOptionsService: SidebarOptionService,
    private readonly _routerService: Router,
    private readonly localStorage: LocalStorageService,
    private readonly authService: AuthService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.listensToPortalChanges();
    this.getUserName();
  };

  getUserName() {
    let token;
    this.localStorage.getItem(authSecretKey).subscribe((resTok => token = resTok));
    this.nameUser = getNameUser(token);
  }

  listensToPortalChanges() {
    this._portalTypeService.currentPortalType$.subscribe((currentPortal) => {
      this.currentPortalType = currentPortal.type;
      this.currentPortalName = currentPortal.name;
    });
  }

  updatePortalType(portal: PORTAL_TYPES) {
    if (portal != this.currentPortalType) {
      this._portalTypeService.updatePortalType(portal);
      this._sidebarOptionsService.resetSidebarOption();
      this._routerService.navigateByUrl(portal);
      this.dropdownVisible = false;
    }
  }

  showCollpaseMenu() {
    const body = document.getElementsByTagName("body")[0].style.overflow = "hidden";
    const overflowHtml = document.getElementsByTagName("html")[0].style.overflow = "hidden";
  };

  logout() {
    this.authService.logout();
    this.router.navigateByUrl(PORTAL_TYPE_URLS.LOGIN);
  }

  toggleDropdown(event: MouseEvent) {
    event.stopPropagation();
    this.dropdownVisible = !this.dropdownVisible;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (
      this.dropdownButton && this.dropdownContent &&
      !this.dropdownButton.nativeElement.contains(event.target) &&
      !this.dropdownContent.nativeElement.contains(event.target)
    ) {
      this.dropdownVisible = false;
    }
  }

}
