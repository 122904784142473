import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { SIDEBAR_OPTIONS_LIST } from './consts/sidebar-options.const';
import { SIDEBAR_OPTION } from '../../models/sidebar-option';
import { PortalTypeService } from '../../services/portal-type.service';
import { SidebarOptionService } from './services/sidebar-option.service';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { PORTAL_INFO } from '../../models/portal-info';
import { SidebarOnlyShowIconsService } from './services/sidebar-only-show-icons/sidebar-only-show-icons.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit, OnDestroy {
  currentPortal: PORTAL_INFO | null = null;
  sidebarOptions = SIDEBAR_OPTIONS_LIST;
  onlyShowIcons: boolean;
  portalSubscription: Subscription | null = null;
  sidebarSubscription: Subscription | null = null;

  constructor(
    private readonly _portalTypeService: PortalTypeService,
    private readonly _sidebarOptionsService: SidebarOptionService,
    private readonly router: Router,
    private onlyShowIcService: SidebarOnlyShowIconsService,
  ) { }

  ngOnInit(): void {
    this.listensToPortalChanges();
    this.listensToSidebarOptionsChanges();
    this.checkOnlyShowIcons();
  }

  ngOnDestroy() {
    if (this.portalSubscription) this.portalSubscription?.unsubscribe();
    if (this.sidebarSubscription) this.sidebarSubscription?.unsubscribe();
  }


  listensToPortalChanges() {
    this.portalSubscription = this._portalTypeService.currentPortalType$.subscribe(
      (currentPortal: PORTAL_INFO) => {
        this.currentPortal = currentPortal;
      }
    );
  }

  listensToSidebarOptionsChanges() {
    this.sidebarSubscription = this._sidebarOptionsService.currentSidebarOption$.subscribe(
      (currentOption: SIDEBAR_OPTION) => {
        this.sidebarOptions.forEach((option) => {
          option.active = false;
          if (option.id === currentOption.id) {
            option.active = true;
          }
        });
      }
    );
  }

  checkOnlyShowIcons() {
    this.onlyShowIcService.only$.subscribe((value) => {
      this.onlyShowIcons = value;
    })
  }

  updateSidebarOption(currentOption: SIDEBAR_OPTION) {
    let portalType = this.router.url.includes(PORTAL_TYPE_URLS.RJ_190)
      ? PORTAL_TYPE_URLS.RJ_190
      : this.router.url.includes(PORTAL_TYPE_URLS.REDE_MULHER)
        ? PORTAL_TYPE_URLS.REDE_MULHER
        : PORTAL_TYPE_URLS.REDE_ESCOLA;
    this.router.navigateByUrl(`${portalType}/${currentOption.url}`);
    this._sidebarOptionsService.updateSidebarOption(currentOption);
  }

  showOnlyIconsChange() {
    this.onlyShowIcons = !this.onlyShowIcons;
    this.onlyShowIcService.setShow(this.onlyShowIcons);
  }
}
