<div class="portal-body__card-filter d-flex align-items-center" [ngClass]="checkActiveCard(portalCard)" role="button"
  (click)="updateSelectedPortalCard(portalCard)">
  <div class="position-relative">
    <div class="portal-filter-card--img-circle" ngClass="{{ portalType }}-{{ sidebarOptionType }}-{{
        portalCard.imgName
      }}-background"></div>
    <img class="portal-filter-card--img" src="./../../../../assets/{{ portalType }}-{{ sidebarOptionType }}-{{
        portalCard.imgName
      }}.svg" />
  </div>
  <div class="column-texts">
    <p class="portal-body__card-filter--label text-nowrap m-0">
      {{ portalCard.title }}
    </p>
    <p class="portal-body__card-filter--value m-0">
      {{ portalCard.value }}
    </p>
  </div>
</div>
