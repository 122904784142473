import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';

import { PORTAL_TYPES } from '../../../shared/enums/portal-types.enum';
import { SIDEBAR_OPTION_TYPES } from '../../../shared/enums/sidebar-option-types.enum';
import { PortalFilterCard } from '../../../shared/models/portal-filter-card';
import { FilterCardsService } from './services/filter-cards.service';
import { PortalTypeService } from '../../services/portal-type.service';
import { SidebarOptionService } from '../sidebar/services/sidebar-option.service';

@Component({
  selector: 'app-filter-card',
  standalone: true,
  imports: [CommonModule, DecimalPipe],
  templateUrl: './filter-card.component.html',
  styleUrl: './filter-card.component.scss',
})
export class FilterCardComponent implements OnInit {
  @Input() portalCard!: PortalFilterCard;
  portalType: PORTAL_TYPES =
    this._portalTypeService.currentPortalType$.value.type;
  sidebarOptionType: SIDEBAR_OPTION_TYPES =
    this._sidebarOptionsService.currentSidebarOption$.value.type;

  constructor(
    private _filterCardsService: FilterCardsService,
    private _portalTypeService: PortalTypeService,
    private _sidebarOptionsService: SidebarOptionService
  ) {}

  ngOnInit(): void {}

  updateSelectedPortalCard(portalCard: PortalFilterCard) {
    this._filterCardsService.updatePortalFilterCard(portalCard);
  }

  checkActiveCard(portalCard: PortalFilterCard) {
    return portalCard.active
      ? this.portalType +
          '-' +
          this.sidebarOptionType +
          '-' +
          portalCard.imgName +
          '-border'
      : '';
  }
}
