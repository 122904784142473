<div class="box_chat">
  <div class="chat__form w-fit-content">
    <select class="form-select" [ngModel]="currentYear" (ngModelChange)="changeYear($event)" name="currentYear">
      @for(date of dates; track date){
      @if(date == 0) {
      <option [value]="date">Todos</option>
      } @else {
      <option [value]="date">{{ date }}</option>
      }

      }
    </select>
  </div>
  <div class="interactions__chat--card">
    @if(isLoading) {
    <div>
      <div class="mb-10" *ngFor="let skeleton of [1, 2, 3]; let i = index">
        <div class="d-flex align-items-center mb-15" [ngClass]="{'justify-content-end': i % 2 != 0}">
          @if(i % 2 == 0) {
          <div class="skeleton-avatar mr-15"></div>
          <div class="skeleton-text skeleton-username mr-10"></div>
          <div class="skeleton-text skeleton-time"></div>
          } @else {
          <div class="skeleton-text skeleton-username mr-10"></div>
          <div class="skeleton-text skeleton-time"></div>
          <div class="skeleton-avatar ml-15"></div>
          }
        </div>
        <div class="my-auto chat__message-container d-flex align-items-center"
          [ngClass]="{'pl-12': i % 2 == 0, 'justify-content-end pr-12': i % 2 != 0}">
          <div class="skeleton-text skeleton-message"></div>
        </div>
      </div>
    </div>
    }
    @else {
    @for(chatMessage of chatMessages; track chatMessage.id; let i = $index){
    <div class="mb-10" [ngClass]="{
        'message-left': !chatMessage?.directionRight,
        'message-right': chatMessage?.directionRight,
        }">
      <div class="d-flex align-items-center mb-15" [ngClass]="{'justify-content-end': chatMessage?.directionRight}">

        @if(!chatMessage?.directionRight) {
        <img class="profilePic mr-15" [ngClass]="{'profilePicDefault': !chatMessage?.caminhoFotoPerfil}"
          [src]="chatMessage?.caminhoFotoPerfil ? chatMessage?.caminhoFotoPerfil : '../../../../../assets/imgs/user_img.svg'" />
        <div class="my-auto username mr-10">{{ chatMessage?.usuarioNome }}</div>
        <div class="time-of-message">{{ chatMessage?.dataHora }}</div>
        } @else {
        <div class="my-auto username mr-10">{{ chatMessage?.usuarioNome }}</div>
        <div class="time-of-message">{{ chatMessage?.dataHora }}</div>
        <img class="profilePic ml-15" [ngClass]="{'profilePicDefault': !chatMessage?.caminhoFotoPerfil}"
          [src]="chatMessage?.caminhoFotoPerfil ? chatMessage?.caminhoFotoPerfil : '../../../../../assets/imgs/user_img.svg'" />
        }
      </div>

      <div class="my-auto chat__message-container d-flex align-items-center" [ngClass]="{
            'pr-12': !chatMessage?.directionRight,
            'pl-12': chatMessage?.directionRight
          }">
        {{ chatMessage.comentario }}
      </div>
    </div>
    }
    @empty {
    <span class="no_coments">Sem comentários</span>
    }
    }
    <form class="form-group d-flex align-items-end" [formGroup]="formGroup">
      <div class="chat__send-message--container d-flex align-items-center cursor-pointer">
        <button type="submit" class="chat__send-message--button">Enviar</button>
        <img class="chat__send-message--button" src="./../../../../../assets/imgs/send-message.png" />
      </div>
      <textarea cols="20" rows="20" maxlength="1000" class="text-area pl-10 py-10" formControlName="message"
        placeholder="Comece a escrever aqui..." name="msg"></textarea>
    </form>
  </div>

</div>
