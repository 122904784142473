import { Component } from '@angular/core';
import { SidebarOptionTitleBandComponent } from '../title-band/sidebar-option-title-band.component';
import { DynamicButtonComponent } from '../dynamic-button/dynamic-button.component';
import { ButtonConfig } from '../dynamic-button/dynamic-button.model';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SIDEBAR_OPTIONS_URLS } from '../../enums/sidebar-option-urls.enum';
import { ValidateCpf, getPortalTypeFromLink } from '../../utils';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { UsersService } from '../../services/190rj/users-page/users.service';
import { LoadingService } from '../../services/loading/loading.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-user-page',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SidebarOptionTitleBandComponent, DynamicButtonComponent, NgxMaskDirective],
  providers: [
    provideNgxMask(),
  ],
  templateUrl: './new-user-page.component.html',
  styleUrl: './new-user-page.component.scss'
})
export class NewUserPageComponent {
  organs: any[] = [];
  profiles: any[] = [];
  show: boolean = false;

  formGroup: FormGroup = new FormGroup({});
  configButtonBack: ButtonConfig = { label: 'Voltar', imgSrc: "assets/back_arrow_ic.svg", handler: () => this.backPage() };

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private usersService: UsersService,
    private loadingService: LoadingService
  ) { }

  async ngOnInit() {
    this.loadingService.present();
    this.initializeFormGroup();
    this.profiles = await this.getProfiles();
    this.organs = await this.getOrgans();
    this.show = true;
    this.loadingService.dismiss();
  }

  initializeFormGroup() {
    this.formGroup = this._formBuilder.group({
      name: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])],
      cpf: [, Validators.compose([Validators.required, ValidateCpf])],
      phone: [, [Validators.required]],
      cellphone: [, [Validators.required]],
      email: ['', Validators.compose([Validators.email])],
      organ: [0, [Validators.required, Validators.min(1)]],
      profile: [0, [Validators.required, Validators.min(1)]],
    });
  }

  getProfiles(): Promise<any[]> {
    return new Promise(resolve => {
      this.usersService.getProfiles().subscribe({
        next: (response) => {
          resolve(response.data);
        },
        error: (err) => {
          console.log(err);
          resolve([]);
        },
      })
    })
  }

  getOrgans(): Promise<any[]> {
    return new Promise(resolve => {
      this.usersService.getOrgans().subscribe({
        next: (response) => {
          resolve(response.data);
        },
        error: (err) => {
          console.log(err);
          resolve([]);
        },
      })
    })
  }

  backPage() {
    window.history.back();
  }

  saveUser() {
    console.log(this.formGroup.value);
    this.loadingService.present();
    let requestBody = {
      "nome": this.formGroup.value.name,
      "cpf": this.formGroup.value.cpf.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      "telefone": this.formGroup.value.phone.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      "celular": this.formGroup.value.cellphone.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      "email": this.formGroup.value.email,
      // "senha": "string",
      // "rg": "string",
      // "funcionalId": "string",
      "profileId": Number(this.formGroup.value.organ),
      "orgaoId": Number(this.formGroup.value.profile),
      // "unidadeId": 0,
      // "grauHierarquicoId": 0,
      // "estaAtivo": true
    };
    this.usersService.createUser(requestBody).subscribe({
      next: (response) => {
        console.log(response);
        this.router.navigateByUrl(`${getPortalTypeFromLink(this.router.url)}/${SIDEBAR_OPTIONS_URLS.USERS}`).then(() => this.openToast("Cadastro salvo com sucesso.", 'success'));
      },
      error: (err) => {
        console.log(err);
        this.openToast('Erro ao cadastrar novo usuário!', 'error');
      },
    });
    this.loadingService.dismiss();
  }

  openToast(text: string, type: string): void {
    if (type == 'error') {
      this.toastr.error(text, 'Erro!');
    } else {
      this.toastr.success(text, 'Sucesso!');
    }

  }
}
