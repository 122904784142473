<div class="side-bar-collapse" [ngClass]="currentPortal.type">

  <div class="container-fluid">
    <div class="actions-top">
      <div role="button" class="header--logout-img">
        <img src="./../../../assets/power.svg" />
      </div>

      <div class="closebtn">
        <label (click)="closeCollapseMenu()" for="check-menu-hamburguer"><img src="./../../../assets/close.svg"></label>
      </div>

    </div>

    <div class="row">
      <div class="d-flex align-items-center justify-content-center">
        <section class="header--username-container">
          <span class="header--username">Luiza Carvalho</span>
        </section>

      </div>
    </div>


    <div class="row">
      <section class="sidebar-logo--container d-flex justify-content-center align-items-center">
        <img (click)="updatePortalType()" class="sidebar-logo--image"
          src="./../../../assets/{{ currentPortal.type }}.svg" />
      </section>
    </div>

    <div class="row">
      <section class="menu-option list-group pt-20">
        @for (option of sidebarOptions; track option.id) { @if(!option.portals ||
        (option.portals.length && option.portals.includes(currentPortal.name))){
        <a class="menu-option--text-container {{ currentPortal.type }}" [ngClass]="option.active ? 'active' : ''"
          (click)="updateSidebarOption(option)">
          <div class="container-fluid">
            <div class="row no-padding">
              <div class="col-12 gap-2 no-padding d-flex justify-content-center align-items-center">
                <div class="box-labels">
                  <div class="img">
                    <img class="menu-option--img my-auto" src="./../../../assets/sidebar-{{ option.img }}-active.svg" />
                  </div>
                  <label [ngClass]="option.active ? 'active' : ''" class="sidebar__button-label--default-color my-auto">
                    {{ option.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </a>
        } }
      </section>
    </div>

  </div>

</div>
