<div class="interactions__photos--card mt-20">
  <div class="interactions__photos--title mb-20">Fotos</div>

  <div class="carousel-container">
    <div class="carousel-inner">
      @for(photoGroup of photoGroups; track photoGroup.arquivoId; let i = $index) {
      <div class="carousel-item" [class.active]="i === currentIndex">
        <div class="photo-group">
          @for(photo of photoGroup; track $index) {
          <div [ngClass]="{'cs_pointer': !photo?.defaultPhoto, 'p-2': photo?.defaultPhoto}" class="photo-item"
            (click)="!photo?.defaultPhoto ? expandPage(photo?.imagemKey) : null">
            <img [src]="photo?.imagemKey" class="d-block" alt="Foto {{ i + 1 }}">
          </div>
          }
        </div>
      </div>
      } @empty {
      <span class="no_photos">Sem fotos disponíveis</span>
      }
    </div>

    @if(photoGroups.length > 2) {
    <button class="carousel-control-prev" (click)="prevSlide()">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" (click)="nextSlide()">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
    }
  </div>
</div>
