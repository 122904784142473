<section class="z-1 position-absolute container-fluid p-0">
  <app-header></app-header>
</section>
<div class="portal--container d-flex flex-row z-0 position-absolute" [ngClass]="portalType">
  <div class="container-fluid">
    <div class="row h-100">
      <div class="sidebar--container col-1-5" [ngClass]="onlyShowIcons ? 'sidebar__only_show_icons' : '' ">
        <app-sidebar></app-sidebar>
      </div>
      <div class="page col-10-5" [ngClass]="onlyShowIcons ? 'page__only_show_icons' : '' ">
        <router-outlet />
      </div>
    </div>
  </div>
</div>
