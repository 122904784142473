@if(currentPortal){
<div class="sidebar container-fluid">
  <div class="row">
    <section class="sidebar-logo--container d-flex justify-content-center align-items-center"
      [ngClass]="currentPortal.type" [class.bg-white]="onlyShowIcons">
      <img class="sidebar-logo--image" [class.remove_image]="onlyShowIcons"
        src="./../../../assets/{{ currentPortal.type }}.svg" />

        <div class="header--portal-type--img-container position-relative" [class.d-none]="!onlyShowIcons" [ngClass]="currentPortal.type">
          <img class="header--portal-type--img position-absolute"
            src="./../../../assets/{{ currentPortal.type }}-small.svg" />
        </div>

    </section>
  </div>

  <div class="row">
    <section class="menu-option list-group pt-20" [ngClass]="onlyShowIcons ? 'menu-option__only_show_icons' : '' ">
      @for (option of sidebarOptions; track option.id) { @if(!option.portals ||
      (option.portals.length && option.portals.includes(currentPortal.name))){
      <a class="menu-option--text-container {{ currentPortal.type }}" [ngClass]="option.active ? 'active' : ''"
        (click)="updateSidebarOption(option)">
        <img class="menu-option--img my-auto" src="./../../../assets/sidebar-{{ option.img }}{{
            option.active ? '-active.svg' : '.svg'
          }}" />
        <p class="sidebar__button-label--default-color my-auto" [class.active]="option?.active"
          [class.d-none]="onlyShowIcons">
          {{ option.name }}
        </p>
      </a>
      } }
    </section>
  </div>

  <!-- <div (click)="showOnlyIconsChange()" class="sidebar--button_clean">
    <img src="assets/right-triangular-rounded.svg" [ngClass]="currentPortal.type" alt="">
  </div> -->
</div>
}
