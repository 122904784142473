<div class="interactions__chat--card position-relative">
  @for(chatMessage of chatMessages; track chatMessage.id){
  <div class="mb-10">
    <div class="d-flex align-items-center mb-15">
      <img class="mr-15" src="./../../../../assets/user-picture.png" />
      <div class="my-auto username mr-10">{{ chatMessage.userName }}</div>
      <div class="time-of-message">{{ chatMessage.timeOfMessage }}</div>
    </div>
    <div
      class="my-auto chat__message-container d-flex align-items-center sender pr-12"
    >
      {{ chatMessage.messageContent }}
    </div>
  </div>

  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-end mb-15">
      <div class="my-auto username">{{ chatMessage.userName }}</div>
      <div class="time-of-message ml-10">{{ chatMessage.timeOfMessage }}</div>
      <img class="ml-15" src="./../../../../assets/user-picture.png" />
    </div>
    <div
      class="d-flex justify-content-end align-items-center chat__message-container receiver pl-12"
    >
      {{ chatMessage.messageContent }}
    </div>
  </div>
  }
  <form class="form-group d-flex align-items-end" [formGroup]="formGroup">
    <div
      class="chat__send-message--container d-flex align-items-center cursor-pointer"
    >
      <button type="submit" class="chat__send-message--button">Enviar</button>
      <img
        class="chat__send-message--button"
        src="./../../../../../assets/send-message.png"
      />
    </div>
    <textarea
      cols="20"
      rows="20"
      maxlength="1000"
      class="text-area pl-10 py-10"
      formControlName="message"
      placeholder="Comece a escrever aqui..."
      name="msg"
    ></textarea>
  </form>
</div>
