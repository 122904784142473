import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as ol from 'ol';
import { View, Map } from 'ol';
import { OSM } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat } from 'ol/proj';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import { Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import Control from 'ol/control/Control';
import { AddressDennoucement } from './dennoucement-map.model';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-dennoucement-map',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dennoucement-map.component.html',
  styleUrl: './dennoucement-map.component.scss'
})
export class DennoucementMapComponent implements OnInit, OnChanges {
  @Input() address: AddressDennoucement;
  private map!: Map;
  private marker: Feature | undefined;
  constructor() { }

  ngOnInit(): void {
    this.initializeMap();
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['address'].currentValue) {
      this.updateMap();
    }
  };

  initializeMap() {
    const osmLayer = new TileLayer({
      source: new OSM(),
    });
    this.marker = new ol.Feature({
      geometry: new Point(fromLonLat([Number(this.address?.longitude), Number(this.address?.latitude)])),
    });
    if (this.address?.iconeMapa) {
      this.marker.setStyle(new Style({
        image: new Icon({
          src: this.address?.iconeMapa,
          scale: 0.4,
        }),
      }));
    }

    const vectorSource = new VectorSource({
      features: [this.marker],
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });
    const view = new View({
      center: fromLonLat([Number(this.address?.longitude), Number(this.address?.latitude)]),
      zoom: 18,
    });

    this.map = new Map({
      target: 'map',
      layers: [osmLayer, vectorLayer],
      view: view,
      controls: []
    });
    this.addCentralizeControl();
  }

  private updateMap(): void {
    if (this.map && this.marker) {
      const newPosition = fromLonLat([Number(this.address?.longitude), Number(this.address?.latitude)]);
      this.marker.setGeometry(new Point(newPosition));
      this.marker.setStyle(new Style({
        image: new Icon({
          src: this.address?.iconeMapa ? this.address?.iconeMapa : '',
          scale: 0.4,
        }),
      }));
      this.map.getView().setCenter(newPosition);
    }
  }

  private addCentralizeControl(): void {
    if (this.map && this.marker) {
      const centralizeControl = new CentralizeControl({
        marker: this.marker,
        map: this.map,
      });
      this.map.addControl(centralizeControl);
    }
  }
}

class CentralizeControl extends Control {
  private marker: Feature;
  private map: Map;
  private button: HTMLElement;

  constructor(options: { marker: Feature, map: Map }) {
    const button = document.createElement('button');
    const svgIcon = document.createElement('img');
    svgIcon.src = 'assets/imgs/location.svg';
    svgIcon.alt = 'Centralizar mapa';
    svgIcon.style.width = '15px';
    svgIcon.style.height = '15px';
    button.appendChild(svgIcon);
    button.classList.add('centralize-btn');

    super({
      element: button,
      target: options.map.getTargetElement(),
    });

    this.marker = options.marker;
    this.map = options.map;
    this.button = button;

    button.addEventListener('click', () => {
      this.centerMap();
    });
  }

  private centerMap(): void {
    const geometry = this.marker.getGeometry();
    if (geometry instanceof Point) {
      const coordinates = geometry.getCoordinates();
      this.map.getView().setCenter(coordinates);
      this.map.getView().setZoom(18);
    }
  }
}
