import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { ChatService } from './services/chat.service';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss',
})
export class ChatComponent implements OnInit {
  formGroup: FormGroup = new FormGroup({});
  chatMessages: Array<any> = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _chatService: ChatService
  ) {}

  ngOnInit(): void {
    this.initializeFormGroup();
    this.getChatMessages();
  }

  initializeFormGroup() {
    this.formGroup = this._formBuilder.group({
      message: ['', []],
    });
  }

  getChatMessages() {
    this._chatService.getChatMessages().subscribe((chatMessages) => {
      this.chatMessages = chatMessages;
    });
  }
}
