import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarOptionTitleBandComponent } from '../../shared/components/title-band/sidebar-option-title-band.component';
import { FilterCardComponent } from '../../shared/components/filter-card/filter-card.component';
import { SingleDoughnutChartComponent } from '../../shared/components/single-doughnut-card/single-doughnut-card.component';
import { LineChartComponent } from '../../shared/components/line-chart/line-chart.component';
import { HeaderBoxTableComponent } from '../../shared/components/header-box-table/header-box-table.component';
import { PORTAL_FILTER_CARD_TITLES } from '../../shared/components/filter-card/enums/filter-card-titles.enum';
import { FilterCardsService } from '../../shared/components/filter-card/services/filter-cards.service';
import { TopFiveComponent } from '../../shared/components/top-five/top-five.component';
import { EXTRAS_PAGES_URLS } from '../../shared/enums/extras-pages-urls.enum';
import { PortalFilterCard } from '../../shared/models/portal-filter-card';
import { LoadingService } from '../../shared/services/loading/loading.service';
import { PORTAL_TYPES } from '../../shared/enums/portal-types.enum';
import { downloadFile, getDateDDMM, getDateDDMMYYYY, verifyErrorIsConection, verifyErrorIsTimeout } from '../../shared/utils';
import { ToastrService } from 'ngx-toastr';
import { GeneralPanelRedeMulherService } from '../../shared/services/general-panelRedeMulher/general-panelRedeMulher.service';
import { TableGeneralPanelRedeMulherService } from '../services/dynamic-table__general-panel-rede-mulher.service';

@Component({
  selector: 'app-rede-mulher-general-panel',
  standalone: true,
  imports: [
    CommonModule,
    SidebarOptionTitleBandComponent,
    FilterCardComponent,
    SingleDoughnutChartComponent,
    LineChartComponent,
    HeaderBoxTableComponent,
    TopFiveComponent,
    LineChartComponent
  ],
  templateUrl: './general-panel.component.html',
  styleUrl: './general-panel.component.scss',
})
export class RedeMulherGeneralPanelComponent implements OnInit, OnDestroy {
  portalCards: Array<PortalFilterCard> = [];
  portalCardsSubscription: Subscription | null = null;
  myEventSubscribeCurrentPortal: Subscription | null = null;
  selectedPortalFilterCard: PortalFilterCard | null = null;
  PORTAL_FILTER_CARD_TITLES = PORTAL_FILTER_CARD_TITLES;
  tableConfig: any = {};
  portalCardsTitle = PORTAL_FILTER_CARD_TITLES;
  topFive: Array<any> = [];
  topFiveData: any = {};
  currentYear: string;
  searchQuery: string = "";
  filterQuery: string = "";
  typesOrStatus: any[] = [];

  constructor(
    private _portalFilterCardsService: FilterCardsService,
    private _tableService: TableGeneralPanelRedeMulherService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private redeMulherService: GeneralPanelRedeMulherService,
    private toastr: ToastrService
  ) { }

  async ngOnInit() {
    this.loadingService.present();
    this.emitNewPortalFilterCards();
    this.listenToFilterCardsChanges();
    this.listenToChangesOnSelectedPortalFilterCard();
    await this.getConfigTable();
    await this.getNumericDatas();
    this.loadingService.dismiss();
  };

  ngOnDestroy(): void {
    if (this.portalCardsSubscription) this.portalCardsSubscription?.unsubscribe();
    if (this.myEventSubscribeCurrentPortal) this.myEventSubscribeCurrentPortal?.unsubscribe();
  }

  getNumericDatas(): Promise<boolean> {
    return new Promise((resolve) => {
      this.redeMulherService.getTotalStatics().subscribe({
        next: (response) => {
          this.portalCards.forEach(card => {
            if (card.title == 'Usuários') card.value = response.totalUsuarios;
            if (card.title == 'Emergências') card.value = response.totalEmergencias
            if (card.title == 'Centros Especializados') card.value = response?.totalCentro ? response?.totalCentro : 0;
            if (card.title == 'Agressores') card.value = response?.totalAgressores ? response?.totalAgressores : 0;
          });
        },
        error: (err) => {
          console.log(err);
        },
      }).add(() => resolve(true));
    })
  };

  emitNewPortalFilterCards() {
    this._portalFilterCardsService.emitNewPortalFilterCards(
      PORTAL_TYPES.REDE_MULHER
    );
  }

  listenToFilterCardsChanges() {
    this.portalCardsSubscription =
      this._portalFilterCardsService.portalFilterCard$.subscribe(
        (portalCards: Array<PortalFilterCard>) => {
          this.portalCards = portalCards;
        }
      );
  }

  listenToChangesOnSelectedPortalFilterCard() {
    this.myEventSubscribeCurrentPortal = this._portalFilterCardsService.currentPortalFilterCard$.subscribe(
      (selectedPortalFilterCard: PortalFilterCard | null) => {
        if (selectedPortalFilterCard) {
          this.selectedPortalFilterCard = selectedPortalFilterCard;
          this.topFiveData.title = selectedPortalFilterCard.title;
          this.portalCards.forEach((portalCard: PortalFilterCard) => {
            portalCard.active = false;
            if (portalCard.id === selectedPortalFilterCard.id) {
              portalCard.active = true;
            }
          });
        };
      }
    );
  };

  async clickCard() {
    this.loadingService.present();
    if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.REDE_MULHER_USERS) {
      await this.getConfigTable();
      this.loadingService.dismiss();
    }
    if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.REDE_MULHER_EMERGENCIES) {
      await this.topEmergencyData();
      await this.getConfigTable();
      this.loadingService.dismiss();
    };
    if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.REDE_MULHER_SPECIALIZED_CENTER) {
      // await this.topEmergencyData();
      await this.getConfigTable();
      this.loadingService.dismiss();
    };
    if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.REDE_MULHER_AGRESSOR) {
      // await this.topDenouncementData();
      await this.getConfigTable();
      this.loadingService.dismiss();
    };
  };

  topEmergencyData(): Promise<boolean> {
    this.topFive = [];
    return new Promise((resolve) => {
      this.redeMulherService.emergencyTops().subscribe({
        next: (response) => {
          response?.forEach((oneTop: any) => {
            oneTop.title = oneTop.tipoChamado;
            oneTop.value = oneTop.total;
            oneTop.color = oneTop.total % 2 == 0 ? '#895BF1' : '#FEBF0F';
            delete oneTop.tipoChamado;
            delete oneTop.total;
            this.topFive.push(oneTop);
          });
          var emegencyCard = this.portalCards.filter(function (card) {
            return card.title === PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES;
          })[0];
          this.topFiveData.total = emegencyCard.value;
        },
        error: (err) => {
          console.log(err);
        },
      }).add(() => resolve(true));
    })
  };

  topReportedData(): Promise<boolean> {
    this.topFive = [];
    return new Promise((resolve) => {
      this.redeMulherService.reportedTops().subscribe({
        next: (response) => {
          response.data.forEach((oneTop: any) => {
            oneTop.title = oneTop.Emergencia;
            oneTop.value = oneTop.quantidade;
            oneTop.color = oneTop.value % 2 == 0 ? '#895BF1' : '#FEBF0F';
            delete oneTop.Emergencia;
            delete oneTop.quantidade;
            this.topFive.push(oneTop);
          });
          var emegencyCard = this.portalCards.filter(function (card) {
            return card.title === PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES;
          })[0];
          this.topFiveData.total = emegencyCard.value;
        },
        error: (err) => {
          console.log(err);
        },
      }).add(() => resolve(true));
    })
  };


  getConfigTable(): Promise<boolean> {
    return new Promise(async resolve => {
      this.searchQuery = "";
      this.filterQuery = "";
      this.tableConfig = await this.getData(1);
      if (this.tableConfig?.optionsFirstFilter) this.typesOrStatus = this.tableConfig.optionsFirstFilter;
      this.configHandleButton();
      this.tableConfig.currentPage = 1;
      resolve(true);
    })
  }

  public clickExcelButton() {
    this.loadingService.present();
    let request: Observable<any> = this.selectedPortalFilterCard?.title === PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS ?
      this.redeMulherService.generalPanelUsersTableExcelExport() :
      this.redeMulherService.generalPanelEmergencyTableExcelExport(this.currentYear);
    request.subscribe({
      next: (response) => {
        downloadFile(response, `Rede Mulher_${this.selectedPortalFilterCard?.title}_${this.currentYear ? getDateDDMM() + this.currentYear : getDateDDMMYYYY()}`);
        this.loadingService.dismiss();
      },
      error: (err) => {
        if (!verifyErrorIsTimeout(err) && !verifyErrorIsConection(err)) this.openToast('Erro ao baixar arquivo Excel!');
        this.loadingService.dismiss();
      }
    })
  }

  public async searchAndFilterItens(event: any) {
    this.searchQuery = event?.searchValue;
    this.filterQuery = event?.filterFirstValue;
    if (this.filterQuery && this.selectedPortalFilterCard?.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES) this.currentYear = event.filterFirstValue;
    if (this.searchQuery || this.filterQuery) {
      this.loadingService.present();
      this.tableConfig = await this.getData(1, this.searchQuery, this.filterQuery);
      this.configHandleButton();
      this.tableConfig.optionsFirstFilter = this.typesOrStatus;
      this.tableConfig.currentPage = 1;
      this.loadingService.dismiss();
    }
  }

  async swapPage(page: number) {
    this.loadingService.present();
    this.tableConfig = await this.getData(page, this.searchQuery, this.filterQuery);
    this.configHandleButton();
    this.tableConfig.optionsFirstFilter = this.typesOrStatus;
    this.tableConfig.currentPage = page;
    this.loadingService.dismiss();
  }

  public async clearSearchTable() {
    this.loadingService.present();
    this.searchQuery = "";
    this.filterQuery = "";
    this.tableConfig = await this.getData(1);
    this.tableConfig.optionsFirstFilter = this.typesOrStatus;
    this.tableConfig.currentPage = 1;
    this.loadingService.dismiss();
  }

  public filterDepedingCard(value: string, object: any): boolean {
    switch (this.selectedPortalFilterCard?.title) {
      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS:
        if (value == 'true' && object.Status == 'Ativo') return true;
        else if (value == 'false' && object.Status == 'Inativo') return true;
        return false;

      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES:
        if (value.toLowerCase().includes(object.type.toLowerCase()))
          return true;
        return false;

      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_SPECIALIZED_CENTER:
        if (value.toLowerCase().includes(object.type.toLowerCase()))
          return true;
        return false;

      default:
        return false;
    }
  }

  public validateObject(object: any, textValue: string): boolean {
    switch (this.selectedPortalFilterCard?.title) {
      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS:
        if (
          object.Email.toString().toLowerCase().includes(textValue) ||
          object.CPF.toString().includes(textValue) ||
          object.Nome.toString().includes(textValue) ||
          object.Telefone.toString().toLowerCase().includes(textValue) ||
          object.Status.toString().toLowerCase().includes(textValue)
        )
          return true;
        return false;
      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_SPECIALIZED_CENTER:
        if (
          object.name.toLowerCase().includes(textValue) ||
          object.date.toString().includes(textValue) ||
          object.hour.toLowerCase().includes(textValue) ||
          object.amount.toString().toLowerCase().includes(textValue) ||
          object.resume.toLowerCase().includes(textValue)
        )
          return true;
        return false;

      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR:
        if (
          object.NM_Agressor.toLowerCase().includes(textValue) ||
          object.NR_CPF.toString().includes(textValue) ||
          object.NR_Processo.toLowerCase().includes(textValue) ||
          object.DT_Validade.toString().toLowerCase().includes(textValue) ||
          object.NM_Usuario.toLowerCase().includes(textValue)
        )
          return true;
        return false;

      default:
        return false;
    }
  }



  private async getData(page?: number, queryText?: string, filterID?: string) {
    return await this._tableService.getSettingsTable(page, this.selectedPortalFilterCard, queryText, filterID);
  }

  private configHandleButton() {
    if (this.tableConfig.showButton) {
      this.tableConfig.buttonTable.handler = (dataObjectTable: any) => {
        this.openVisualize(dataObjectTable);
      };
    };
  }
  openVisualize(data: any) {
    let page: string;
    let passData: any;
    if (this.selectedPortalFilterCard?.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS) {
      page = EXTRAS_PAGES_URLS.VIEW_USER;
      passData = { userData: data };
    } else {
      page = EXTRAS_PAGES_URLS.VIEW_REPORTED;
      passData = { reportData: data, card: this.selectedPortalFilterCard };
    }
    this.router.navigate([page], { relativeTo: this.route, state: passData });
  }


  openToast(text: string): void {
    this.toastr.error(text, 'Erro!');
  }
}
