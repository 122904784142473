<section class="portal-header--container">
  <app-sidebar-option-title-band></app-sidebar-option-title-band>
</section>

@if(show) {
<section class="portal-body--container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="header">
          <span class="title">Lista de Usuários</span>
          <div class="d-flex gap-2">
            <app-dynamic-button [buttonConfig]="configButtonToggleUsers"></app-dynamic-button>
            @if(showRegisterUserButton()) {
            <app-dynamic-button [buttonConfig]="configButtonRegister"></app-dynamic-button>
            }
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <app-header-box-table [headerBoxTableSettings]="tableConfig" (searchClick)="searchAndFilterItens($event)"
          (clearSearchClick)="clearSearchTable()" (swapPage)="swapPage($event)"></app-header-box-table>
      </div>
    </div>
  </div>


</section>
}
