import { PORTAL_TYPES_NAMES } from '../../../enums/portal-types-names.enum';
import { SIDEBAR_OPTION_NAMES } from '../../../enums/sidebar-option-names.enum';
import { SIDEBAR_OPTION_TYPES } from '../../../enums/sidebar-option-types.enum';
import { SIDEBAR_OPTIONS_URLS } from '../../../enums/sidebar-option-urls.enum';
import { SIDEBAR_OPTION } from '../../../models/sidebar-option';

export const SIDEBAR_OPTIONS = {
  GENERAL_PANEL: {
    id: 1,
    name: SIDEBAR_OPTION_NAMES.GENERAL_PANEL,
    type: SIDEBAR_OPTION_TYPES.GENERAL_PANEL,
    img: SIDEBAR_OPTION_TYPES.GENERAL_PANEL,
    url: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL,
    active: true,
  },
  REPORTED: {
    id: 2,
    name: SIDEBAR_OPTION_NAMES.REPORTED,
    type: SIDEBAR_OPTION_TYPES.REPORTED,
    img: SIDEBAR_OPTION_TYPES.REPORTED,
    url: SIDEBAR_OPTIONS_URLS.REPORTED,
    active: false,
    portals: [PORTAL_TYPES_NAMES.RJ_190],
  },
  EMERGENCY: {
    id: 3,
    name: SIDEBAR_OPTION_NAMES.EMERGENCY,
    type: SIDEBAR_OPTION_TYPES.EMERGENCY,
    img: SIDEBAR_OPTION_TYPES.EMERGENCY,
    url: SIDEBAR_OPTIONS_URLS.EMERGENCY,
    active: false,
    portals: [PORTAL_TYPES_NAMES.RJ_190, PORTAL_TYPES_NAMES.REDE_MULHER],
  },
  ANNOUNCEMENTS: {
    id: 4,
    name: SIDEBAR_OPTION_NAMES.ANNOUNCEMENTS,
    type: SIDEBAR_OPTION_TYPES.ANNOUNCEMENTS,
    img: SIDEBAR_OPTION_TYPES.ANNOUNCEMENTS,
    url: SIDEBAR_OPTIONS_URLS.ANNOUNCEMENTS,
    active: false,
    portals: [PORTAL_TYPES_NAMES.RJ_190, PORTAL_TYPES_NAMES.REDE_MULHER],
  },
  INTERACTIONS: {
    id: 5,
    name: SIDEBAR_OPTION_NAMES.INTERACTIONS,
    type: SIDEBAR_OPTION_TYPES.INTERACTIONS,
    img: SIDEBAR_OPTION_TYPES.INTERACTIONS,
    url: SIDEBAR_OPTIONS_URLS.INTERACTIONS,
    active: false,
    portals: [PORTAL_TYPES_NAMES.RJ_190, PORTAL_TYPES_NAMES.REDE_MULHER],
  },
  USERS: {
    id: 6,
    name: SIDEBAR_OPTION_NAMES.USERS,
    type: SIDEBAR_OPTION_TYPES.USERS,
    img: SIDEBAR_OPTION_TYPES.USERS,
    url: SIDEBAR_OPTIONS_URLS.USERS,
    active: false,
    portals: [PORTAL_TYPES_NAMES.RJ_190, PORTAL_TYPES_NAMES.REDE_MULHER],
  },
};

export const SIDEBAR_OPTIONS_LIST: Array<SIDEBAR_OPTION> = [
  SIDEBAR_OPTIONS.GENERAL_PANEL,
  SIDEBAR_OPTIONS.REPORTED,
  SIDEBAR_OPTIONS.EMERGENCY,
  SIDEBAR_OPTIONS.ANNOUNCEMENTS,
  SIDEBAR_OPTIONS.INTERACTIONS,
  SIDEBAR_OPTIONS.USERS,
];
