import { Injectable } from '@angular/core';
import Chart from 'chart.js/auto';
import { BarDataset } from '../models/bar-dataset';
import { BAR_CHART_COLORS } from '../consts/bar-chart-colors.const';
import { PORTAL_FILTER_CARD_TITLES } from '../../filter-card/enums/filter-card-titles.enum';
import { PORTAL_TYPES } from '../../../enums/portal-types.enum';
import { PortalFilterCard } from '../../../models/portal-filter-card';
import { firstValueFrom } from 'rxjs';
import { RedeMulherService } from '../../../services/rede-mulher/rede-mulher.service';
import { GeneralPanelService } from '../../../services/190rj/painel-geral-page/painel-geral.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class BarChartService {
  chart: any;
  days: any = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  constructor(
    private generalPanelRJ190: GeneralPanelService,
    private redeMulherService: RedeMulherService,
    private toastr: ToastrService
  ) { }

  async getChartData(portalType: PORTAL_TYPES, currentFilterCard: PortalFilterCard) {
    if (
      portalType === PORTAL_TYPES.RJ_190 &&
      currentFilterCard.title === PORTAL_FILTER_CARD_TITLES.RJ_190_USERS
    ) {
      let values: any[] = [];
      let labels: any[] = [];
      await firstValueFrom(this.generalPanelRJ190.statiscsMonthlyUsers()).then((response) => {
        response = [
          { dia: '01', mes: 11, ano: 2024, total: 11 },
          { dia: '02', mes: 11, ano: 2024, total: 5 },
          { dia: '03', mes: 11, ano: 2024, total: 4 },
          { dia: '04', mes: 11, ano: 2024, total: 5 },
          { dia: '05', mes: 11, ano: 2024, total: 20 },
          { dia: '06', mes: 11, ano: 2024, total: 5 },
          { dia: '07', mes: 11, ano: 2024, total: 2 },
          { dia: '08', mes: 11, ano: 2024, total: 3 },
          { dia: '09', mes: 11, ano: 2024, total: 6 },
          { dia: 10, mes: 11, ano: 2024, total: 7 },
          { dia: 11, mes: 11, ano: 2024, total: 8 },
          { dia: 12, mes: 11, ano: 2024, total: 4 },
          { dia: 13, mes: 11, ano: 2024, total: 2 },
          { dia: 14, mes: 11, ano: 2024, total: 4 },
          { dia: 15, mes: 11, ano: 2024, total: 4 },
          { dia: 16, mes: 11, ano: 2024, total: 9 },
          { dia: 17, mes: 11, ano: 2024, total: 4 },
          { dia: 18, mes: 11, ano: 2024, total: 22 },
          { dia: 19, mes: 11, ano: 2024, total: 21 },
          { dia: 20, mes: 11, ano: 2024, total: 25 },
          { dia: 21, mes: 11, ano: 2024, total: 24 },
          { dia: 22, mes: 11, ano: 2024, total: 17 },
          { dia: 23, mes: 11, ano: 2024, total: 11 },
          { dia: 24, mes: 11, ano: 2024, total: 12 },
          { dia: 25, mes: 11, ano: 2024, total: 16 },
          { dia: 26, mes: 11, ano: 2024, total: 15 },
          { dia: 27, mes: 11, ano: 2024, total: 22 },
          { dia: 28, mes: 11, ano: 2024, total: 21 },
          { dia: 29, mes: 11, ano: 2024, total: 34 },
          { dia: 30, mes: 11, ano: 2024, total: 34 },
          { dia: 31, mes: 11, ano: 2024, total: 39 },
        ];
        const d = new Date();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        response.forEach((element: any) => {
          if (element.ano == year) {
            if (element.mes == month) {
              var d = new Date(`${month}/${element.dia}/${year}`);
              var dayName = this.days[d.getDay()];
              values.push(element.total);
              if (element.dia.toString().length < 2) element.dia = `0${element.dia}`
              labels.push(`${element.dia}. ${dayName}`);
            }
          }
        });
      }).catch(() => {
        this.openToastError('Erro ao buscar estatísticas mensal de usuários!');
        values = [];
      });
      return [
        new BarDataset(
          values,
          BAR_CHART_COLORS.RJ_190.USERS,
          labels,
        ),
      ];
    } else if (
      portalType === PORTAL_TYPES.REDE_MULHER &&
      currentFilterCard.title === PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS
    ) {
      let values: any[] = [];
      let labels: any[] = [];
      await firstValueFrom(this.redeMulherService.statiscsMonthlyUsers()).then((response) => {
        const d = new Date();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        response.Results.forEach((element: any) => {
          if (element.NR_Ano == year) {
            if (element.NR_Mes == month) {
              var d = new Date(`${month}/${element.NR_Dia}/${year}`);
              var dayName = this.days[d.getDay()];
              values.push(element.QT_Total);
              if (element.NR_Dia.toString().length < 2) element.NR_Dia = `0${element.NR_Dia}`
              labels.push(`${element.NR_Dia}. ${dayName}`);
            }
          }
        });
      }).catch(() => {
        this.openToastError('Erro ao buscar estatísticas mensal de usuários!');
        values = [];
      });
      return [
        new BarDataset(
          values,
          BAR_CHART_COLORS.RJ_190.USERS,
          labels
        ),
      ];
    }

    else if (
      portalType === PORTAL_TYPES.REDE_MULHER &&
      currentFilterCard.title === PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES
    ) {
      let values: any[] = [];
      let labels: any[] = [];
      await firstValueFrom(this.redeMulherService.statiscsMonthlyEmergencys()).then((response) => {
        const d = new Date();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        response.Results.forEach((element: any) => {
          if (element.NR_Ano == year) {
            if (element.NR_Mes == month) {
              var d = new Date(`${month}/${element.NR_Dia}/${year}`);
              var dayName = this.days[d.getDay()];
              values.push(element.QT_Total);
              if (element.NR_Dia.toString().length < 2) element.NR_Dia = `0${element.NR_Dia}`
              if (!labels.includes(`${element.NR_Dia}. ${dayName}`)) labels.push(`${element.NR_Dia}. ${dayName}`);
            }
          }
        });
      }).catch(() => values = []);
      return [
        new BarDataset(
          values,
          BAR_CHART_COLORS.RJ_190.USERS,
          labels
        ),
      ];
    }


    else if (
      portalType === PORTAL_TYPES.REDE_ESCOLA &&
      currentFilterCard.title === PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_USERS
    ) {
      return [
        new BarDataset(
          [500, 1000, 500, 1000, 500, 500, 1000, 750, 1000, 500],
          BAR_CHART_COLORS.REDE_ESCOLA.USERS,
          []
        ),
        new BarDataset(
          [-500, -750, -500, -500, -500, -500, -750, -500, -750, -500],
          BAR_CHART_COLORS.REDE_ESCOLA.USERS2,
          []
        ),
      ];
    }

    return [
      new BarDataset(
        [500, 1000, 500, 1000, 500, 500, 1000, 750, 1000, 500],
        BAR_CHART_COLORS.RJ_190.USERS,
        []
      ),
      new BarDataset(
        [-500, -750, -500, -500, -500, -500, -750, -500, -750, -500],
        BAR_CHART_COLORS.RJ_190.USERS2,
        []
      ),
    ];
  }

  buildChart(labels: string[], dataset: BarDataset[]): any {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart('myChart-Bar', {
      type: 'bar',
      data: {
        labels: labels,
        datasets: dataset,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        events: ['mousemove'],
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            backgroundColor: '#85A3BBCC',
            displayColors: true,
            cornerRadius: 4,
            callbacks: {
              label: function (context) {
                let label = '';
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('pt-BR').format(
                    context.parsed.y
                  );
                }
                return label;
              },
              labelColor: function (context) {
                return {
                  borderColor: '#85A3BBCC',
                  backgroundColor: '',
                  borderWidth: 0,
                  borderRadius: 4,
                };
              },
              labelTextColor: function (context) {
                return '#FFFFFF';
              },
            },
          },
        },
        scales: {
          y: {
            stacked: true,
            grid: {
              lineWidth: ({ tick }) => (tick.value == 0 ? 5 : 1),
              color: ({ tick }) => (tick.value == 0 ? '#99B2C6' : '#D9E1E7'),
            },
            ticks: {
              color: '#B3B8BD',
              callback: function (value, index, ticks) {
                let formatedValue = value.toString();
                if (Math.abs(+value) >= 1000) {
                  formatedValue = +value / 1000 + 'k';
                }
                return formatedValue;
              },
            },
          },
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            beginAtZero: true,
            ticks: {
              color: '#B3B8BD',
            },
          },
        },
      },
    });
  }

  openToastError(text: string): void {
    this.toastr.error(text, 'Erro!');
  }
}
