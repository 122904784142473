import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SidebarOptionTitleBandComponent } from '../title-band/sidebar-option-title-band.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InteractionsService } from './services/interactions.service';
import { ChatComponent } from './chat/chat.component';
import { InteractionDetailsComponent } from './interaction-details/interaction-details.component';
import { PhotosComponent } from './photos/photos.component';
import { DennoucementMapComponent } from './dennoucement-map/dennoucement-map.component';

@Component({
  selector: 'app-interactions',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SidebarOptionTitleBandComponent,
    ChatComponent,
    InteractionDetailsComponent,
    PhotosComponent,
    DennoucementMapComponent,
  ],
  templateUrl: './interactions.component.html',
  styleUrl: './interactions.component.scss',
})
export class InteractionsComponent implements OnInit {
  formGroup: FormGroup = new FormGroup({});
  users: Array<any> = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _interactionsService: InteractionsService
  ) {}

  ngOnInit(): void {
    this.initializeFormGroup();
    this.getUsers();
  }

  initializeFormGroup() {
    this.formGroup = this._formBuilder.group({
      userName: ['', []],
    });
  }

  getUsers() {
    this._interactionsService.getUsers().subscribe((users) => {
      this.users = users;
    });
  }
}
