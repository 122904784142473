import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LoadingService } from '../../../services/loading/loading.service';
import { InteractionsService } from '../../../../190rj/services/interactions/interactions.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-interaction-details',
  standalone: true,
  imports: [MatTooltipModule],
  templateUrl: './interaction-details.component.html',
  styleUrl: './interaction-details.component.scss',
})
export class InteractionDetailsComponent implements OnChanges {
  chatDetails: any = {};
  @Input() dennoucementDetails: any;

  constructor(
    private _interactionsService: InteractionsService,
    private loadingService: LoadingService,
  ) { }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['dennoucementDetails'].currentValue) {
      this.loadingService.present();
      this.chatDetails = changes['dennoucementDetails'].currentValue;
      this.chatDetails = this.getHourAndDate(this.chatDetails);
      delete this.chatDetails.dataCadastro;
      this.loadingService.dismiss();
    }
  };

  getInteractionDetails(dennoucementID: number): Promise<any> {
    return new Promise((resolve) => {
      this._interactionsService.getDennoucementsDetails(dennoucementID).subscribe({
        next: (chatDetails) => resolve(chatDetails?.data?.dados),
        error: (err) => resolve([])
      });
    })
  }

  getHourAndDate(dennoucementDetails: any) {
    dennoucementDetails.date = this.getDate(dennoucementDetails.dataCadastro);
    dennoucementDetails.hour = this.getHour(dennoucementDetails.dataCadastro);
    return dennoucementDetails;
  }

  getDate(dateDennoucement: string) {
    const data = new Date(dateDennoucement);
    const day = String(data.getUTCDate()).padStart(2, '0');
    const month = String(data.getUTCMonth() + 1).padStart(2, '0');
    const year = data.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }

  getHour(dateDennoucement: string) {
    const data = new Date(dateDennoucement);
    const hour = String(data.getUTCHours()).padStart(2, '0');
    const minutes = String(data.getUTCMinutes()).padStart(2, '0');
    return `${hour}:${minutes}`;
  }
}
