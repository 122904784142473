import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { DynamicButtonComponent } from '../../../shared/components/dynamic-button/dynamic-button.component';
import { SidebarOptionTitleBandComponent } from '../../../shared/components/title-band/sidebar-option-title-band.component';
import { ButtonConfig } from '../../../shared/components/dynamic-button/dynamic-button.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../../../shared/services/loading/loading.service';
import { UsersService } from '../../services/users-page/users.service';
import { emailValidator, getPortalTypeFromLink, getTypeUser, validateCPF } from '../../../shared/utils';
import { SIDEBAR_OPTIONS_URLS } from '../../../shared/enums/sidebar-option-urls.enum';
import { TYPE_USERS } from '../../../shared/enums/type-users';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { PORTAL_TYPE_URLS } from '../../../shared/enums/portal-types-urls.enum';
import { Observable } from 'rxjs';
import { RedeMulherService } from '../../../shared/services/rede-mulher/rede-mulher.service';

@Component({
  selector: 'app-edit-user-page190rj',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SidebarOptionTitleBandComponent, DynamicButtonComponent, NgxMaskDirective],
  providers: [
    provideNgxMask(),
  ],
  templateUrl: './edit-user-page.component.html',
  styleUrl: './edit-user-page.component.scss'
})
export class EditUserPageComponent implements OnInit {
  formGroup: FormGroup = new FormGroup({});
  configButtonBack: ButtonConfig = { label: 'Voltar', imgSrc: "assets/imgs/back_arrow_ic.svg", handler: () => this.backPage() };
  show: boolean = false;
  userID: string | null = '';
  currentPortalURL: string;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private rj190UsersService: UsersService,
    private redeMulherUsersService: RedeMulherService,
    private loadingService: LoadingService,
    private authService: AuthService,
  ) { }

  backPage() {
    this.router.navigate([`${this.currentPortalURL}/${SIDEBAR_OPTIONS_URLS.USERS}`], { state: { editPage: true } });
  }

  async ngOnInit() {
    this.loadingService.present();
    this.currentPortalURL = getPortalTypeFromLink(this.router.url);
    this.userID = window.history.state.userID;
    let userData: any;
    if (this.userID) userData = await this.getUserData(this.userID);
    else this.openToast('Erro ao buscar dados do usuário', 'error');
    this.initializeFormGroup(userData);
    if (!userData) this.disabledForms();
    this.show = true;
    this.loadingService.dismiss();
  }

  getUserData(userID: string | null): Promise<any[]> {
    let request: Observable<any> =
      this.currentPortalURL == PORTAL_TYPE_URLS.RJ_190 ?
        this.rj190UsersService.getUserAppData(userID) :
        this.redeMulherUsersService.getUserAppData(userID)
    return new Promise(resolve => {
      request.subscribe({
        next: (response) => {
          this.currentPortalURL == PORTAL_TYPE_URLS.RJ_190
            ? resolve(response) :
            resolve(response.data);
        },
        error: () => resolve([])
      })
    })
  }

  initializeFormGroup(user?: any) {
    this.formGroup = this._formBuilder.group({
      name: [user?.NM_Usuario ? user?.NM_Usuario : user?.nome, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])],
      cpf: [user?.NR_Cpf ? user?.NR_Cpf : user?.cpf, Validators.compose([Validators.required, this.cpfValidator])],
      cellphone: [user?.NR_DDD || user?.NR_Telefone ?
        user?.NR_DDD + user?.NR_Telefone : user?.telefone ? user?.telefone : null, Validators.compose([
          Validators.required,
          Validators.minLength(11),
        ])],
      email: [user?.NM_Email ? user?.NM_Email : user?.email, Validators.compose([Validators.required, this.emailValidator])],
    });
  }

  private disabledForms() {
    this.formGroup.controls['name'].disable();
    this.formGroup.controls['cpf'].disable();
    this.formGroup.controls['cellphone'].disable();
    this.formGroup.controls['email'].disable();
  }

  get cpf(): any {
    return this.formGroup.get('cpf');
  }

  get email() {
    return this.formGroup.get('email');
  }

  get cellphone() {
    return this.formGroup.get('cellphone');
  }

  emailValidator(control: AbstractControl) {
    if (control.value && !emailValidator(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  };

  cpfValidator(control: AbstractControl) {
    if (control.value && !validateCPF(control.value)) {
      return { cpfInvalido: true };
    }
    return null;
  }

  verifyValidCpf(): boolean {
    return validateCPF(this.cpf.value);
  }

  saveUser() {
    this.loadingService.present();
    let requestBody = this.currentPortalURL == PORTAL_TYPE_URLS.RJ_190 ?
      {
        "NM_Usuario": this.formGroup.value.name,
        "NR_Cpf": this.formGroup.value.cpf.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
        "NR_DDD": this.formGroup.value.cellphone.normalize("NFD").replace(/[\u0300-\u036f]/g, "").substring(0, 2),
        "NR_Telefone": this.formGroup.value.cellphone.normalize("NFD").replace(/[\u0300-\u036f]/g, "").substring(2),
        "NM_Email": this.formGroup.value.email,
        "IS_Ativo": true
      } :
      {
        "NM_Usuario": this.formGroup.value.name,
        "NR_Cpf": this.formGroup.value.cpf.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
        "NR_Telefone1": this.formGroup.value.cellphone.normalize("NFD").replace(/[\u0300-\u036f]/g, "").substring(2),
        "NM_Email": this.formGroup.value.email,
        "IS_Ativo": true
      }
    let request: Observable<any> =
      this.currentPortalURL == PORTAL_TYPE_URLS.RJ_190 ?
        this.rj190UsersService.editUserAppData(this.userID, requestBody) :
        this.redeMulherUsersService.editUserAppData(this.userID, requestBody)
    request.subscribe({
      next: () => {
        this.loadingService.dismiss();
        this.router.navigateByUrl(`${this.currentPortalURL}/${SIDEBAR_OPTIONS_URLS.USERS}`).then(() => this.openToast("Usuário salvo com sucesso.", 'success'));
      },
      error: (err: any) => {
        if (err?.error?.message) this.openToast(err?.error?.message, 'error');
        else this.openToast('Erro ao editar usuário!', 'error');
        this.loadingService.dismiss();
      },
    });
  }

  openToast(text: string, type: string): void {
    if (type == 'error') {
      this.toastr.error(text, 'Erro!');
    } else {
      this.toastr.success(text, 'Sucesso!');
    }
  }

  userIsAdmin = () => getTypeUser(this.authService.getAccessToken) == TYPE_USERS.ADMIN

}
