import { Component, OnInit } from '@angular/core';
import { ButtonConfig } from '../../../shared/components/dynamic-button/dynamic-button.model';
import { HeaderBoxTableComponent } from '../../../shared/components/header-box-table/header-box-table.component';
import { SidebarOptionTitleBandComponent } from '../../../shared/components/title-band/sidebar-option-title-band.component';
import { DynamicButtonComponent } from '../../../shared/components/dynamic-button/dynamic-button.component';
import { DynamicTableService } from '../../../shared/components/dynamic-table/services/dynamic-table.service';
import { Router } from '@angular/router';
import { SIDEBAR_OPTIONS_URLS } from '../../../shared/enums/sidebar-option-urls.enum';
import { LoadingService } from '../../../shared/services/loading/loading.service';
import { downloadFile, getDateDDMMYY } from '../../../shared/utils';
import { EmergencyService } from '../../../shared/services/190rj/emergency-page/emergency.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-emergency-page190rj',
  standalone: true,
  imports: [HeaderBoxTableComponent, SidebarOptionTitleBandComponent, DynamicButtonComponent],
  templateUrl: './emergency-page190rj.component.html',
  styleUrl: './emergency-page190rj.component.scss'
})
export class EmergencyPage190rjComponent implements OnInit {
  emergencysData: any[];
  configButtonRegister: ButtonConfig = { label: 'Excel', imgSrc: "assets/excel_ic.svg", handler: () => this.exportExcel(), customColor: { backgroundColor: "#00954B" } };
  tableConfig: any = {};
  showTable: boolean = false;
  states: any[] = [];
  types: any[] = [];

  constructor(
    private tableService: DynamicTableService,
    private router: Router,
    private loadingService: LoadingService,
    private emergencyPageRJ190: EmergencyService,
    private toastr: ToastrService
  ) { }

  async ngOnInit() {
    this.loadingService.present();
    this.tableConfig = await this.getData();
    this.types = this.tableConfig.optionsFirstFilter;
    this.states = this.tableConfig.optionsSecondFilter;
    this.tableConfig.currentPage = 1;
    this.emergencysData = this.tableConfig.tableBody;
    this.showTable = true;
    this.loadingService.dismiss();
  }


  public async searchAndFilterItens(event: any) {
    if (event.filterByDateValue || event.filterFirstValue || event.filterSecondValue) {
      this.loadingService.present();
      this.tableConfig = await this.getData(1, event.filterByDateValue, event.filterFirstValue, event.filterSecondValue, true);
      this.tableConfig.currentPage = 1;
      this.tableConfig.optionsFirstFilter = this.types;
      this.tableConfig.optionsSecondFilter = this.states;
      this.loadingService.dismiss();
    }
  }

  public async clearSearchTable() {
    this.loadingService.present();
    this.tableConfig = await this.getData(1, '', '', '', true);
    this.tableConfig.currentPage = 1;
    this.tableConfig.optionsFirstFilter = this.types;
    this.tableConfig.optionsSecondFilter = this.states;
    this.loadingService.dismiss();
  }

  private getData(page?: number, date?: string, type?: string, status?: string, isItSearch?: boolean): Promise<any> {
    return this.tableService.getObjectConfigTable(this.router.url, SIDEBAR_OPTIONS_URLS.EMERGENCY, null, page, type, status, date, '', isItSearch);
  };

  async swapPage(page: number) {
    this.loadingService.present();
    this.tableConfig = await this.getData(page);
    this.tableConfig.currentPage = page;
    this.loadingService.dismiss();
  };

  exportExcel() {
    this.loadingService.present();
    this.emergencyPageRJ190.emergencyExcel().subscribe({
      next: (res) => {
        downloadFile(res, `190RJ_Emergências_${getDateDDMMYY()}`);
      },
      error: () => this.toastr.error('Erro ao baixar arquivo Excel!', 'Erro!')
    })
    this.loadingService.dismiss();
  };
}
