import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DynamicTableComponent } from '../dynamic-table/dynamic-table.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderTableSettings } from './header-box-table.model';
import { DynamicButtonComponent } from '../dynamic-button/dynamic-button.component';
import { ButtonConfig } from '../dynamic-button/dynamic-button.model';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { Router } from '@angular/router';
import { TableSettings } from '../dynamic-table/models/dynamic-table.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header-box-table',
  standalone: true,
  imports: [CommonModule, DynamicTableComponent, FormsModule, DynamicButtonComponent],
  templateUrl: './header-box-table.component.html',
  styleUrl: './header-box-table.component.scss'
})
export class HeaderBoxTableComponent implements OnInit {
  @Input() headerBoxTableSettings: HeaderTableSettings;
  @Output() public searchClick = new EventEmitter<any>();
  @Output() public clearSearchClick = new EventEmitter<any>();
  @Output() public excelButtonClick = new EventEmitter<any>();
  @Output() public swapPage = new EventEmitter<any>();
  @ViewChild(DynamicTableComponent) childView!: DynamicTableComponent;
  tableSettings: any;
  searchTextValue: string;
  filterFirstValue: string | null;
  filterSecondValue: string | null;
  filterByDateValue: string | null;
  secondFilterByDateValue: any;
  showButtonClearSearch: boolean = false;
  configButtonSearch: ButtonConfig = { label: 'Pesquisar', handler: () => this.searchInComponent() };
  configButtonClearSearch: ButtonConfig = { label: 'Limpar', handler: () => this.clearCick(), customColor: { backgroundColor: "#f7a01d" } };
  urlActive: string;
  portalTypes = PORTAL_TYPE_URLS;
  title: any;
  @ViewChild('dateInput', { static: false }) dateInput: ElementRef;

  constructor(private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.urlActive = this.router.url;
  };


  ngOnChanges(changes: SimpleChanges) {
    if (changes['headerBoxTableSettings'].currentValue) {
      if (changes['headerBoxTableSettings'].currentValue.headerTitle != this.title) this.clearSearch();
      this.tableSettings = this.buildTableConfig(changes['headerBoxTableSettings'].currentValue);
      this.verifyOptionSelected(changes['headerBoxTableSettings'].currentValue);
      this.title = changes['headerBoxTableSettings'].currentValue.headerTitle;
    }
  };

  buildTableConfig(table: any) {
    const arrayUnderConstruction = {} as TableSettings;
    arrayUnderConstruction.titlesTables = table.titlesTables;
    arrayUnderConstruction.tableBody = table.tableBody;
    arrayUnderConstruction.itensPerPage = table.itensPerPage;
    table.totalItens ? arrayUnderConstruction.totalItens = table.totalItens : arrayUnderConstruction.totalItens;
    table.currentPage ? arrayUnderConstruction.currentPage = table.currentPage : arrayUnderConstruction.currentPage;
    arrayUnderConstruction.showButton = table.showButton ? table.showButton : false;
    arrayUnderConstruction.buttonEndTable = table.buttonTable;
    return arrayUnderConstruction;
  };

  verifyOptionSelected(config: any) {
    if (config?.optionsFirstFilter && !config.optionsFirstFilter[0]?.default && !this.filterFirstValue) {
      this.filterFirstValue = config.optionsFirstFilter[0].value;
    }
    if (config?.optionsSecondFilter && !config.optionsSecondFilter[0]?.default && !this.filterSecondValue) {
      this.filterSecondValue = config.optionsSecondFilter[0].value;
    }
  }

  public searchInComponent() {
    if (this.isValidText(this.searchTextValue) ||
      this.isValidText(this.filterFirstValue) ||
      this.isValidText(this.filterSecondValue) ||
      this.isValidText(this.filterByDateValue) ||
      this.isValidText(this.secondFilterByDateValue)) {
      this.showButtonClearSearch = true;
      return this.searchClick.emit({
        searchValue: this.searchTextValue ? this.searchTextValue.toLowerCase() : null,
        filterFirstValue: this.filterFirstValue ? this.filterFirstValue.toLowerCase() : null,
        filterSecondValue: this.filterSecondValue ? this.filterSecondValue.toLowerCase() : null,
        filterByDateValue: this.filterByDateValue ? this.filterByDateValue.toLowerCase() : null,
        secondFilterByDateValue: this.secondFilterByDateValue ? this.secondFilterByDateValue.toLowerCase() : null,
      });
    };
  }

  public clearCick() {
    this.clearSearch();
    this.clearSearchClick.emit();
  };

  public changePage(page: number) {
    this.swapPage.emit(page);
  }

  clearSearch() {
    this.showButtonClearSearch = false;
    this.searchTextValue = "";
    this.filterFirstValue = null;
    this.filterSecondValue = null;
    this.filterByDateValue = null;
    this.secondFilterByDateValue = null;
  }

  public isValidText(text: string | null): boolean {
    if (text != "" && text != null && text != undefined) return true;
    return false;
  };

  openToastError(text: string): void {
    this.toastr.error(text, 'Erro!');
  }
}
