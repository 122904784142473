export class UserAnalysisDataset {
  constructor(
    data: Array<number>,
    backgroundColor: Array<string> = [],
    rotation: number = 0,
    tension: number = 4
  ) {
    this.data = data;
    this.backgroundColor = backgroundColor;
    this.rotation = rotation;
    this.tension = tension;
  }

  data: Array<number>;
  backgroundColor: Array<string>;
  rotation: number;
  tension: number;
}
