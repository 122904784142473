import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import * as toastStrings from './toastTexts.json';
import * as toastTitlesOrTypes from '../../../../assets/constants/toastTitlesOrTypes.json';


@Component({
  selector: 'app-modal-forgot-pass',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './modal-forgot-pass.component.html',
  styleUrl: './modal-forgot-pass.component.scss'
})
export class ModalForgotPassComponent implements OnInit {
  forgotPassForm: FormGroup = new FormGroup([]);
  isLoading: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<ModalForgotPassComponent>,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  close(): void {
    this.dialogRef.close();
  }

  initializeFormGroup() {
    this.forgotPassForm = this._formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  get email() {
    return this.forgotPassForm.get('email');
  }

  sendEmail() {
    this.isLoading = true;
    this.authService.forgotPasseEmail(this.forgotPassForm.value.email).subscribe({
      next: () => {
        this.toastr.success(toastStrings.success_send_email, toastTitlesOrTypes.successs_title);
        this.isLoading = false;
        this.close();
      },
      error: (err) => {
        this.toastr.error(toastStrings.erro_send_email, toastTitlesOrTypes.error_title);
        this.isLoading = false;
      },
    })
  }
}
