export class BarDataset {
  constructor(
    data: Array<number>,
    backgroundColor: Array<string>,
    labels: Array<string>,
    borderRadius: number = 20,
    barThickness: number = 20,
  ) {
    this.data = data;
    this.backgroundColor = backgroundColor;
    this.labels = labels;
    this.borderRadius = borderRadius;
    this.barThickness = barThickness;
  }

  data: Array<number>;
  backgroundColor: Array<string>;
  labels: Array<string>;
  borderRadius: number = 20;
  barThickness: number = 20;
}
