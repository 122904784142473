import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PORTAL_TYPES } from '../enums/portal-types.enum';
import { PORTAL_TYPES_NAMES } from '../enums/portal-types-names.enum';
import { PORTAL_INFO } from '../models/portal-info';

@Injectable({
  providedIn: 'root',
})
export class PortalTypeService {
  currentPortalType$: BehaviorSubject<PORTAL_INFO> =
    new BehaviorSubject<PORTAL_INFO>({
      type: PORTAL_TYPES.RJ_190,
      name: PORTAL_TYPES_NAMES.RJ_190,
    });
  private defaultSidebarOption: PORTAL_INFO = {
    type: PORTAL_TYPES.RJ_190,
    name: PORTAL_TYPES_NAMES.RJ_190,
  };

  constructor() { }

  updatePortalType(portal: PORTAL_TYPES) {
    switch (portal) {
      case PORTAL_TYPES.RJ_190:
        this.currentPortalType$.next({
          type: PORTAL_TYPES.RJ_190,
          name: PORTAL_TYPES_NAMES.RJ_190,
        });
        break;
      case PORTAL_TYPES.REDE_MULHER:
        this.currentPortalType$.next({
          type: PORTAL_TYPES.REDE_MULHER,
          name: PORTAL_TYPES_NAMES.REDE_MULHER,
        });
        break;
      case PORTAL_TYPES.REDE_ESCOLA:
        this.currentPortalType$.next({
          type: PORTAL_TYPES.REDE_ESCOLA,
          name: PORTAL_TYPES_NAMES.REDE_ESCOLA,
        });
        break;
    }
  }

  resetPortalOption() {
    this.currentPortalType$.next(this.defaultSidebarOption);
  }
}
