import { Injectable } from '@angular/core';
import { PORTAL_TYPE_URLS } from '../../../enums/portal-types-urls.enum';
import { SIDEBAR_OPTIONS_URLS } from '../../../enums/sidebar-option-urls.enum';
import { Observable, firstValueFrom, of } from 'rxjs';
import { HeaderTableSettings } from '../../header-box-table/header-box-table.model';
import { PORTAL_FILTER_CARD_TITLES } from '../../filter-card/enums/filter-card-titles.enum';
import { MockDataTable } from './dynamic-table.mocks';
import { RedeMulherService } from '../../../services/rede-mulher/rede-mulher.service';
import { formatResponseTableEmergency, formatResponseTableReports, transformDateUStoBR } from '../../../utils';
import { GeneralPanelService } from '../../../services/190rj/painel-geral-page/painel-geral.service';
import { EmergencyService } from '../../../services/190rj/emergency-page/emergency.service';
import { UsersService } from '../../../services/190rj/users-page/users.service';
import { ReportsService } from '../../../services/190rj/reports-page/reports.service';
@Injectable({
  providedIn: 'root'
})
export class DynamicTableService {

  constructor(
    private generalPanelRJ190: GeneralPanelService,
    private emergencyPageRJ190: EmergencyService,
    private usersPageRJ190: UsersService,
    private redeMulherService: RedeMulherService,
    private reportsServiceRJ190: ReportsService
  ) { }


  public async getObjectConfigTable(portal: string, page: string, card?: any, pageNumber?: number, type?: string, status?: string, date?: string, year?: string, isItSearch?: boolean) {
    if (portal.includes(PORTAL_TYPE_URLS.RJ_190)) return await this.getSettingsTableRj190(page, card, pageNumber, type, status, date, year, isItSearch);
    else if (portal.includes(PORTAL_TYPE_URLS.REDE_MULHER)) return await this.getSettingsTableMulher(page, card);
    else if (portal.includes(PORTAL_TYPE_URLS.REDE_ESCOLA)) return this.getSettingsTableEscola(page, card);
    return {};
  }

  public async getSettingsTableRj190(page: string, clickedCard: any, pageNumber?: number, type?: string, status?: string, date?: string, year?: string, isItSearch?: boolean) {
    let config: HeaderTableSettings = {};
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      let response = await this.getTableRj190(page, clickedCard, 5, pageNumber, undefined, undefined, undefined, year);
      if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 5;
        config.totalItens = response?.total;
        config.showTextBox = false;
        config.showFirstFilterBox = true;
        config.showExcelButton = true;
        config.showClearButton = false;
        config.optionsFirstFilter = [];
        config.titlesTables = [
          'Tipo',
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
          'Total'];
        config.showButton = false;
        config.tableBody = response?.data ? response?.data : [];
      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 5;
        config.totalItens = response.total;
        config.showTextBox = false;
        config.showFirstFilterBox = true;
        config.showExcelButton = true;
        config.showClearButton = false;
        config.optionsFirstFilter = [];
        config.titlesTables = [
          'Tipo',
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
          'Total'];
        config.showButton = false;
        config.tableBody = response?.data ? response.data : [];
      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.RJ_190_MISSING) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 5;
        config.showTextBox = false;
        config.showFirstFilterBox = true;
        config.showExcelButton = true;
        config.showClearButton = false;
        config.optionsFirstFilter = [];
        config.titlesTables = [
          'Tipo',
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
          'Total'];
        config.showButton = false;
        config.tableBody = response ? response : [];;
      };

      let currentYear: any = new Date().getFullYear();
      let dates = [
        currentYear,
        currentYear - 1,
        currentYear - 2,
        currentYear - 3,
        currentYear - 4,
        currentYear - 5,
      ];
      dates.forEach((year: number, index: any) => {
        config?.optionsFirstFilter?.push({ name: `${year}`, value: `${year}` });
      });
    } else if (page == SIDEBAR_OPTIONS_URLS.USERS) {
      config.optionsFirstFilter = [{ name: "Perfil", value: "", default: true }];
      if (!isItSearch) {
        let profilesFilter: any = await this.getProfilesRJ190();
        profilesFilter.data.forEach((profile: any) => {
          config.optionsFirstFilter?.push({ name: profile.NM_Perfil, value: profile.ID_Perfil });
        });
      }
      let responseUsers = await this.getTableRj190(page, clickedCard, 10, pageNumber, type, status);
      let newArray: any[] = [];
      responseUsers.data.forEach((user: any) => {
        let newObject = {
          name: user.nome,
          cpf: user.cpf,
          phone: user.telefone,
          email: user.email,
          organID: user.orgaoId,
          profileID: user.perfilId
        };
        newArray.push(newObject);
      });
      config.totalItens = responseUsers.total;
      config.headerTitle = "Usuários";
      config.titleSearch = "Pesquisar por nome";
      config.titlesTables = ['Nome', 'CPF', 'Telefone', 'E-mail', 'Órgão', 'Perfil'];
      config.itensPerPage = 10;
      config.showTextBox = true;
      config.titleSearch = 'Pesquise por nome';
      config.showFirstFilterBox = true;
      config.showClearButton = true;
      config.tableBody = newArray;
    } else if (page == SIDEBAR_OPTIONS_URLS.EMERGENCY) {
      config.optionsFirstFilter = [{ name: "Tipo", value: "", default: true }];
      config.optionsSecondFilter = [{ name: "Status", value: "", default: true }];
      let responseEmergency = await this.getTableRj190(page, clickedCard, 10, pageNumber, type, status, date);
      if (responseEmergency.data.length > 0) {
        responseEmergency.data = formatResponseTableEmergency(responseEmergency.data);
      }
      if (!isItSearch) {
        let typesFilter: any = await this.getTypesEmergencyRj190();
        let statusFilter: any = await this.getStatusEmergencyRj190();
        if (typesFilter.data.length > 0) {
          typesFilter.data.forEach((type: any) => {
            config.optionsFirstFilter?.push({ name: type.NM_TipoChamado, value: type.ID_TipoChamado });
          });
        }
        if (statusFilter.data.length > 0) {
          statusFilter.data.forEach((stat: any) => {
            config.optionsSecondFilter?.push({ name: stat.NM_Status, value: stat.ID_Status });
          });
        }
      }
      config.headerTitle = "Emergências";
      config.itensPerPage = 10;
      config.totalItens = responseEmergency.total;
      config.showTextBox = false;
      config.showFirstFilterBox = true;
      config.showSecondFilterBox = true;
      config.showFilterByDate = true;
      config.showClearButton = true;
      config.titlesTables = ['Data', 'Hora', 'Tipo', 'Mike 190', 'Status', 'Satisfação'];
      config.tableBody = responseEmergency.data;
    } else if (page == SIDEBAR_OPTIONS_URLS.REPORTED) {
      let responseReported = await this.getTableRj190(page, clickedCard, 10, pageNumber, type);
      if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.RJ_190_REPORTS) {
        if (responseReported?.data?.length > 0) {
          responseReported.data = formatResponseTableReports(responseReported.data);
        }
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 10;
        config.totalItens = responseReported.total;
        config.showTextBox = true;
        config.titleSearch = 'Pesquise por usuário ou palavra';
        config.showFirstFilterBox = false;
        config.showSecondFilterBox = false;
        config.showClearButton = true;
        config.titlesTables = ['Autor',
          'Data',
          'Horário',
          'Quantidade',
          'Resumo do Post',
          'Status',
          'Ver Publicação',];
        config.showButton = true;
        config.buttonTable = { label: 'Visualizar' };
        config.tableBody = responseReported.data;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.RJ_190_OPEN_STATUS) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquise por usuário ou palavra';
        config.showFirstFilterBox = false;
        config.showSecondFilterBox = false;
        config.showClearButton = true;
        config.titlesTables = [
          'Autor',
          'Data',
          'Horário',
          'Quantidade',
          'Resumo do Post',
          'Ver Publicação',
        ];
        config.showButton = true;
        config.buttonTable = { label: 'Visualizar' };
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.RJ_190_BLOCKED) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquise por usuário ou palavra';
        config.showFirstFilterBox = false;
        config.showSecondFilterBox = false;
        config.showClearButton = true;
        config.titlesTables = [
          'Autor',
          'CPF',
          'Telefone',
          'E-mail',
          'Data de Bloqueio',
          'Ver Publicação',
        ];
        config.showButton = true;
        config.buttonTable = { label: 'Visualizar' };
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.RJ_190_CLOSED_STATUS) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquise por usuário ou palavra';
        config.showFirstFilterBox = false;
        config.showSecondFilterBox = false;
        config.showClearButton = true;
        config.titlesTables = [
          'Autor',
          'CPF',
          'Telefone',
          'E-mail',
          'Data de Bloqueio',
          'Ver Publicação',
        ];
        config.showButton = true;
        config.buttonTable = { label: 'Visualizar' };
        config.tableBody = responseReported;
      };
    };
    return config;
  }

  public async getSettingsTableMulher(page: string, clickedCard: any) {
    let config: HeaderTableSettings = {};
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      let responseReported = await this.getTableMulher(page, clickedCard);
      if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS) {
        config.headerTitle = 'Todos os ' + clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquise por nome';
        config.showFirstFilterBox = true;
        config.optionsFirstFilter = [
          { name: 'Status', value: '', default: true },
          { name: 'Ativo', value: 'true' },
          { name: 'Inativo', value: 'false' },
        ];
        config.titlesTables = [
          'Nome',
          'CPF',
          'Telefone',
          'E-mail',
          'Status',
          'Ver Perfil',
        ];
        config.showButton = true;
        config.buttonTable = { label: 'Visualizar' };
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 5;
        config.showTextBox = false;
        config.showFirstFilterBox = true;
        config.optionsFirstFilter = [
          { name: 'Tipo', value: '', default: true },
        ];
        responseReported.forEach((element: any) => {
          let object = { name: element.type, value: element.type };
          if (!config.optionsFirstFilter?.includes(object)) config.optionsFirstFilter?.push(object);
        });
        config.titlesTables = [
          'Tipo',
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
          'Total'];
        config.showButton = false;
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_REPORTED) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquisar por nome ou palavra';
        config.showFirstFilterBox = false;
        config.showSecondFilterBox = false;
        config.titlesTables = [
          'Autor',
          'Data',
          'Horário',
          'Qt. de Reports',
          'Resumo do Post',
          'Ver Perfil',
        ];
        config.showButton = true;
        config.buttonTable = { label: 'Visualizar' };
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR) {
        config.headerTitle = 'Todos os' + ' ' + clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquisar por nome do agressor';
        config.showFirstFilterBox = false;
        config.showSecondFilterBox = false;
        config.titlesTables = [
          'Agressor',
          'CPF',
          'Medida Protetiva',
          'Data do Vencimento',
          'Vítima',
        ];
        config.showButton = false;
        config.tableBody = responseReported;
      };
    } else if (page == SIDEBAR_OPTIONS_URLS.USERS) {
      let responseUsers = await this.getTableMulher(page);
      config.headerTitle = "Usuários";
      config.titleSearch = "Pesquisar por nome";
      config.titlesTables = ['Nome', 'CPF', 'Telefone', 'E-mail', 'Órgão', 'Perfil'];
      config.itensPerPage = 10;
      config.showTextBox = false;
      config.showFirstFilterBox = true;
      config.optionsFirstFilter = [
        { name: "Perfil", value: "", default: true },
        { name: "Administrador", value: "admin" },
        { name: "Suporte", value: "sup" },
        { name: "Observador", value: "obs" },
      ];
      config.showButton = false;
      config.tableBody = responseUsers;
    } else if (page == SIDEBAR_OPTIONS_URLS.EMERGENCY) {
      let responseUsers = await this.getTableMulher(page);
      config.headerTitle = "Emergências";
      config.titleSearch = "Pesquisar por nome";
      config.titlesTables = ['Protocolo', 'Data', 'Hora', 'Tipo', 'Vítima', 'Status', 'Classificação'];
      config.itensPerPage = 10;
      config.showTextBox = false;
      config.showFirstFilterBox = true;
      config.optionsFirstFilter = [
        { name: "Tipo", value: "", default: true },
        { name: "Violência Sexual", value: "Violência Sexual" },
        { name: "Violência Verbal", value: "Violência Verbal" },
        { name: "Violência Psicologica", value: "Violência Psicologica" },
      ];
      config.showButton = false;
      config.tableBody = responseUsers;
    };
    return config;
  }

  public getSettingsTableEscola(page: string, clickedCard: any): Observable<any> {
    let config: HeaderTableSettings = {};
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      this.getTableEscola(page, clickedCard).subscribe({
        next: (responseReported) => {
          if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_SCHOOLS) {
            config.headerTitle = clickedCard.title;
            config.itensPerPage = 10;
            config.showTextBox = true;
            config.titleSearch = 'Pesquise por nome';
            config.showFirstFilterBox = true;
            config.optionsFirstFilter = [
              { name: 'Tipo', value: '', default: true },
              { name: 'Pública', value: 'Pública' },
              { name: 'Municipal', value: 'Municipal' },
              { name: 'Estadual', value: 'Estadual' },
              { name: 'Federal', value: 'Federal' },
              { name: 'Privada', value: 'Privada' },
            ];
            config.titlesTables = [
              'Nome da escola',
              'Tipo',
              'Endereço',
              'Telefone',
              'E-mail',
            ];
            config.showButton = false;
            config.tableBody = responseReported;

          } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS) {
            config.headerTitle = clickedCard.title;
            config.itensPerPage = 4;
            config.showTextBox = false;
            config.showFirstFilterBox = true;
            config.showExcelButton = true;
            config.optionsFirstFilter = [
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
            ];
            config.titlesTables = [
              'Nome da escola',
              'Nome do usuário',
              'Telefone',
              'Data da denúncia',
              'Ver denúncia'
            ];
            config.showButton = true;
            config.buttonTable = { label: 'Visualizar' };
            config.tableBody = responseReported;

          } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_EMERGENCIES) {
            config.headerTitle = clickedCard.title;
            config.itensPerPage = 5;
            config.showTextBox = false;
            config.showFirstFilterBox = true;
            config.showExcelButton = true;
            config.optionsFirstFilter = [
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
            ];
            config.titlesTables = [
              'Tipo',
              'Jan',
              'Fev',
              'Mar',
              'Abr',
              'Mai',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez',
              'Total'];
            config.showButton = false;
            config.tableBody = responseReported;

          };
        },
        error: () => {
          config = {};
        },
      });
    } else if (SIDEBAR_OPTIONS_URLS.EMERGENCY) {
    };
    return of(config);
  }

  private async getTableRj190(page: string, cardOption?: any, limit?: number, pageNumber?: number, type?: string, status?: string, date?: string, year?: string) {
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      let response: any;
      if (cardOption.title == PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES) {
        await firstValueFrom(this.generalPanelRJ190.generalPanelEmergencyTable(limit, pageNumber, year)).then((resService) => response = resService).catch(() => response = []);
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS) {
        await firstValueFrom(this.generalPanelRJ190.denouncementTable(limit, pageNumber, year)).then((resService) => response = resService).catch(() => response = []);
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.RJ_190_MISSING) {
        await firstValueFrom(this.generalPanelRJ190.missingTable(year)).then((resService) => response = resService).catch(() => response = []);
      };
      if (response) {
        let newResponse: any = [];
        let arrayForeach = response?.data ? response.data : response;
        arrayForeach.forEach((element: any) => {
          let newObject = {
            type: element?.tipoChamado ? element.tipoChamado : element.tipoDenuncia ? element.tipoDenuncia : element.tipo,
            jan: element.Jan ? element.Jan : 0,
            fev: element.Fev ? element.Fev : 0,
            mar: element.Mar ? element.Mar : 0,
            abr: element.Abr ? element.Abr : 0,
            mai: element.Mai ? element.Mai : 0,
            jun: element.Jun ? element.Jun : 0,
            jul: element.Jul ? element.Jul : 0,
            ago: element.Ago ? element.Ago : 0,
            set: element.Set ? element.Set : 0,
            out: element.Out ? element.Out : 0,
            nov: element.Nov ? element.Nov : 0,
            dez: element.Dez ? element.Dez : 0,
            total: element.Jan +
              element.Fev +
              element.Mar +
              element.Abr +
              element.Mai +
              element.Jun +
              element.Jul +
              element.Ago +
              element.Set +
              element.Out +
              element.Nov +
              element.Dez
          };
          newResponse.push(newObject);
        });
        response?.data ? response.data = newResponse : response = newResponse;
        return response;
      } else return {};
    } else if (page == SIDEBAR_OPTIONS_URLS.USERS) {
      let response: any;
      await firstValueFrom(this.usersPageRJ190.getUsers(limit, pageNumber, type, status)).then((resService) => response = resService).catch(() => response = []);
      return response;
    } else if (page == SIDEBAR_OPTIONS_URLS.EMERGENCY) {
      let response: any;
      await firstValueFrom(this.emergencyPageRJ190.emergencyPageTable(limit, pageNumber, type, status, date)).then((resService) => response = resService).catch(() => response = []);
      return response;
    } else if (page == SIDEBAR_OPTIONS_URLS.REPORTED) {
      if (cardOption.title == PORTAL_FILTER_CARD_TITLES.RJ_190_REPORTS) {
        let response: any;
        await firstValueFrom(this.reportsServiceRJ190.getReportsTable(limit, pageNumber, type)).then((resService) => response = resService).catch(() => response = []);
        return response;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.RJ_190_OPEN_STATUS) {
        return [];
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.RJ_190_BLOCKED) {
        return [];
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.RJ_190_CLOSED_STATUS) {
        return [];
      };
    };

    return {};
  }

  private getTableEscola(page: string, cardOption?: any): Observable<any> {
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      let response: any;
      if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_SCHOOLS) {
        response = MockDataTable.REDE_ESCOLA.GENERAL_PANEL.SCHOOLS;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS) {
        response = MockDataTable.REDE_ESCOLA.GENERAL_PANEL.DENOUNCEMENTS;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_EMERGENCIES) {
        response = MockDataTable.REDE_ESCOLA.GENERAL_PANEL.EMERGENCIES;
        response.forEach((element: any) => {
          element.total =
            element.jan +
            element.fev +
            element.mar +
            element.abr +
            element.mai +
            element.jun +
            element.jul +
            element.ago +
            element.set +
            element.out +
            element.nov +
            element.dez;
        });
      };
      return of(response);
    }
    return of({});
  }

  private async getTableMulher(page: string, cardOption?: any) {
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      let response: any;
      if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS) {
        await firstValueFrom(this.redeMulherService.usersTable()).then((resService) => {
          response = resService.Results;
          response.forEach((element: any) => {
            element.Nome = element.Nome ? element.Nome : 'Sem nome';
            element.CPF = element.CPF ? element.CPF : 'Sem CPF';
            element.Email = element.Email ? element.Email : 'Sem email';
            element.Telefone = element.Telefone ? element.Telefone : 'Sem telefone';
            element.Status = element.Status ? 'Ativo' : 'Inativo';
          });
        }).catch((err) => {
          response = [];
          console.log(err)
        });
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES) {
        await firstValueFrom(this.redeMulherService.emengercyTable()).then((resService) => {
          response = resService.Results;
        }).catch((err) => {
          response = [];
          console.log(err)
        });
        if (response) {
          let newResponse: any = [];
          response.forEach((element: any) => {
            let newObject = {
              type: element?.NM_TipoOcorrencia ? element?.NM_TipoOcorrencia : element?.NM_Tipo,
              jan: element[1] ? element[1] : 0,
              fev: element[2] ? element[2] : 0,
              mar: element[3] ? element[3] : 0,
              abr: element[4] ? element[4] : 0,
              mai: element[5] ? element[5] : 0,
              jun: element[6] ? element[6] : 0,
              jul: element[7] ? element[7] : 0,
              ago: element[8] ? element[8] : 0,
              set: element[9] ? element[9] : 0,
              out: element[10] ? element[10] : 0,
              nov: element[11] ? element[11] : 0,
              dez: element[12] ? element[12] : 0,
              total: element[1] +
                element[2] +
                element[3] +
                element[4] +
                element[5] +
                element[6] +
                element[7] +
                element[8] +
                element[9] +
                element[10] +
                element[11] +
                element[12]
            };
            newResponse.push(newObject);
          });
          response = newResponse;
        };
        // response = MockDataTable.REDE_MULHER.GENERAL_PANEL.EMERGENCIES;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_REPORTED) {
        await firstValueFrom(this.redeMulherService.reportedTable()).then((resService) => {
          response = resService.Results;
        }).catch((err) => {
          response = [];
          console.log(err)
        });
        response = MockDataTable.REDE_MULHER.GENERAL_PANEL.REPORTED;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR) {
        await firstValueFrom(this.redeMulherService.agressorTable()).then((resService) => {
          response = resService.Results;
        }).catch((err) => {
          response = [];
          console.log(err)
        });
      };
      return response;
    } else if (page == SIDEBAR_OPTIONS_URLS.USERS) {
      return MockDataTable.REDE_MULHER.USERS
    } else if (page == SIDEBAR_OPTIONS_URLS.EMERGENCY) {
      return MockDataTable.REDE_MULHER.EMERGENCY
    }

    return of({});
  };


  private async getTypesEmergencyRj190() {
    let response;
    await firstValueFrom(this.emergencyPageRJ190.emergencyGetTypes()).then((resService) => response = resService).catch(() => response = []);
    return response;
  }

  private async getStatusEmergencyRj190() {
    let response;
    await firstValueFrom(this.emergencyPageRJ190.emergencyGetStatus()).then((resService) => response = resService).catch(() => response = []);
    return response;
  }

  private async getProfilesRJ190() {
    let response;
    await firstValueFrom(this.usersPageRJ190.getProfiles()).then((resService) => response = resService).catch(() => response = []);
    return response;
  }

}
