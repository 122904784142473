import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarOnlyShowIconsService {

  private onlyShowIcons$ = new BehaviorSubject<any>(false);
  only$ = this.onlyShowIcons$.asObservable();


  constructor() { }

  setShow(show: boolean) {
    this.onlyShowIcons$.next(show);
  }
}
