import { HttpInterceptorFn } from '@angular/common/http';
import { catchError, switchMap, throwError, timeout } from 'rxjs';
import { isTokenExpired } from '../../utils';
import { AuthService } from '../../services/auth/auth.service';
import { inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
const TIMEOUT: number = 7000;
export const globalInterceptor: HttpInterceptorFn = (req, next) => {
  if (!req.url.includes(environment.baseEndpointRefreshToken)) {
    const authService = inject(AuthService);
    if (authService.getAccessToken && !isTokenExpired(authService.getAccessToken)) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authService.getAccessToken}`)
      });
      return next(authReq);
    }
    else if (authService.getAccessToken && isTokenExpired(authService.getAccessToken)) {
      return authService.refreshTokenRequest().pipe(
        switchMap((res) => {
          const refreshedAuthReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${res}`)
          });
          return next(refreshedAuthReq);
        }),
        catchError(err => {
          console.error('Falha ao realizar refresh token:', err);
          authService.logout();
          return throwError(() => err);
        })
      );
    } else return next(req);
  } else return next(req);
};
