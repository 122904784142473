import { Component } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { AuthService } from './shared/services/auth/auth.service';
import { isTokenExpired } from './shared/utils';

registerLocaleData(localePt);

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterModule, CommonModule, LoadingComponent],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    if (this.authService.getAccessToken && isTokenExpired(this.authService.getAccessToken)) this.authService.logout();
    else {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.authService.getAccessToken && isTokenExpired(this.authService.getAccessToken)) {
            this.authService.refreshTokenRequest().subscribe({
              error: () => {
                console.error('Falha ao renovar o token. Usuário será deslogado.');
                this.authService.logout();
              },
            });
          }
        }
      });
    }
  }
}
