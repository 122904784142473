import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { PORTAL_FILTER_CARD_TITLES } from '../../../shared/components/filter-card/enums/filter-card-titles.enum';
import { FilterCardComponent } from '../../../shared/components/filter-card/filter-card.component';
import { FilterCardsService } from '../../../shared/components/filter-card/services/filter-cards.service';
import { HeaderBoxTableComponent } from '../../../shared/components/header-box-table/header-box-table.component';
import { LineChartComponent } from '../../../shared/components/line-chart/line-chart.component';
import { SingleDoughnutChartComponent } from '../../../shared/components/single-doughnut-card/single-doughnut-card.component';
import { SidebarOptionTitleBandComponent } from '../../../shared/components/title-band/sidebar-option-title-band.component';
import { TopFiveComponent } from '../../../shared/components/top-five/top-five.component';
import { PortalFilterCard } from '../../../shared/models/portal-filter-card';
import { SIDEBAR_OPTIONS_URLS } from '../../../shared/enums/sidebar-option-urls.enum';
import { DynamicTableService } from '../../../shared/components/dynamic-table/services/dynamic-table.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EXTRAS_PAGES_URLS } from '../../../shared/enums/extras-pages-urls.enum';
import { LoadingService } from '../../../shared/services/loading/loading.service';
import { RedeMulherService } from '../../../shared/services/rede-mulher/rede-mulher.service';
import { BarChartComponent } from '../../../shared/components/bar-chart/bar-chart.component';

@Component({
  selector: 'app-rede-mulher-general-panel',
  standalone: true,
  imports: [
    CommonModule,
    SidebarOptionTitleBandComponent,
    FilterCardComponent,
    SingleDoughnutChartComponent,
    LineChartComponent,
    HeaderBoxTableComponent,
    TopFiveComponent,
    BarChartComponent
  ],
  templateUrl: './general-panel.component.html',
  styleUrl: './general-panel.component.scss',
})
export class RedeMulherGeneralPanelComponent implements OnInit, OnDestroy {
  portalCards: Array<PortalFilterCard> = [];
  portalCardsSubscription: Subscription | null = null;
  myEventSubscribeCurrentPortal: Subscription | null = null;
  selectedPortalFilterCard: PortalFilterCard | null = null;
  PORTAL_FILTER_CARD_TITLES = PORTAL_FILTER_CARD_TITLES;
  painelData: any[];
  tableConfig: any = {};
  portalCardsTitle = PORTAL_FILTER_CARD_TITLES;
  topFive: Array<any> = [];
  topFiveData: any = {};

  constructor(
    private _portalFilterCardsService: FilterCardsService,
    private _tableService: DynamicTableService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private redeMulherService: RedeMulherService
  ) { }

  async ngOnInit() {
    this.loadingService.present();
    this.listenToFilterCardsChanges();
    await this.listenToChangesOnSelectedPortalFilterCard();
    await this.getConfigTable();
    await this.getNumericDatas();
    this.loadingService.dismiss();
  };

  ngOnDestroy(): void {
    if (this.portalCardsSubscription) this.portalCardsSubscription?.unsubscribe();
    if (this.myEventSubscribeCurrentPortal) this.myEventSubscribeCurrentPortal?.unsubscribe();
  }

  getNumericDatas(): Promise<boolean> {
    return new Promise((resolve) => {
      this.redeMulherService.numericData().subscribe({
        next: (response) => {
          this.portalCards.forEach(card => {
            if (card.title == 'Usuários') card.value = response.Results.usuarios;
            if (card.title == 'Emergências') card.value = response.Results.ocorrencias;
            // if (card.title == 'Reportados') card.value = response.Results.reportados;
            if (card.title == 'Agressores') card.value = response.Results.agressores;
          });
        },
        error: (err) => {
          console.log(err);
        },
      }).add(() => resolve(true));
    })
  };

  listenToFilterCardsChanges() {
    this.portalCardsSubscription =
      this._portalFilterCardsService.portalFilterCard$.subscribe(
        (portalCards: Array<PortalFilterCard>) => {
          this.portalCards = portalCards;
        }
      );
  }

  listenToChangesOnSelectedPortalFilterCard(): Promise<any> {
    return new Promise((resolve) => {
      this.myEventSubscribeCurrentPortal = this._portalFilterCardsService.currentPortalFilterCard$.subscribe(
        async (selectedPortalFilterCard: PortalFilterCard | null) => {
          if (selectedPortalFilterCard) {
            this.selectedPortalFilterCard = selectedPortalFilterCard;
            this.topFiveData.title = selectedPortalFilterCard.title;
            this.portalCards.forEach((portalCard: PortalFilterCard) => {
              portalCard.active = false;
              if (portalCard.id === selectedPortalFilterCard.id) {
                portalCard.active = true;
              }
            });
          }
        }
      );
      resolve(true);
    })
  };

  async clickCard() {
    this.loadingService.present();
    if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.REDE_MULHER_USERS) {
      await this.getConfigTable();
      this.loadingService.dismiss();
    }
    if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.REDE_MULHER_EMERGENCIES) {
      await this.topEmergencyData();
      await this.getConfigTable();
      this.loadingService.dismiss();
    };
    if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.REDE_MULHER_REPORTED) {
      // await this.topEmergencyData();
      await this.getConfigTable();
      this.loadingService.dismiss();
    };
    if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.REDE_MULHER_AGRESSOR) {
      // await this.topDenouncementData();
      await this.getConfigTable();
      this.loadingService.dismiss();
    };
  };

  topEmergencyData(): Promise<boolean> {
    this.topFive = [];
    return new Promise((resolve) => {
      this.redeMulherService.emergencyTops().subscribe({
        next: (response) => {
          response.Results.forEach((oneTop: any) => {
            oneTop.title = oneTop.NM_Denuncia;
            oneTop.value = oneTop.NR_Quantidade;
            oneTop.color = oneTop.value % 2 == 0 ? '#895BF1' : '#FEBF0F';
            delete oneTop.NM_Denuncia;
            delete oneTop.NR_Quantidade;
            this.topFive.push(oneTop);
          });
          var emegencyCard = this.portalCards.filter(function (card) {
            return card.title === PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES;
          })[0];
          this.topFiveData.total = emegencyCard.value;
        },
        error: (err) => {
          console.log(err);
        },
      }).add(() => resolve(true));
    })
  };

  topReportedData(): Promise<boolean> {
    this.topFive = [];
    return new Promise((resolve) => {
      this.redeMulherService.reportedTops().subscribe({
        next: (response) => {
          response.data.forEach((oneTop: any) => {
            oneTop.title = oneTop.Emergencia;
            oneTop.value = oneTop.quantidade;
            oneTop.color = oneTop.value % 2 == 0 ? '#895BF1' : '#FEBF0F';
            delete oneTop.Emergencia;
            delete oneTop.quantidade;
            this.topFive.push(oneTop);
          });
          var emegencyCard = this.portalCards.filter(function (card) {
            return card.title === PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES;
          })[0];
          this.topFiveData.total = emegencyCard.value;
        },
        error: (err) => {
          console.log(err);
        },
      }).add(() => resolve(true));
    })
  };


  getConfigTable(): Promise<boolean> {
    return new Promise(async resolve => {
      this.tableConfig = await this.getData();
      if (this.tableConfig.showButton) {
        this.tableConfig.buttonTable.handler = (dataObjectTable: any) => {
          this.openVisualize(dataObjectTable);
        };
      }
      this.painelData = this.tableConfig.tableBody;
      resolve(true);
    })
  }

  showDoughnutChart() {
    return (
      this.selectedPortalFilterCard?.title ===
      PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS
    );
  }

  public searchAndFilterItens(event: any) {
    let newArray: any = [];
    this.painelData.forEach((elementArray) => {
      if (event?.searchValue && event?.filterValue) {
        if (this.filterDepedingCard(event.filterValue, elementArray)) {
          if (this.validateObject(elementArray, event.searchValue)) {
            newArray.push(elementArray);
          }
        }
      } else {
        if (event?.searchValue) {
          if (this.validateObject(elementArray, event.searchValue)) {
            newArray.push(elementArray);
          }
        } else {
          if (this.filterDepedingCard(event.filterValue, elementArray))
            newArray.push(elementArray);
        }
      }
    });
    this.tableConfig.tableBody = newArray;
  }

  public filterDepedingCard(value: string, object: any): boolean {
    switch (this.selectedPortalFilterCard?.title) {
      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS:
        if (value == 'true' && object.Status == 'Ativo') return true;
        else if (value == 'false' && object.Status == 'Inativo') return true;
        return false;

      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES:
        if (value.toLowerCase().includes(object.type.toLowerCase()))
          return true;
        return false;

      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_REPORTED:
        if (value.toLowerCase().includes(object.type.toLowerCase()))
          return true;
        return false;

      default:
        return false;
    }
  }

  public validateObject(object: any, textValue: string): boolean {
    switch (this.selectedPortalFilterCard?.title) {
      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS:
        if (
          object.Email.toString().toLowerCase().includes(textValue) ||
          object.CPF.toString().includes(textValue) ||
          object.Nome.toString().includes(textValue) ||
          object.Telefone.toString().toLowerCase().includes(textValue) ||
          object.Status.toString().toLowerCase().includes(textValue)
        )
          return true;
        return false;
      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_REPORTED:
        if (
          object.name.toLowerCase().includes(textValue) ||
          object.date.toString().includes(textValue) ||
          object.hour.toLowerCase().includes(textValue) ||
          object.amount.toString().toLowerCase().includes(textValue) ||
          object.resume.toLowerCase().includes(textValue)
        )
          return true;
        return false;

      case PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR:
        if (
          object.NM_Agressor.toLowerCase().includes(textValue) ||
          object.NR_CPF.toString().includes(textValue) ||
          object.NR_Processo.toLowerCase().includes(textValue) ||
          object.DT_Validade.toString().toLowerCase().includes(textValue) ||
          object.NM_Usuario.toLowerCase().includes(textValue)
        )
          return true;
        return false;

      default:
        return false;
    }
  }

  public clearSearchTable() {
    this.tableConfig.tableBody = this.painelData;
  }

  private async getData() {
    return await this._tableService.getObjectConfigTable(this.router.url, SIDEBAR_OPTIONS_URLS.GENERAL_PANEL, this.selectedPortalFilterCard);
  }

  openVisualize(data: any) {
    let page: string;
    let passData: any;
    if (this.selectedPortalFilterCard?.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS) {
      page = EXTRAS_PAGES_URLS.VIEW_USER;
      passData = { userData: data };
    } else {
      page = EXTRAS_PAGES_URLS.VIEW_REPORTED;
      passData = { reportData: data, card: this.selectedPortalFilterCard };
    }
    this.router.navigate([page], { relativeTo: this.route, state: passData });
  }
}
