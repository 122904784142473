import { Component } from '@angular/core';

@Component({
  selector: 'app-interaction-photos',
  standalone: true,
  imports: [],
  templateUrl: './photos.component.html',
  styleUrl: './photos.component.scss',
})
export class PhotosComponent {}
