import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-top-five',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './top-five.component.html',
  styleUrl: './top-five.component.scss',
})
export class TopFiveComponent {
  @Input() topFive: Array<any> = [];
  @Input() data: any = {};
  currentYear = new Date().getFullYear();

  constructor() { }
}
