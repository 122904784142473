<section class="portal-header--container">
  <app-sidebar-option-title-band [card]="card"></app-sidebar-option-title-band>
</section>
<div *ngIf="showPage" class="view_report__container--grid">
  <div></div>
  <div>
    <div class="title-box">
      <div class="row border-bottom-0 pb-0">
        <div class="div-col-title col-lg-6 col-xl-6 col-12">
          <p class="portal-body__title mb-0">{{title}}</p>
        </div>
        <div class="div-col-back col-lg-6 col-xl-6 col-12">
          <app-dynamic-button [buttonConfig]="configButtonBack"></app-dynamic-button>
        </div>
      </div>
    </div>
    <div class="portal-body__view-report-card">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xxl-3 col-12 d-flex align-items-center justify-content-center">
            <div class="user-img-box" [ngClass]="{
                'background_img_190rj': portal == portals.RJ_190,
                'background_img_rede_mulher': portal == portals.REDE_MULHER,
                'background_img_rede_escola': portal == portals.REDE_ESCOLA
                }" [ngStyle]="{'background-image': imgProfile}">
            </div>
          </div>
          <div class="col-xxl-9 col-12 my-auto">
            <div class="view_report__row--title col-12 no-padding">{{reportData?.nomeAutor}}</div>
            <div class="view_report__row--text col-12 no-padding">{{reportData?.idadeAutor}} anos</div>
            <div class="view_report__row--text col-12 no-padding d-flex">
              <div class="col-xxl-3 col-4 no-padding">
                <span class="view_report__row--text infos_text"><img src="assets/imgs/sinal_ic.svg" alt="">Emergências:
                  {{reportData?.quantidadeEmergencias}}</span>
              </div>
              <div class="col-xxl-3 col-4 no-padding">
                <span class="view_report__row--text infos_text"><img src="assets/imgs/megaphone.svg" alt="">Denúncias:
                  {{reportData?.quantidadeDenuncias}}
                </span>
              </div>
              <div class="col-xxl-3 col-4 no-padding">
                <span class="view_report__row--text infos_text"><img src="assets/imgs/arrow-loop.svg" alt="">Reports:
                  {{reportData?.quantidadeReports}}</span>
              </div>
            </div>
            <div class="view_report__row--text col-12 no-padding">
              <span class="view_report__row--text infos_text"><img src="assets/imgs/arrow-loop.svg" alt="">Reports desse
                post:
                {{reportData?.quantidadePosts}}</span>
            </div>
          </div>
        </div>
        <div class="row mt-24" [ngClass]="{'border-bottom-0': portal === portals.REDE_ESCOLA}">
          <div class="col-12 no-padding mb-10">
            <span class="view_report__row--title">Descrição do post</span>
          </div>
          <div class="col-12 no-padding mb-20">
            <span class="view_report__row--text text-break">
              {{reportData?.resumoPost}}
            </span>
          </div>
          <div class="col-12 no-padding d-flex align-items-center">
            <div class="container-fluid">
              <div class="div-row-images row no-padding border-bottom-0">
                @for(image of reportData?.images; track $index){
                <div class="box-img-text mr-12 mb-12" (click)="expandPage(image?.arquivoKey)"
                  [ngStyle]="{'background-image': 'url(' + image?.arquivoKey + ')'}" [ngClass]="{
                    'background_img_190rj': portal == portals.RJ_190,
                    'background_img_rede_mulher': portal == portals.REDE_MULHER,
                    'background_img_rede_escola': portal == portals.REDE_ESCOLA}">
                </div>
                }
              </div>
            </div>
          </div>
        </div>
        @if(portal != portals.REDE_ESCOLA) {

        <div class="row mt-24">
          <div class="view_report__row--title col-12 no-padding mb-20">Qual foi a violação?
            <!-- @if(!pageOnlyShowData) {
              <span class="text-danger">*</span>
            } -->
          </div>
          <div class="col-lg-5 col-xl-5 col-12 no-padding">
            <form [formGroup]="formGroup">
              <select class="form-select" id="violation" formControlName="violation">
                <option selected value="0">Selecione</option>
                @for(violation of reported.violations; track violation.id){
                <option [value]="violation.id">{{ violation.name }}</option>
                }
              </select>
            </form>
          </div>
        </div>
        <div class="row mt-24 border-bottom-0 pb-0">
          <div class="view_report__row--title col-12 no-padding mb-25">O que fazer?
            @if(!pageOnlyShowData) {
            <span class="text-danger">*</span>
            }
          </div>
          <form [formGroup]="formGroup" class="no-padding">
            <div class="container-fluid">
              <div class="row div-box-select-row">
                @for(whatDoOption of reported.whatToDo; track whatDoOption.id){
                <div class="col-lg-4 col-xl-4 col-12 div-box-select-option">
                  <input [ngClass]="portal === portals.REDE_MULHER? 'rede-mulher' : 'rj-190'" type="radio"
                    formControlName="whatToDo" [id]="whatDoOption.id" [value]="whatDoOption.id" />
                  <label class="label_radius_button mb-3" [for]="whatDoOption.id">{{whatDoOption.name}}</label>
                </div>
                }
              </div>
            </div>
            <div class="col-12 no-padding d-flex justify-content-center">
              @if(!pageOnlyShowData) {
              <button (click)="saveUser()" class="mt-40 save-button"
                [ngClass]="{'button_color_rj190': portal == portals.RJ_190, 'button_color_rede_mulher': portal == portals.REDE_MULHER}"
                type="submit" [disabled]="!this.formGroup.valid">
                Confirmar
              </button>
              }
            </div>
          </form>
        </div>
        }
      </div>
    </div>
  </div>
  <div></div>
</div>
