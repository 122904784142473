import { ApplicationConfig, ErrorHandler } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { globalInterceptor } from './shared/interceptors/global/global.interceptor';
import { provideToastr } from 'ngx-toastr';
import { globalErrorHandler } from './shared/interceptors/error-handler/global-error-handler.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideToastr({
      timeOut: 2000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true
    }),
    provideHttpClient(withInterceptors([
      globalInterceptor,
      globalErrorHandler
    ])),
  ]
};
