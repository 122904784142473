export class LineDataset {
  constructor(
    data: Array<number>,
    borderColor: string,
    tension: number = 0.45,
    types?: any,
    subtitles?: Subtitle[] | undefined,
  ) {
    this.data = data;
    this.tension = tension;
    this.borderWidth = 5;
    this.borderColor = borderColor;
    this.pointStyle = 'line';
    this.types = types;
    this.subtitles = subtitles;
  }

  data: Array<number>;
  borderColor: string;
  tension: number;
  borderWidth: number;
  pointStyle: string;
  types: any;
  subtitles: Subtitle[] | undefined
}

export interface Subtitle {
  blockColor: string,
  title: string
}
