import { Component } from '@angular/core';
import { SidebarOptionTitleBandComponent } from '../title-band/sidebar-option-title-band.component';
import { DynamicButtonComponent } from '../dynamic-button/dynamic-button.component';
import { getPortalTypeFromLink } from '../../utils';
import { Router } from '@angular/router';
import { SIDEBAR_OPTIONS_URLS } from '../../enums/sidebar-option-urls.enum';
import { ButtonConfig } from '../dynamic-button/dynamic-button.model';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';


@Component({
  selector: 'app-view-user-page',
  standalone: true,
  imports: [CommonModule, SidebarOptionTitleBandComponent, DynamicButtonComponent, NgxMaskDirective, NgxMaskPipe],
  providers: [
    provideNgxMask(),
  ],
  templateUrl: './view-user-page.component.html',
  styleUrl: './view-user-page.component.scss'
})
export class ViewUserPageComponent {

  portal: string;
  userData: any;
  imgProfile: string;
  showPage: boolean = false;
  configButtonBack: ButtonConfig = { label: 'Voltar', imgSrc: "assets/back_arrow_ic.svg", handler: () => this.backPage() };

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userData = window.history.state.userData;
    this.portal = getPortalTypeFromLink(this.router.url);
    //validar como será feito, protendo a URL por enquanto.
    if (!this.userData) return this.navToRetrocessPage();
    if (!this.userData.age) this.userData.age = 25;
    this.imgProfile = 'url("../../../../assets/user_img.svg")';
    this.showPage = true;
  }

  navToRetrocessPage() {
    this.router.navigateByUrl(`${this.portal}/${SIDEBAR_OPTIONS_URLS.GENERAL_PANEL}`);
  }

  backPage() {
    window.history.back();
  }
}
