import { CommonModule } from '@angular/common';
import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DynamicTablePipe } from './dynamic-table.pipe';
import { ButtonEndTable, TableSettings } from './models/dynamic-table.model';
import { Router } from '@angular/router';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { cpfMask, phoneMask } from '../../utils';
import { SIDEBAR_OPTIONS_URLS } from '../../enums/sidebar-option-urls.enum';

@Component({
  selector: 'app-dynamic-table',
  standalone: true,
  imports: [CommonModule, DynamicTablePipe],
  templateUrl: './dynamic-table.component.html',
  styleUrl: './dynamic-table.component.scss'
})
export class DynamicTableComponent implements OnChanges {
  @Input() tableSettings: TableSettings;
  @Input() tableBody: any;
  @Input() buttonTable: ButtonEndTable;
  @Output() public changePage = new EventEmitter<any>();
  pages: number[];
  activeLink: number = 1;
  arrayPages: any = [];
  urlActive: string;
  portalTypes = PORTAL_TYPE_URLS;
  numberPages: any;
  lastPageIndexData: any;
  initPageIndexData: any;
  totalPageIndexData: any;

  constructor(private router: Router) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tableBody']?.currentValue) {
      this.urlActive = this.router.url;
      let newArrayAfterFilter = changes['tableBody'].currentValue;
      newArrayAfterFilter = this.maskData(newArrayAfterFilter);
      this.getNumPages(newArrayAfterFilter);
      this.activePage(changes['tableSettings'].currentValue.currentPage);
    }
    if (changes['buttonTable']?.currentValue) this.buttonTable = changes['buttonTable'].currentValue;
  };

  public swapPage(nextPage: boolean) {
    if (nextPage) this.activeLink += 1;
    else this.activeLink -= 1;
    this.changePage.emit(this.activeLink);
  };


  public activePage(pageNumber: number, click?: boolean) {
    if (pageNumber != this.activeLink) {
      this.activeLink = pageNumber;
      if (click) this.changePage.emit(this.activeLink);
      else this.arrayPages = this.tableSettings.tableBody;
    } else this.arrayPages = this.tableSettings.tableBody;

  }

  public getNumPages(data: any) {
    let array: any[] = data;
    let length = this.tableSettings.totalItens ? this.tableSettings.totalItens : array.length;
    this.numberPages = Math.ceil(length / this.tableSettings.itensPerPage);
    this.pages = this.paginateLinks(this.numberPages);
    this.buildNumberIndexData(data);
  };

  public paginateLinks(links: any) {
    let newLinksArray: any = [];
    links = Array.from({ length: links }, (_, i) => i + 1);
    let max: number = 0;
    this.activeLink == 1 ? max = this.activeLink + 2 : max = this.activeLink + 1;
    let min = this.activeLink - 1;
    links.forEach((link: any) => {
      if (link >= min && link <= max) {
        newLinksArray.push(link);
      }
    });
    return newLinksArray;
  };

  public buildNumberIndexData(array: any) {
    let length = this.tableSettings.totalItens ? this.tableSettings.totalItens : array.length;
    this.lastPageIndexData = this.activeLink * this.tableSettings.itensPerPage;
    this.initPageIndexData = this.activeLink * this.tableSettings.itensPerPage - this.tableSettings.itensPerPage;
    this.totalPageIndexData = length;
    if (this.activeLink == 1) this.initPageIndexData = 1;
    if (this.lastPageIndexData > length) this.lastPageIndexData = length;
  }

  public maskData(dataTable: any) {
    dataTable.forEach((uniqueData: any) => {
      if (uniqueData?.cpf && uniqueData?.cpf.match(/^[0-9]+$/) != null) uniqueData.cpf = cpfMask(uniqueData.cpf);
      if (uniqueData?.CPF && uniqueData?.CPF.match(/^[0-9]+$/) != null) uniqueData.CPF = cpfMask(uniqueData.CPF);
      if (uniqueData.phone && uniqueData?.phone.match(/^[0-9]+$/) != null) uniqueData.phone = phoneMask(uniqueData.phone);
      if (uniqueData.Telefone && uniqueData?.Telefone.match(/^[0-9]+$/) != null) uniqueData.Telefone = phoneMask(uniqueData.Telefone);
      if (uniqueData.DT_Validade) uniqueData.DT_Validade = uniqueData.DT_Validade.split('T', 1)[0];
    });
    return dataTable;
  };

  showToolTip(headerName: string, toolTipText: string) {
    return headerName == 'Resumo do Post' ? toolTipText  : null;
  }
}
