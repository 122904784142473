import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { PORTAL_FILTER_CARD_TITLES } from "../../../shared/components/filter-card/enums/filter-card-titles.enum";
import { HeaderTableSettings } from "../../../shared/components/header-box-table/header-box-table.model";
import { formatResponseTableReports, formatResponseTableOpenStatus, formatResponseTableClosedStatus, formatResponseTableBlocked, getOnlyFirstName, getTypeUser } from "../../../shared/utils";
import { ReportsService } from "../reports-page/reports.service";
import { AuthService } from "../../../shared/services/auth/auth.service";


@Injectable({
  providedIn: 'root'
})

export class TableReported190RJService {
  constructor(
    private reportedRJ190Service: ReportsService,
    private authService: AuthService
  ) { }

  public async getSettingsTable(card: any, pageNumber?: number, query?: string) {
    let config = {} as HeaderTableSettings;
    let responseReported = await this.getDataTableByCard(card, pageNumber, query);
    if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_REPORTS) {
      if (responseReported?.data?.length > 0) {
        responseReported.data = formatResponseTableReports(responseReported.data, this.authService.getAccessToken);
      } else responseReported.data = [];
      config.headerTitle = card.title;
      config.itensPerPage = 10;
      config.totalItens = responseReported.total;
      config.showTextBox = true;
      config.titleSearch = 'Pesquise por usuário ou palavra';
      config.showFirstFilterBox = false;
      config.showSecondFilterBox = false;
      config.showClearButton = false;
      config.showClearIc = true;
      config.titlesTables = [
        'Usuário',
        'Data',
        'Horário',
        'Quantidade',
        'Resumo do Post',
        'Status',
        'Ver Publicação',];
      config.showButton = true;
      config.buttonTable = { label: 'Visualizar' };
      config.tableBody = responseReported.data;

    } else if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_OPEN_STATUS) {
      if (responseReported?.data?.length > 0) {
        responseReported.data = formatResponseTableOpenStatus(responseReported.data, this.authService.getAccessToken);
      } else responseReported.data = [];
      config.headerTitle = card.title;
      config.itensPerPage = 10;
      config.totalItens = responseReported.total;
      config.showTextBox = true;
      config.titleSearch = 'Pesquise por usuário ou palavra';
      config.showFirstFilterBox = false;
      config.showSecondFilterBox = false;
      config.showClearButton = false;
      config.showClearIc = true;
      config.titlesTables = [
        'Usuário',
        'Data',
        'Horário',
        'Quantidade',
        'Resumo do Post',
        'Ver Publicação',
      ];
      config.showButton = true;
      config.buttonTable = { label: 'Visualizar' };
      config.tableBody = responseReported.data;
    } else if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_BLOCKED) {
      if (responseReported?.data?.length > 0) {
        responseReported.data = formatResponseTableBlocked(responseReported.data, this.authService.getAccessToken);
      } else responseReported.data = [];
      config.headerTitle = card.title;
      config.itensPerPage = 10;
      config.totalItens = responseReported.total;
      config.showTextBox = true;
      config.titleSearch = 'Pesquise por usuário ou palavra';
      config.showFirstFilterBox = false;
      config.showSecondFilterBox = false;
      config.showClearButton = false;
      config.showClearIc = true;
      config.titlesTables = [
        'Usuário',
        'CPF',
        'Telefone',
        'E-mail',
        'Data de Bloqueio',
        'Ver Publicação',
      ];
      config.showButton = true;
      config.buttonTable = { label: 'Visualizar' };
      config.tableBody = responseReported?.data;

    } else if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_CLOSED_STATUS) {
      if (responseReported?.data?.length > 0) {
        responseReported.data = formatResponseTableClosedStatus(responseReported.data, this.authService.getAccessToken);
      } else responseReported.data = [];
      config.headerTitle = card.title;
      config.itensPerPage = 10;
      config.totalItens = responseReported.total;
      config.showTextBox = true;
      config.titleSearch = 'Pesquise por usuário ou palavra';
      config.showFirstFilterBox = false;
      config.showSecondFilterBox = false;
      config.showClearButton = false;
      config.showClearIc = true;
      config.titlesTables = [
        'Usuário',
        'CPF',
        'Telefone',
        'E-mail',
        'Data de Bloqueio',
        'Status',
        'Ver Publicação',
      ];
      config.showButton = true;
      config.buttonTable = { label: 'Visualizar' };
      config.tableBody = responseReported.data;
    };
    return config;
  }

  private async getDataTableByCard(card?: any, pageNumber?: number, query?: string) {
    let response: any;
    if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_REPORTS) {
      await firstValueFrom(this.reportedRJ190Service.getReportsTable(10, pageNumber, query)).then((responseAPI) => response = responseAPI).catch(() => response = []);
      return response;
    } else if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_OPEN_STATUS) {
      await firstValueFrom(this.reportedRJ190Service.getStatusOpenTable(10, pageNumber, query)).then((responseAPI) => response = responseAPI).catch(() => response = []);
      return response;
    } else if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_BLOCKED) {
      await firstValueFrom(this.reportedRJ190Service.getBlockedTable(10, pageNumber, query)).then((responseAPI) => response = responseAPI).catch(() => response = []);
      return response;
    } else if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_CLOSED_STATUS) {
      await firstValueFrom(this.reportedRJ190Service.getStatusClosedTable(10, pageNumber, query)).then((responseAPI) => response = responseAPI).catch(() => response = []);
      return response;
    };
  }
}
