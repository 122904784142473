<section class="header--container relative" [ngClass]="currentPortalType">
  <input type="checkbox" id="check-menu-hamburguer">
  <label for="check-menu-hamburguer" (click)="showCollpaseMenu()" class="checkbtn">
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
  </label>
  <section class="d-flex align-items-center">
    <img class="header--brasao-pmerj" src="./../../assets/imgs/brasao-pmerj-small.svg" />
    <span class="header--pmerj">Polícia Militar do Estado do Rio de Janeiro</span>
  </section>
  <section class="d-flex align-items-center justify-content-between">
    <section class="d-flex align-items-center position-relative">
      <div class="header--portal-type--img-container position-relative" [ngClass]="currentPortalType">
        <img class="header--portal-type--img position-absolute"
          src="./../../../assets/imgs/{{ currentPortalType }}-small.svg" />
      </div>
      <div class="dropdown" (click)="onClickOutside($event)">
        <img #dropdownButton src="../../../../assets/imgs/arrow_down_white.svg" (click)="toggleDropdown($event)">
        <div @dropdownAnimation #dropdownContent class="dropdown-content" *ngIf="dropdownVisible">
          @for(portal of portals; track portal ){
          <a (click)="updatePortalType(portal.src)">
            <div class="header--portal-type--img-container position-relative" [ngClass]="portal.src">
              <img class="header--portal-type--img position-absolute"
                src="./../../../assets/imgs/{{ portal.src }}-small.svg" />
            </div> {{portal.name}}
          </a>
          }
        </div>
      </div>
      <span class="header--portal-type--title">{{ currentPortalName }}</span>
    </section>
    <section class="header--username-container">
      <span class="header--username">{{nameUser}}</span>
    </section>
    <section (click)="logout()" role="button" class="">
      <img src="./../../../assets/imgs/power.svg" class="header--logout-img" />
    </section>
  </section>

  <div class="side-bar-collapse">
    <app-sidebar-collpse></app-sidebar-collpse>
  </div>
</section>
