import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { RestApiService } from '../../rest-api/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private readonly baseUrl = environment.baseUrl190RJ;
  constructor(
    private restApiService: RestApiService,
  ) { }

  getTotalReports(): Observable<any> {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'reports/total-reports',
    };
    return this.restApiService.get(request);
  };

  getReportsTable(limit?: number, page?: number, query?: string) {
    if (!limit) limit = 10;
    if (!page) page = 1;
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `reports?${query ? `q=${query}&` : ""}page=${page}&limit=${limit}`,
    };
    return this.restApiService.get(request);
  };


  getStatusOpenTable(limit?: number, page?: number, query?: string) {
    if (!limit) limit = 10;
    if (!page) page = 1;
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `status-aberto/listar?${query ? `q=${query}&` : ""}page=${page}&limit=${limit}`,
    };
    return this.restApiService.get(request);
  };

  getStatusClosedTable(limit?: number, page?: number, query?: string) {
    if (!limit) limit = 10;
    if (!page) page = 1;
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `status-fechado/listar?${query ? `q=${query}&` : ""}page=${page}&limit=${limit}`,
    };
    return this.restApiService.get(request);
  };

  getReportById(id: string) {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `reports/${id}`,
    };
    return this.restApiService.get(request);
  };

  toDoReport(id: string, body: any) {
    let endpoint = this.baseUrl + `reports/${id}/status`;
    return this.restApiService.put(endpoint, body);
  };

  toDoOpenStat(id: string, body: any) {
    let endpoint = this.baseUrl + `status-aberto/${id}/status`;
    return this.restApiService.put(endpoint, body);
  };

  toDoCloseStat(id: string, body: any) {
    let endpoint = this.baseUrl + `status-fechado/${id}/status`;
    return this.restApiService.put(endpoint, body);
  };


}


