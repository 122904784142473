import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SidebarOptionService } from '../sidebar/services/sidebar-option.service';
import { PortalTypeService } from '../../services/portal-type.service';
import { SIDEBAR_OPTIONS_LIST } from '../sidebar/consts/sidebar-options.const';
import { PORTAL_INFO } from '../../models/portal-info';
import { SIDEBAR_OPTION } from '../../models/sidebar-option';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { CommonModule } from '@angular/common';
import { authSecretKey, getNameUser, getPortalTypeFromLink } from '../../utils';
import { PORTAL_TYPES } from '../../enums/portal-types.enum';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { PORTAL_TYPES_NAMES } from '../../enums/portal-types-names.enum';
import { trigger, transition, style, animate } from '@angular/animations';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-sidebar-collpse',
  standalone: true,
  imports: [CommonModule, RouterModule],
  animations: [
    trigger('dropdownAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-10px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(-10px)' }))
      ])
    ])
  ],
  templateUrl: './sidebar-collpse.component.html',
  styleUrl: './sidebar-collpse.component.scss'
})
export class SidebarCollpseComponent {
  currentPortal: PORTAL_INFO;
  sidebarOptions = SIDEBAR_OPTIONS_LIST;
  nameUser: string;
  portals: any[] = [
    { name: PORTAL_TYPES_NAMES.RJ_190, src: PORTAL_TYPES.RJ_190 },
    { name: PORTAL_TYPES_NAMES.REDE_MULHER, src: PORTAL_TYPES.REDE_MULHER },
    { name: PORTAL_TYPES_NAMES.REDE_ESCOLA, src: PORTAL_TYPES.REDE_ESCOLA }
  ];
  dropdownVisible: boolean = false;
  @ViewChild('dropdownButton') dropdownButton: ElementRef;
  @ViewChild('dropdownContent') dropdownContent: ElementRef;

  constructor(
    private readonly _portalTypeService: PortalTypeService,
    private readonly _sidebarOptionsService: SidebarOptionService,
    private readonly localStorage: LocalStorageService,
    private readonly router: Router,
    private readonly authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.listensToPortalChanges();
    this.listensToSidebarOptionsChanges();
    this.getUserName();
  }

  getUserName() {
    let token;
    this.localStorage.getItem(authSecretKey).subscribe((resTok => token = resTok));
    this.nameUser = getNameUser(token);
  }

  listensToPortalChanges() {
    this._portalTypeService.currentPortalType$.subscribe(
      (currentPortal: PORTAL_INFO) => {
        this.currentPortal = currentPortal;
      }
    );
  }

  listensToSidebarOptionsChanges() {
    this._sidebarOptionsService.currentSidebarOption$.subscribe(
      (currentOption: SIDEBAR_OPTION) => {
        this.sidebarOptions.forEach((option) => {
          option.active = false;
          if (option.id === currentOption.id) {
            option.active = true;
          }
        });
      }
    );
  }

  updateSidebarOption(currentOption: SIDEBAR_OPTION) {
    let portalType = getPortalTypeFromLink(this.router.url);
    this.router.navigateByUrl(`${portalType}/${currentOption.url}`);
    this._sidebarOptionsService.updateSidebarOption(currentOption);
    let menu: any = document.getElementById("check-menu-hamburguer");
    const overflow = document.getElementsByTagName("body")[0].style.overflow = "scroll";
    const overflowHtml = document.getElementsByTagName("html")[0].style.overflow = "scroll";
    menu.checked = false;
  }

  updatePortalType(portal: PORTAL_TYPES) {
    this._portalTypeService.updatePortalType(portal);
    this._sidebarOptionsService.resetSidebarOption();
    this.router.navigateByUrl(portal);
    let menu: any = document.getElementById("check-menu-hamburguer");
    menu.checked = false;
    const overflow = document.getElementsByTagName("body")[0].style.overflow = "scroll";
    const overflowHtml = document.getElementsByTagName("html")[0].style.overflow = "scroll";
  }

  closeCollapseMenu() {
    const overflow = document.getElementsByTagName("body")[0].style.overflow = "scroll";
    const overflowHtml = document.getElementsByTagName("html")[0].style.overflow = "scroll";
  }

  toggleDropdown(event: MouseEvent) {
    event.stopPropagation();
    this.dropdownVisible = !this.dropdownVisible;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (
      this.dropdownButton && this.dropdownContent &&
      !this.dropdownButton.nativeElement.contains(event.target) &&
      !this.dropdownContent.nativeElement.contains(event.target)
    ) {
      this.dropdownVisible = false;
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl(PORTAL_TYPE_URLS.LOGIN);
  }

}
