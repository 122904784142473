{
  "error_type": "error",
  "error_title": "Erro!",
  "success_type": "success",
  "successs_title": "Sucesso!",
  "warning_type": "warning",
  "warning_title": "Cuidado!",
  "info_type": "info",
  "info_title": "Alerta!"
}
