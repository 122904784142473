<div class="container-fluid">
  <div class="row px-0">
    <div class="col-12 px-0">
      <table class="dynamic_table" cellpadding="10" [ngClass]="tableSettings.tableBody?.length < 1 ? 'mb-24' : ''"
        id="table__data">
        <thead>
          <tr class="row_title">
            @for (headerColumn of tableSettings.titlesTables; track $index) {
            <th class="title_column">{{ headerColumn }}</th>
            }
          </tr>
        </thead>
        <tbody>
          @for (firstFor of arrayPages; track $index) {
          <tr class="row_texts">
            <td *ngFor="let item of firstFor | keys; let indexSecondFor = index" class="text_column"
              [ngClass]="{ padding_top: $index === 0 }" [ngStyle]="{'display': item == 'id' ? 'none' : 'table-cell'}"
              [attr.hover-tooltip]="showToolTip(tableSettings.titlesTables[indexSecondFor], firstFor[item])">
              <div class="p-0 table__title--mobile">
                <span class="headerColumnName">{{tableSettings.titlesTables[indexSecondFor]}}:</span>
                {{ firstFor[item] }}
              </div>
            </td>
            @if (tableSettings.showButton) {
            <td [ngClass]="{ padding_top: $index === 0 }">
              <button class="button_end_table" [ngClass]="{
                  rede_mulher_color: urlActive.includes(portalTypes.REDE_MULHER),
                  rj190_color: urlActive.includes(portalTypes.RJ_190),
                  rede_escola_color: urlActive.includes(portalTypes.REDE_ESCOLA)
                }" (click)="
                  buttonTable.handler ? buttonTable.handler(firstFor) : false
                ">
                {{ buttonTable.label }}
              </button>
            </td>
            }
          </tr>
          } @empty {
          <span class="no_data">Sem dados</span>
          }
        </tbody>
      </table>

      @if (arrayPages.length > 0) {
      <div class="pagination">
        <!-- <div class="align-left">
          <span>Mostrando do {{initPageIndexData}} ao {{lastPageIndexData}} de um total de
            {{totalPageIndexData}}</span>
        </div> -->
        <div class="align-center">
          <button class="button_swap_page ms-1" [disabled]="activeLink == pages[0]" (click)="swapPage(false)">
            <img src="assets/chevron-left_table.svg" alt="">
          </button>
          <ul>
            @for (page of pages; track $index) {
            <li class="link" [ngClass]="{
                active_rede_mulher_color:
                  activeLink === page && urlActive.includes(portalTypes.REDE_MULHER),
                active_rj190_color:
                  activeLink === page && urlActive.includes(portalTypes.RJ_190),
                active_rede_escola_color:
                  activeLink === page && urlActive.includes(portalTypes.REDE_ESCOLA)
              }" (click)="activePage(page, true)">
              {{ page }}
            </li>
            }
          </ul>
          <button class="button_swap_page me-1" [disabled]="activeLink == numberPages" (click)="swapPage(true)">
            <img src="assets/chevron-right_table.svg" alt="">
          </button>
        </div>
      </div>
      }
    </div>
  </div>
</div>
