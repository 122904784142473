import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { RedeMulherService } from "../../shared/services/rede-mulher/rede-mulher.service";
import { AuthService } from "../../shared/services/auth/auth.service";
import { HeaderTableSettings } from "../../shared/components/header-box-table/header-box-table.model";
import { getCPFMasked, getEmailMasked, getOnlyDDNumber, getOnlyFirstName, getTypeUser } from "../../shared/utils";
import { TYPE_USERS } from "../../shared/enums/type-users";

@Injectable({
  providedIn: 'root'
})

export class TableUsersRedeMulherService {
  constructor(
    private redeMulherService: RedeMulherService,
    private authService: AuthService
  ) { }

  public async getSettingsTableUsersApp(pageNumber?: number, filter?: string) {
    let config = {} as HeaderTableSettings;
    let responseUsers = await this.getDataTableUsersApp(pageNumber, filter);
    let newArray: any[] = [];
    responseUsers?.data?.forEach((user: any) => {
      let newObject = {
        name: user?.nome ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getOnlyFirstName(user.nome) : user.nome : '',
        cpf: user?.cpf ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getCPFMasked(user.cpf) : user.cpf : '',
        phone: user?.telefone ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getOnlyDDNumber(user.telefone) : user.telefone : 'Telefone invalído',
        email: user?.email ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getEmailMasked(user.email) : user.email : '',
        id: user.id
      };
      newArray.push(newObject);
    });
    config.totalItens = responseUsers.total;
    config.headerTitle = "Usuários";
    config.titleSearch = "Pesquisar por nome";
    config.titlesTables = ['Nome', 'CPF', 'Telefone', 'E-mail'];
    config.itensPerPage = 10;
    config.showTextBox = true;
    config.titleSearch = 'Pesquise por nome';
    config.showClearIc = true;
    if (getTypeUser(this.authService.getAccessToken) != TYPE_USERS.SPEC) {
      config.titlesTables.push('Editar');
      config.showButton = true;
      config.buttonTable = { label: 'Editar' };
    }
    config.tableBody = newArray;
    return config;
  }
  private async getDataTableUsersApp(pageNumber?: number, filter?: string) {
    let response: any;
    await firstValueFrom(this.redeMulherService.getUsersApp(10, pageNumber, filter)).then((resService) => response = resService).catch(() => response = []);
    return response;
  }

}

