<section class="portal-header--container">
  <app-sidebar-option-title-band></app-sidebar-option-title-band>
</section>
<section class="portal-body--container">
  <div class="mb-25 container-fluid portal-cards__container">
    <div class="row">
      @for(portalCard of portalCards; track portalCard.id ){
      <div class="col-3">
        <app-filter-card (click)="clickCard()" [portalCard]="portalCard"></app-filter-card>
      </div>
      }
    </div>
  </div>
  @if(selectedPortalFilterCard?.title ===
  PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR){
  <div class="container-fluid mb-25 rede-mulher__agressor-line-chart">
    <div class="row">
      <div class="col-12">
        <app-line-chart></app-line-chart>
      </div>
    </div>
  </div>
  } @else if(selectedPortalFilterCard?.title ===
  PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES){
  <div class="mb-25 container-fluid">
    <div class="row">
      <div class="col-xxl-9 col-xl-8 col-md-8">
        <app-bar-chart></app-bar-chart>
      </div>
      <div class="col-xxl-3 col-xl-4 col-md-4">
        <app-top-five [topFive]="topFive" [data]="topFiveData"></app-top-five>
      </div>
    </div>
  </div>
  } @else if(showDoughnutChart()){
  <div class="mb-25 container-fluid rede-mulher__dougnut-card">
    <div class="row">
      <div class="col-xxl-9 col-xl-8 col-md-8">
        <app-bar-chart></app-bar-chart>
      </div>
      <div class="col-xxl-3 col-xl-4 col-md-4">
        <app-single-doughnut-card></app-single-doughnut-card>
      </div>
    </div>
  </div>
  }
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <app-header-box-table [headerBoxTableSettings]="tableConfig" (searchClick)="searchAndFilterItens($event)"
          (clearSearchClick)="clearSearchTable()"></app-header-box-table>
      </div>
    </div>
  </div>
</section>
