import { PORTAL_TYPE_URLS } from "./enums/portal-types-urls.enum";
import { saveAs } from 'file-saver';
import { TYPE_USERS } from "./enums/type-users";
import { HttpErrorResponse } from "@angular/common/http";
import { ERRORS_ENUM } from "./enums/error.enum";
import { TimeoutError } from "rxjs";

export const authSecretKey = 'Bearer_Token';
export const totalMonths = 12;


export const cpfMask = function (v: any) {
  v = v.toString();
  v = v.replace(/\D/g, "")
  v = v.replace(/(\d{3})(\d)/, "$1.$2")
  v = v.replace(/(\d{3})(\d)/, "$1.$2")
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  return v
}

export const phoneMask = function (value: any) {
  if (!value) return ""
  value = value.toString();
  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{2})(\d)/, "($1) $2")
  value = value.replace(/(\d)(\d{4})$/, "$1-$2")
  return value
}

export const getPortalTypeFromLink = function (link: string) {
  let portal: string = "";
  if (link.includes(PORTAL_TYPE_URLS.REDE_MULHER)) portal = PORTAL_TYPE_URLS.REDE_MULHER;
  else if (link.includes(PORTAL_TYPE_URLS.RJ_190)) portal = PORTAL_TYPE_URLS.RJ_190;
  else if (link.includes(PORTAL_TYPE_URLS.REDE_ESCOLA)) portal = PORTAL_TYPE_URLS.REDE_ESCOLA;
  return portal;
}

export function validateCPF(cpf: string): boolean {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11) {
    return false;
  }
  if (/^(\d)\1{10}$/.test(cpf)) {
    return false;
  }
  let soma = 0;
  let resto: number;
  for (let i = 1; i <= 9; i++) {
    soma += parseInt(cpf.charAt(i - 1)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(cpf.charAt(9))) {
    return false;
  }
  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma += parseInt(cpf.charAt(i - 1)) * (12 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(cpf.charAt(10))) {
    return false;
  }
  return true;
}

export const generateLoremText = function () {
  let text = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
  return text;
}

export const downloadFile = function (data: any, fileName: string) {
  const blob = new Blob([data], { type: 'application/octet-stream' });
  saveAs(blob, fileName + '.xlsx');
}

export const isTokenExpired = (token: any) => Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000;


export const transformDateUStoBR = function (date: any) {
  var arrDate = date.split("-");
  return arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0];
};

export const getNameUser = (token: any) => {
  if (token) return (JSON.parse(atob(token.split('.')[1]))).name;
  return;
};

export const getTypeUser = (token: any) => {
  if (token) return (JSON.parse(atob(token.split('.')[1]))).profile;
  return;
};

export const dataURItoBlob = (dataURI: any) => {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/png' });
};

export const base64ToBlob = (dataURI: string) => {
  const splitDataURI = dataURI.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++)
    ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeString })
};

export const getDateDDMMYY = () => {
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1;
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear().toString().substring(2);
  const pMonth = month.toString().padStart(2, "0");
  const pDay = day.toString().padStart(2, "0");
  return `${pDay}${pMonth}${year}`;
};

export const getDateDDMMYYYY = () => {
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1;
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear().toString();
  const pMonth = month.toString().padStart(2, "0");
  const pDay = day.toString().padStart(2, "0");
  return `${pDay}${pMonth}${year}`;
};

export const getDateDDMM = () => {
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1;
  const day = dateObj.getUTCDate();
  const pMonth = month.toString().padStart(2, "0");
  const pDay = day.toString().padStart(2, "0");
  return `${pDay}${pMonth}`;
};


export const formatDateDDMMYYYY = (data: string) => {
  let split = data.split('-');
  return `${split[2]}/${split[1]}/${split[0]}`;
};

export const formatResponseTableReports = (data: any, jwt: string | null) => {
  let newArray: any = [];
  data.forEach((report: any) => {
    newArray.push({
      author: getTypeUser(jwt) == TYPE_USERS.SPEC ? getOnlyFirstName(report?.autor) : report?.autor,
      data: formatDateDDMMYYYY(report?.dataCadastro),
      hour: report.horarioCadastro,
      quantity: report.quantidade,
      resume: report.resumoPost,
      status: report.status,
      id: report.idDenuncia
    });
  });
  return newArray;
}

export const formatResponseTableOpenStatus = (data: any, jwt: string | null) => {
  let newArray: any = [];
  data.forEach((report: any) => {
    newArray.push({
      author: getTypeUser(jwt) == TYPE_USERS.SPEC ? getOnlyFirstName(report?.autor) : report?.autor,
      data: formatDateDDMMYYYY(report?.dataCadastro),
      hour: report.horarioCadastro,
      quantity: report.quantidade,
      resume: report.resumoPost,
      id: report.idDenuncia
    });
  });
  return newArray;
}

export const formatResponseTableBlocked = (data: any, jwt: string | null) => {
  let newArray: any = [];
  data.forEach((report: any) => {
    newArray.push({
      author: getTypeUser(jwt) == TYPE_USERS.SPEC ? getOnlyFirstName(report?.autor) : report?.autor,
      cpf: getTypeUser(jwt) == TYPE_USERS.SPEC ? getCPFMasked(report.cpf) : report.cpf,
      phone: getTypeUser(jwt) == TYPE_USERS.SPEC ? getOnlyDDNumber(report.telefone) : report.telefone,
      email: getTypeUser(jwt) == TYPE_USERS.SPEC ? getEmailMasked(report.email) : report.email,
      data: formatDateDDMMYYYY(report.dataBloqueio),
      id: report.idDenuncia
    });
  });
  return newArray;
}

export const formatResponseTableClosedStatus = (data: any, jwt: string | null) => {
  let newArray: any = [];
  data.forEach((report: any) => {
    newArray.push({
      author: getTypeUser(jwt) == TYPE_USERS.SPEC ? getOnlyFirstName(report?.autor) : report?.autor,
      cpf: getTypeUser(jwt) == TYPE_USERS.SPEC ? getCPFMasked(report.cpf) : report.cpf,
      phone: getTypeUser(jwt) == TYPE_USERS.SPEC ? getOnlyDDNumber(report.telefone) : report.telefone,
      email: getTypeUser(jwt) == TYPE_USERS.SPEC ? getEmailMasked(report.email) : report.email,
      data: formatDateDDMMYYYY(report.dataBloqueio),
      status: report.status,
      id: report.idDenuncia
    });
  });
  return newArray;
}

export const formatResponseTableEmergency = (data: any) => {
  let newArray: any = [];
  data.forEach((emergency: any) => {
    newArray.push({
      data: formatDateDDMMYYYY(emergency.dataCadastro),
      hour: emergency?.horarioCadastro,
      type: emergency?.tipoChamado,
      mike: emergency?.protocoloExterno ? emergency.protocoloExterno : '-',
      status: emergency?.status,
      satisfaction: emergency?.satisfacao,
      id: emergency?.idChamado
    });
  });
  return newArray;
}

export function generateCPF() {
  let total_array = 9;
  let n = 9;
  let [n1, n2, n3, n4, n5, n6, n7, n8, n9] = Array.from(Array(total_array), () => (Math.round(Math.random() * n)));

  let d1 = n9 * 2 + n8 * 3 + n7 * 4 + n6 * 5 + n5 * 6 + n4 * 7 + n3 * 8 + n2 * 9 + n1 * 10;
  d1 = 11 - (mod(d1, 11));
  if (d1 >= 10) d1 = 0;

  let d2 = d1 * 2 + n9 * 3 + n8 * 4 + n7 * 5 + n6 * 6 + n5 * 7 + n4 * 8 + n3 * 9 + n2 * 10 + n1 * 11;
  d2 = 11 - (mod(d2, 11));
  if (d2 >= 10) d2 = 0;
  return `${n1}${n2}${n3}${n4}${n5}${n6}${n7}${n8}${n9}${d1}${d2}`;
}

let mod = (dividendo: any, divisor: any) => Math.round(dividendo - (Math.floor(dividendo / divisor) * divisor));


export function getLastFiveYears() {
  let currentYear: any = new Date().getFullYear();
  let dates = [
    currentYear,
    currentYear - 1,
    currentYear - 2,
    currentYear - 3,
    currentYear - 4,
    currentYear - 5,
  ];
  return dates;
};

export function setImageDimensions(url: string): Promise<{ width: number, height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => {
      reject('Erro ao carregar a imagem');
    };
    img.src = url;
  });
}

export const getOnlyFirstName = (fullName: string) => fullName.trim().split(" ")[0];

export const emailValidator = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};


export function getCPFMasked(cpf: string) {
  const cleanCPF = cpf.replace(/\D/g, "");
  if (cleanCPF.length !== 11) {
    return 'CPF inválido';
  }
  return `${cleanCPF.slice(0, 3)}..*-${cleanCPF.slice(9, 11)}`;
}

export function getOnlyDDNumber(phone: string) {
  const cleanPhone = phone.replace(/\D/g, "");
  const ddd = cleanPhone.slice(0, 2);
  return `${ddd} *********`;
}

export function getEmailMasked(email: string) {
  const [username, domain] = email.split("@");
  const maskedUsername = username.replace(/(\w)(\w+)(\w)/g, (match, first, middle, last) => {
    return first + "*".repeat(middle.length) + last;
  });
  const domainParts = domain.split(".");
  const maskedDomain = domainParts
    .map((part, index) =>
      index === domainParts.length - 1
        ? part
        : part[0] + "*".repeat(part.length - 2) + part.slice(-1)
    )
    .join(".");

  return `${maskedUsername}@${maskedDomain}`;
}


export function verifyErrorIsConection(err: HttpErrorResponse) {
  return err instanceof HttpErrorResponse && err.status === 0;
}

export function verifyErrorIsTimeout(err: any) {
  return err instanceof TimeoutError || err.name === 'TimeoutError';
}



