import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../../../shared/services/rest-api/rest-api.service';
import { getPortalTypeFromLink } from '../../../shared/utils';
import { Router } from '@angular/router';
import { PORTAL_TYPE_URLS } from '../../../shared/enums/portal-types-urls.enum';
@Injectable({
  providedIn: 'root'
})
export class AnnoucementsService {
  private readonly baseUrlRJ190 = environment.baseUrl190RJ;
  private readonly baseUrlRedeMulher = environment.baseUrlRedeMulher;
  private readonly baseEndpointRJ190 = environment.baseEndpointAnnoucements190RJ;
  private readonly baseEndpointRedeMulher = environment.baseEndpointAnnoucementsRedeMulher;
  constructor(
    private restApiService: RestApiService,
    private _router: Router
  ) { }

  getAnnoucements(limit?: number, page?: number) {
    let portal = getPortalTypeFromLink(this._router.url);
    if (!limit) limit = 10;
    if (!page) page = 1;
    const request: any = {
      baseUrl: portal === PORTAL_TYPE_URLS.RJ_190 ? this.baseUrlRJ190 : this.baseUrlRedeMulher,
      endpoint: `${portal === PORTAL_TYPE_URLS.RJ_190 ? this.baseEndpointRJ190 : this.baseEndpointRedeMulher}?limit=${limit}&page=${page}`,
    };
    return this.restApiService.get(request);
  }

  activeBanner(bannersIDs: Array<any>) {
    let endpoint = getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190
      ? this.baseUrlRJ190 + this.baseEndpointRJ190 :
      this.baseUrlRedeMulher + this.baseEndpointRedeMulher;
    endpoint += 'ativar';
    let body = {
      "bannerIds": bannersIDs
    }
    return this.restApiService.put(endpoint, body);
  };

  inactiveBanner(bannersIDs: Array<any>) {
    let endpoint = getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190
      ? this.baseUrlRJ190 + this.baseEndpointRJ190 :
      this.baseUrlRedeMulher + this.baseEndpointRedeMulher;
    endpoint += 'inativar';
    let body = {
      "bannerIds": bannersIDs
    };
    return this.restApiService.put(endpoint, body);
  };

  deleteBanner(bannersIDs: Array<any>) {
    let endpoint = getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190
      ? this.baseUrlRJ190 + this.baseEndpointRJ190 :
      this.baseUrlRedeMulher + this.baseEndpointRedeMulher;
    endpoint += 'deletar';
    let body = {
      "bannerIds": bannersIDs
    }
    return this.restApiService.delete(endpoint, {
      body: body
    });
  };

  getBannerById(id: number) {
    let portal = getPortalTypeFromLink(this._router.url);
    const request: any = {
      baseUrl: portal === PORTAL_TYPE_URLS.RJ_190 ? this.baseUrlRJ190 : this.baseUrlRedeMulher,
      endpoint: `${portal === PORTAL_TYPE_URLS.RJ_190 ? this.baseEndpointRJ190 : this.baseEndpointRedeMulher}${id}`,
    };
    return this.restApiService.get(request);
  };

  changeDataBanner(body: any, idBanner: number) {
    let endpoint = getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190
      ? this.baseUrlRJ190 + this.baseEndpointRJ190 :
      this.baseUrlRedeMulher + this.baseEndpointRedeMulher;
    endpoint += `${idBanner}`;
    return this.restApiService.put(endpoint, body);
  };

  addNewBanner(body: any) {
    let endpoint = getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190
      ? this.baseUrlRJ190 + this.baseEndpointRJ190 :
      this.baseUrlRedeMulher + this.baseEndpointRedeMulher;
    return this.restApiService.post(endpoint, body);
  };

  getAvaliableOrders() {
    let portal = getPortalTypeFromLink(this._router.url);
    const request: any = {
      baseUrl: portal === PORTAL_TYPE_URLS.RJ_190 ? this.baseUrlRJ190 : this.baseUrlRedeMulher,
      endpoint: `${portal === PORTAL_TYPE_URLS.RJ_190 ? this.baseEndpointRJ190 : this.baseEndpointRedeMulher}nr-ordem`,
    };
    return this.restApiService.get(request);
  };

  changeBannersOrders(body: any) {
    let endpoint = getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190
      ? this.baseUrlRJ190 + this.baseEndpointRJ190 :
      this.baseUrlRedeMulher + this.baseEndpointRedeMulher;
    endpoint += `atualizar-ordem`;
    return this.restApiService.put(endpoint, body);
  };

}
