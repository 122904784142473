import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { SIDEBAR_OPTION_NAMES } from '../../../shared/enums/sidebar-option-names.enum';
import { Router } from '@angular/router';
import { SIDEBAR_OPTIONS_URLS } from '../../enums/sidebar-option-urls.enum';
import { EXTRAS_PAGES_URLS } from '../../enums/extras-pages-urls.enum';
import { EXTRAS_PAGES_NAME } from '../../enums/extra-pages-names.enum';
import { getPortalTypeFromLink } from '../../utils';

@Component({
  selector: 'app-sidebar-option-title-band',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sidebar-option-title-band.component.html',
  styleUrl: './sidebar-option-title-band.component.scss',
})
export class SidebarOptionTitleBandComponent implements OnInit {
  listLinks: any = [];
  constructor(
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    this.getLinks();
  }

  getLinks() {
    let links = this.router.url.split('/');
    links.splice(0, 1);
    links.splice(0, 1);
    links.forEach(urlLink => {
      let link = this.verifyLinkName(urlLink);
      if (link) this.listLinks.push(link);
    });
  }

  verifyLinkName(link: string) {
    ;
    if (link?.includes(SIDEBAR_OPTIONS_URLS.GENERAL_PANEL)) return { name: SIDEBAR_OPTION_NAMES.GENERAL_PANEL, link: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL };
    else if (link?.includes(SIDEBAR_OPTIONS_URLS.REPORTED)) return { name: SIDEBAR_OPTION_NAMES.REPORTED, link: SIDEBAR_OPTIONS_URLS.REPORTED };
    else if (link?.includes(EXTRAS_PAGES_URLS.VIEW_REPORTED)) return { name: EXTRAS_PAGES_NAME.VIEW_REPORTED, link: EXTRAS_PAGES_URLS.VIEW_REPORTED };
    else if (link?.includes(SIDEBAR_OPTIONS_URLS.EMERGENCY)) return { name: SIDEBAR_OPTION_NAMES.EMERGENCY, link: SIDEBAR_OPTIONS_URLS.EMERGENCY };
    else if (link?.includes(SIDEBAR_OPTIONS_URLS.ANNOUNCEMENTS)) return { name: SIDEBAR_OPTION_NAMES.ANNOUNCEMENTS, link: SIDEBAR_OPTIONS_URLS.ANNOUNCEMENTS };
    else if (link?.includes(EXTRAS_PAGES_URLS.ADD_ANNOUNCEMENTS)) return { name: EXTRAS_PAGES_NAME.ADD_ANNOUNCEMENTS, link: EXTRAS_PAGES_URLS.ADD_ANNOUNCEMENTS };
    else if (link?.includes(SIDEBAR_OPTIONS_URLS.INTERACTIONS)) return { name: SIDEBAR_OPTION_NAMES.INTERACTIONS, link: SIDEBAR_OPTIONS_URLS.INTERACTIONS };
    else if (link?.includes(SIDEBAR_OPTIONS_URLS.USERS)) return { name: SIDEBAR_OPTION_NAMES.USERS, link: SIDEBAR_OPTIONS_URLS.USERS };
    else if (link?.includes(EXTRAS_PAGES_URLS.NEW_USER)) return { name: EXTRAS_PAGES_NAME.NEW_USER, link: EXTRAS_PAGES_URLS.NEW_USER };
    else if (link?.includes(EXTRAS_PAGES_URLS.VIEW_USER)) return { name: EXTRAS_PAGES_NAME.VIEW_USER, link: EXTRAS_PAGES_URLS.VIEW_USER };
    return;
  }

  redirectLink(link: any) {
    let portal = getPortalTypeFromLink(this.router.url);
    this.router.navigate([`${portal}/${link.link}`]);
  }
}
