import { HttpInterceptorFn } from '@angular/common/http';
import { of } from 'rxjs';
import { authSecretKey } from '../../utils';
import { environment } from '../../../../environments/environment';
export const rj190Interceptor: HttpInterceptorFn = (req, next) => {
  if (req.url.includes(environment.baseUrl190RJ)) {
    let token;
    of(localStorage.getItem(authSecretKey)).subscribe((res) => {
      token = res;
    });
    if (token) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
      return next(authReq);
    } else return next(req);

  } else return next(req);
};
