import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { ModalService } from '../modal/services/modal.service';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { SidebarOptionTitleBandComponent } from '../title-band/sidebar-option-title-band.component';
import { EXTRAS_PAGES_URLS } from '../../enums/extras-pages-urls.enum';
import { LoadingService } from '../../services/loading/loading.service';
import { AnnoucementsService } from '../../../190rj/services/annoucements-page/annoucements.service';
import { ToastrService } from 'ngx-toastr';
import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { getPortalTypeFromLink, verifyErrorIsConection, verifyErrorIsTimeout } from '../../utils';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-announcement',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SidebarOptionTitleBandComponent, FormsModule, DragDropModule, MatTooltipModule],
  animations: [
    trigger('dropdownAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-10px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(-10px)' }))
      ])
    ])
  ],
  templateUrl: './announcement.component.html',
  styleUrl: './announcement.component.scss',
})
export class AnnouncementComponent implements OnInit, OnDestroy {
  formGroup: FormGroup = new FormGroup({});
  annoucements: any = null;
  disableButtons: boolean = true;
  currentPage: number = 1;
  totalPageIndexData: number;
  numberPages: any;
  pages: Array<number> = [];
  lastPageIndexData: any;
  initPageIndexData: any;
  portalURLS = PORTAL_TYPE_URLS;
  currentURL: string = '';
  headerTitles = ['', 'Imagem', 'Ordem', 'Nome do arquivo', 'Link', 'Status', ''];
  show: boolean = false;
  onlyTruesBanners: Array<string> = [];
  subscribeForm: Subscription;
  select_all_annoucements: any;
  dropdownVisible: boolean = false;
  bodyElement: HTMLElement = document.body;
  title: string = "";

  @ViewChild('dropdownButton') dropdownButton: ElementRef;
  @ViewChild('dropdownContent') dropdownContent: ElementRef;

  constructor(
    private _formBuilder: FormBuilder,
    private annoucementsService: AnnoucementsService,
    private _router: Router,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    this.loadingService.present();
    this.currentURL = this._router.url;
    this.title = getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190 ? 'Banners' : 'Banner Publicados';
    this.formGroup = this._formBuilder.group({});
    await this.getAnnoucements(this.currentPage);
    this.show = true;
    this.loadingService.dismiss();
  };

  ngOnDestroy(): void {
    if (this.subscribeForm) this.subscribeForm.unsubscribe();
  }

  toggleDropdown(event: MouseEvent) {
    event.stopPropagation();
    this.dropdownVisible = !this.dropdownVisible;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (
      this.dropdownButton && this.dropdownContent &&
      !this.dropdownButton.nativeElement.contains(event.target) &&
      !this.dropdownContent.nativeElement.contains(event.target)
    ) {
      this.dropdownVisible = false;
    }
  }

  selectAllBanners(event: boolean) {
    if (event) {
      Object.keys(this.formGroup.controls).forEach(control => {
        this.formGroup.controls[control].setValue(true);
      });
      if (this.verifySelectAtLeastOneBanner()) this.select_all_annoucements = true;
      this.dropdownVisible = false;
    } else this.unSelectAllBanners();

  }

  unSelectAllBanners() {
    this.select_all_annoucements = false;
    this.dropdownVisible = false;
    Object.keys(this.formGroup.controls).forEach(control => {
      this.formGroup.controls[control].setValue(false);
    });
  }

  selectOnlyActivesBanners() {
    this.annoucements.forEach((annoucement: any) => {
      if (annoucement.status.toString() == 'true') this.formGroup.controls[annoucement.idBanner.toString()].setValue(true);
    });
    if (this.verifySelectAtLeastOneBanner()) this.select_all_annoucements = true;
    this.dropdownVisible = false;
  }

  selectOnlyInactivesBanners() {
    this.annoucements.forEach((annoucement: any) => {
      if (annoucement.status.toString() == 'false') this.formGroup.controls[annoucement.idBanner.toString()].setValue(true);
    });
    if (this.verifySelectAtLeastOneBanner()) this.select_all_annoucements = true;
    this.dropdownVisible = false;
  }

  getAnnoucements(queryPage: number): Promise<boolean> {
    return new Promise(async resolve => {
      let responseAPI = await this.getAnnouncementsData(queryPage);
      if (responseAPI.data.length > 0) {
        this.getNumPages(responseAPI);
        this.annoucements = responseAPI.data;
        this.annoucements.forEach((annoucement: any) => {
          this.formGroup.addControl(annoucement.idBanner.toString(), new FormControl(false));
        });
        this.createNewSubscribeFormControls();
        resolve(true);
      } else {
        this.annoucements = [];
        resolve(true);
      }
    })
  };


  clickAnnoucementGetIds() {
    for (const property in this.formGroup.value) {
      if (this.formGroup.value[property] && !this.onlyTruesBanners.includes(property)) this.onlyTruesBanners.push(property);
      else if (!this.formGroup.value[property]) this.onlyTruesBanners = this.onlyTruesBanners.filter(idBanner => idBanner !== property);
    };

  }

  verifySelectAtLeastOneBanner(): boolean {
    return Object.values(this.formGroup.controls).some(control => control.value === true);
  }

  public getNumPages(responseAPI: any) {
    this.numberPages = Math.ceil(responseAPI.total / 5);
    this.pages = this.paginateLinks(this.numberPages);
    this.buildNumberIndexData(responseAPI);
  };

  public paginateLinks(links: any) {
    let newLinksArray: any = [];
    links = Array.from({ length: links }, (_, i) => i + 1);
    let max: number = 0;
    this.currentPage == 1 ? max = this.currentPage + 2 : max = this.currentPage + 1;
    let min = this.currentPage - 1;
    links.forEach((link: any) => {
      if (link >= min && link <= max) {
        newLinksArray.push(link);
      }
    });
    return newLinksArray;
  };

  public buildNumberIndexData(responseAPI: any) {
    this.lastPageIndexData = this.currentPage * 5;
    this.initPageIndexData = this.currentPage * 5 - 5;
    this.totalPageIndexData = responseAPI.data.length;
    if (this.currentPage == 1) this.initPageIndexData = 1;
    if (this.lastPageIndexData > responseAPI.data.length) this.lastPageIndexData = responseAPI.data.length;
  }

  public swapPage(nextPage: boolean) {
    this.select_all_annoucements = false;
    if (nextPage) this.currentPage += 1;
    else this.currentPage -= 1;
    this.changeControlsArray();
  };

  private async changeControlsArray() {
    this.show = false;
    this.disableButtons = true;
    this.annoucements = [];
    this.onlyTruesBanners = [];
    this.loadingService.present();
    let responseAPI = await this.getAnnouncementsData(this.currentPage);
    this.annoucements = responseAPI.data;
    if (this.annoucements.length > 0) this.resetFormControl();
    this.show = true;
    this.loadingService.dismiss();
  }

  resetFormControl() {
    this.clearFormGroup();
    for (let index = 0; index < this.annoucements.length; index++) {
      const annoucement = this.annoucements[index];
      this.formGroup.addControl(annoucement.idBanner.toString(), new FormControl(false));
    }
    this.createNewSubscribeFormControls();
  }

  clearFormGroup() {
    this.formGroup = new FormGroup({});
  };

  createNewSubscribeFormControls() {
    if (this.subscribeForm) this.subscribeForm.unsubscribe();
    this.subscribeForm = this.formGroup.valueChanges.subscribe((annoucementsClicks: any) => {
      this.disableButtons = Object.values(annoucementsClicks).indexOf(true) === -1;
      this.clickAnnoucementGetIds();
    });
  }



  public activePage(pageNumber: number, click?: boolean) {
    if (pageNumber != this.currentPage) {
      this.currentPage = pageNumber;
      if (click) this.changeControlsArray();
    };
  };

  getAnnouncementsData(page: number): Promise<any> {
    return new Promise(resolve => {
      this.annoucementsService.getAnnoucements(5, page).subscribe({
        next: (annoucements: any) => resolve(annoucements),
        error: () => resolve({ data: [] }),
      })
    });
  }



  async changeAnnoucementsState(operation: string) {
    this.loadingService.present();
    let changeArray = false;
    if (operation === 'activate') {
      let bannerStatusEqOperation = await this.verifyBannerIsActivate();
      if (bannerStatusEqOperation) this.toastr.warning('Banner já está ativado.', 'Alerta!')
      else {
        await firstValueFrom(this.annoucementsService.activeBanner(this.onlyTruesBanners)).then(() => {
          this.toastr.success('Banner ativado com sucesso.', 'Sucesso!')
          changeArray = true;
        }).catch(err =>
          !verifyErrorIsTimeout(err) &&
          !verifyErrorIsConection(err) &&
          this.toastr.error('Erro ao ativar os banners.', 'Erro!')
        );
      }
    } else if (operation === 'deactivate') {
      let bannerStatusEqOperation = await this.verifyBannerIsInactivate();
      if (bannerStatusEqOperation) this.toastr.warning('Banner já está inativo.', 'Alerta!')
      else {
        await firstValueFrom(this.annoucementsService.inactiveBanner(this.onlyTruesBanners)).then(() => {
          this.toastr.success('Banner inativado com sucesso.', 'Sucesso!')
          changeArray = true;
        }).catch(err =>
          !verifyErrorIsTimeout(err) &&
          !verifyErrorIsConection(err) &&
          this.toastr.error('Erro ao inativar os banners.', 'Erro!'));
      }
    } else {
      await firstValueFrom(this.annoucementsService.deleteBanner(this.onlyTruesBanners)).then(() => {
        this.toastr.success('Imagem excluída com sucesso.', 'Sucesso!')
        changeArray = true;
      }).catch(err =>
        !verifyErrorIsTimeout(err) &&
        !verifyErrorIsConection(err) &&
        this.toastr.error('Erro ao deletar os banners.', 'Erro!'));
    };
    if (changeArray) {
      this.updateArray(operation);
      this.formGroup.reset();
    };
    this.select_all_annoucements = false;
    this.loadingService.dismiss();
  }


  verifyBannerIsActivate(): Promise<any> {
    return new Promise(resolve => {
      if (this.onlyTruesBanners.length == 1) {
        let banner: any;
        for (const idBannerSelect of this.onlyTruesBanners) {
          banner = this.annoucements.filter((a: any) => a.idBanner == idBannerSelect)[0];
        }
        if (banner.status.toString() == 'true') return resolve(true)
        return resolve(false);
      } else {
        resolve(false);
      }
    })
  }

  verifyBannerIsInactivate(): Promise<any> {
    return new Promise(resolve => {
      if (this.onlyTruesBanners.length == 1) {
        let banner: any;
        for (const idBannerSelect of this.onlyTruesBanners) {
          banner = this.annoucements.filter((a: any) => a.idBanner == idBannerSelect)[0];
        }
        if (banner.status.toString() == 'false') return resolve(true)
        return resolve(false);
      } else {
        resolve(false);
      }
    })
  }


  private updateArray(operation: string) {
    this.onlyTruesBanners.forEach(idBannerClick => {
      if (operation === 'activate') {
        this.annoucements.find((annoucement: any) => annoucement.idBanner == idBannerClick).status = 'true';
      } else if (operation === 'deactivate') {
        this.annoucements.find(
          (annoucement: any) => annoucement.idBanner == idBannerClick
        ).status = 'false';
      } else {
        const deleteIndex: number = this.annoucements.findIndex(
          (annoucement: any) => annoucement.idBanner == idBannerClick
        );
        this.annoucements.splice(deleteIndex, 1);
      }
    });
  }

  goToAnnoucementDetail(idBanner?: any) {
    this._router.navigate([this._router.url + '/' + EXTRAS_PAGES_URLS.ADD_ANNOUNCEMENTS], { queryParams: { id: idBanner } });
  }

  openModal(modalTemplate: TemplateRef<any>) {
    this.modalService.open(modalTemplate).subscribe((action: any) => {
      if (action) {
        this.changeAnnoucementsState('delete');
      }
    });
  };

  dragStart() {
    this.bodyElement.classList.add('inheritCursors');
    this.bodyElement.style.cursor = 'grabbing';
  }

  onDrop(event: CdkDragDrop<any[]>) {
    this.bodyElement.classList.remove('inheritCursors');
    this.bodyElement.style.cursor = 'unset';
    const previousIndex = event.previousIndex;
    const currentIndex = event.currentIndex;
    if (previousIndex !== currentIndex) {
      let newArray = this.annoucements;
      const temp = newArray[previousIndex];
      [newArray[currentIndex].ordem, newArray[previousIndex].ordem] =
        [newArray[previousIndex].ordem, newArray[currentIndex].ordem];
      newArray[previousIndex] = newArray[currentIndex];
      newArray[currentIndex] = temp;
      this.annoucements = newArray;
      this.cdr.detectChanges();
      this.resetFormControl();
      this.changeOrderAnnoucement(this.annoucements[previousIndex], this.annoucements[currentIndex]);
    }

  }

  changeOrderAnnoucement(droppedBanner: any, selectedBanner: any) {
    let body = [droppedBanner, selectedBanner];
    this.annoucementsService.changeBannersOrders(body).subscribe();
  }
}
