import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-expand-image',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './expand-image.component.html',
  styleUrl: './expand-image.component.scss'
})
export class ExpandImageComponent {

  constructor(
    public dialogRef: MatDialogRef<ExpandImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }


  close(): void {
    this.dialogRef.close();
  }


}
