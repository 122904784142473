import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SidebarOptionService } from '../sidebar/services/sidebar-option.service';
import { PortalTypeService } from '../../services/portal-type.service';
import { SIDEBAR_OPTIONS_LIST } from '../sidebar/consts/sidebar-options.const';
import { PORTAL_INFO } from '../../models/portal-info';
import { SIDEBAR_OPTION } from '../../models/sidebar-option';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { CommonModule } from '@angular/common';
import { getPortalTypeFromLink } from '../../utils';
import { PORTAL_TYPES } from '../../enums/portal-types.enum';

@Component({
  selector: 'app-sidebar-collpse',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './sidebar-collpse.component.html',
  styleUrl: './sidebar-collpse.component.scss'
})
export class SidebarCollpseComponent {
  currentPortal: PORTAL_INFO;
  sidebarOptions = SIDEBAR_OPTIONS_LIST;

  constructor(
    private readonly _portalTypeService: PortalTypeService,
    private readonly _sidebarOptionsService: SidebarOptionService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.listensToPortalChanges();
    this.listensToSidebarOptionsChanges();
  }

  listensToPortalChanges() {
    this._portalTypeService.currentPortalType$.subscribe(
      (currentPortal: PORTAL_INFO) => {
        this.currentPortal = currentPortal;
      }
    );
  }

  listensToSidebarOptionsChanges() {
    this._sidebarOptionsService.currentSidebarOption$.subscribe(
      (currentOption: SIDEBAR_OPTION) => {
        this.sidebarOptions.forEach((option) => {
          option.active = false;
          if (option.id === currentOption.id) {
            option.active = true;
          }
        });
      }
    );
  }

  updateSidebarOption(currentOption: SIDEBAR_OPTION) {
    let portalType = getPortalTypeFromLink(this.router.url);
    this.router.navigateByUrl(`${portalType}/${currentOption.url}`);
    this._sidebarOptionsService.updateSidebarOption(currentOption);
    let menu: any = document.getElementById("check-menu-hamburguer");
    const overflow = document.getElementsByTagName("body")[0].style.overflow = "scroll";
    const overflowHtml = document.getElementsByTagName("html")[0].style.overflow = "scroll";
    menu.checked = false;
  }

  updatePortalType() {
    this._portalTypeService.updatePortalType();
    this._sidebarOptionsService.resetSidebarOption();

    if (this.currentPortal.type === PORTAL_TYPES.RJ_190) {
      this.router.navigateByUrl(PORTAL_TYPE_URLS.RJ_190);
    } else if (this.currentPortal.type === PORTAL_TYPES.REDE_MULHER) {
      this.router.navigateByUrl(PORTAL_TYPE_URLS.REDE_MULHER);
    } else {
      this.router.navigateByUrl(PORTAL_TYPE_URLS.REDE_ESCOLA);
    }
    let menu: any = document.getElementById("check-menu-hamburguer");
    menu.checked = false;
    const overflow = document.getElementsByTagName("body")[0].style.overflow = "scroll";
    const overflowHtml = document.getElementsByTagName("html")[0].style.overflow = "scroll";
  }

  closeCollapseMenu() {
    const overflow = document.getElementsByTagName("body")[0].style.overflow = "scroll";
    const overflowHtml = document.getElementsByTagName("html")[0].style.overflow = "scroll";
  }
}
