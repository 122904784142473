<section class="portal-body__top-five-chart-card pt-15">
  <div class="chart--title mb-10 pb-1">Top 5 {{ data?.title }}</div>
  <div class="line-chart__form">
    <select class="form-select" disabled>
      <option [value]="currentYear">{{ currentYear }}</option>
    </select>
  </div>
  @for(entry of topFive; track entry.title){
  <div class="d-flex align-items-center chart--entry-text-container">
    <div class="chart--entry-title">
      {{ entry.title }} -
    </div>
    <div class="chart--entry-value">
      {{ entry.value }}
    </div>
  </div>
  <div class="chart--entry-bar" [ngStyle]="{
      'background-color': entry.color,
      width: ((entry.value / data?.total) * 100).toString() + '%'
    }"></div>
  } @empty {
  <span class="no_data">Sem dados</span>
  }
</section>
