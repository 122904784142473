import { Injectable } from '@angular/core';
import { RestApiService } from '../rest-api/rest-api.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedeMulherService {
  private readonly baseUrl = environment.baseUrlRedeMulher;
  constructor(
    private restApiService: RestApiService,
  ) { }

  numericData() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/dados-numericos',
    };
    return this.restApiService.get(request);
  };
  statiscsMonthlyUsers() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/usuarios/diario-mensal',
    };
    return this.restApiService.get(request);
  };

  statiscsMonthlyEmergencys() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/ocorrencias/diario-mensal',
    };
    return this.restApiService.get(request);
  };


  statiscsAnualsAgressor() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/agressor/por-mes',
    };
    return this.restApiService.get(request);
  };



  emergencyTops() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/ocorrencias/top-5',
    };
    return this.restApiService.get(request);
  };

  emengercyTable() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/ocorrencias/tabela',
    };
    return this.restApiService.get(request);
  };

  reportedTops() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/ocorrencias/top-5',
    };
    return this.restApiService.get(request);
  };

  reportedTable() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/ocorrencias/tabela',
    };
    return this.restApiService.get(request);
  };

  agressorTable() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/agressor/tabela',
    };
    return this.restApiService.get(request);
  };

  usersTable() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/usuarios/tabela',
    };
    return this.restApiService.get(request);
  };
}
