import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../../../shared/services/rest-api/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class EmergencyService {
  private readonly baseUrl = environment.baseUrl190RJ;
  constructor(
    private restApiService: RestApiService,
  ) { }

  emergencyPageTable(limit?: number, page?: number, textQuery?: string, initDate?: string, finalDate?: string) {
    if (!limit) limit = 10;
    if (!page) page = 1;
    const params: string[] = [];
    if (initDate) params.push(`dataInicio=${initDate}`);
    if (finalDate) params.push(`dataFinal=${finalDate}`);
    if (textQuery) params.push(`nome=${textQuery}`);
    params.push(`page=${page}`);
    params.push(`limit=${limit}`);
    const queryString = params.length > 0 ? `?${params.join('&')}` : '';
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `tabela-emergencia${queryString}`,
    };
    return this.restApiService.get(request);
  };

  emergencyGetTypes() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `tabela-emergencia/listar-tipos-chamados`,
    };
    return this.restApiService.get(request);
  };

  emergencyGetStatus() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `tabela-emergencia/listar-status`,
    };
    return this.restApiService.get(request);
  };

  emergencyExcel() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `tabela-emergencia/excel`,
      responseType: 'blob'
    };
    return this.restApiService.get(request);
  };
}
