import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { RestApiService } from '../../rest-api/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class AnnoucementsService {
  private readonly baseUrl = environment.baseUrl190RJ;
  constructor(
    private restApiService: RestApiService,
  ) { }

  getAnnoucements(limit?: number, page?: number) {
    if (!limit) limit = 10;
    if (!page) page = 1;
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `anuncio-banners?limit=${limit}&page=${page}`,
    };
    return this.restApiService.get(request);
  }

  activeBanner(bannersIDs: Array<any>) {
    let endpoint = this.baseUrl + `anuncio-banners/ativar`;
    let body = {
      "bannerIds": bannersIDs
    }
    return this.restApiService.put(endpoint, body);
  };

  inactiveBanner(bannersIDs: Array<any>) {
    let endpoint = this.baseUrl + `anuncio-banners/inativar`;
    let body = {
      "bannerIds": bannersIDs
    };
    return this.restApiService.put(endpoint, body);
  };

  deleteBanner(bannersIDs: Array<any>) {
    let endpoint = this.baseUrl + `anuncio-banners/deletar`;
    let body = {
      "bannerIds": bannersIDs
    }
    return this.restApiService.delete(endpoint, {
      body: body
    });
  };

  getBannerById(id: number) {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `anuncio-banners/${id}`,
    };
    return this.restApiService.get(request);
  };

  changeDataBanner(body: any, idBanner: number) {
    let endpoint = this.baseUrl + `anuncio-banners/${idBanner}`;
    return this.restApiService.put(endpoint, body);
  };

  addNewBanner(body: any) {
    let endpoint = this.baseUrl + `anuncio-banners`;
    return this.restApiService.post(endpoint, body);
  };

}
