import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../shared/components/header/header.component';
import { SidebarComponent } from '../shared/components/sidebar/sidebar.component';
import { PORTAL_TYPES } from '../shared/enums/portal-types.enum';
import { PortalTypeService } from '../shared/services/portal-type.service';
import { PORTAL_INFO } from '../shared/models/portal-info';
import { SidebarOnlyShowIconsService } from '../shared/components/sidebar/services/sidebar-only-show-icons/sidebar-only-show-icons.service';

@Component({
  selector: 'app-portal',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent, SidebarComponent],
  templateUrl: './portal.component.html',
  styleUrl: './portal.component.scss',
})
export class PortalComponent {

  title = 'pmerj-portal';
  portalType: PORTAL_TYPES;
  onlyShowIcons: boolean;

  constructor(
    private _portalTypeService: PortalTypeService,
    private cdRef: ChangeDetectorRef,
    private onlyShowIcService: SidebarOnlyShowIconsService,
  ) {
    this.portalType = PORTAL_TYPES.RJ_190;
  }

  ngOnInit(): void {
    this.listensToPortalChanges();
    this.checkOnlyShowIcons();
  }

  checkOnlyShowIcons() {
    this.onlyShowIcService.only$.subscribe((value) => {
      this.onlyShowIcons = value;
    })
  }

  listensToPortalChanges() {
    this._portalTypeService.currentPortalType$.subscribe(
      (currentPortal: PORTAL_INFO) => {
        this.portalType = currentPortal.type;
        this.cdRef.detectChanges();
      }
    );
  }
}
