<section class="portal-header--container">
  <app-sidebar-option-title-band></app-sidebar-option-title-band>
</section>
<section class="portal-body--container relative">
  <input type="checkbox" id="check-three-points">
  <label for="check-three-points" class="checkbtn">
    <div class="point"></div>
    <div class="point"></div>
    <div class="point"></div>
  </label>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="header">
          <p class="portal-body__title">Interações Usuários</p>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="portal-body__interactions--grid">
          <div class="interactions__users-list--card">
            <form class="interactions__users_list--form position-relative pb-1 mx-25" [formGroup]="formGroup">
              <img class="interactions__users_list--magnifying-glass" src="./../../../../assets/magnifying-glass.svg" />
              <input class="pl-35" type="text" formControlName="userName" placeholder="Pesquisar Usuário" />
            </form>
            <div class="interactions__user-list--container pt-15 ml-25">
              @for(user of users; track user.id){
              <div class="interactions__user-list--grid mb-25">
                <div class="position-relative">
                  <div class="interactions__user-status--circle" [ngClass]="user.status"></div>
                  <img src="./../../../../assets/user-picture.png" />
                </div>
                <div class="my-auto username">{{ user.name }}</div>
                <div class="mt-8">
                  @if(user.unreadMessages){
                  <div class="interactions__user-messages-count--circle">
                    <div class="interactions__user-messages-count--value">
                      {{ user.unreadMessages }}
                    </div>
                  </div>
                  }
                </div>
              </div>
              }
            </div>
          </div>
          <app-chat></app-chat>
          <div class="interactions__user-detais-div">
            <app-interaction-details></app-interaction-details>
            <app-interaction-photos></app-interaction-photos>
            <app-dennoucement-map></app-dennoucement-map>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="interactions_collapse_details">
    <div class="closebtn">
      <label for="check-three-points"><img src="assets/close_gray.svg"></label>
    </div>
    <app-interaction-details></app-interaction-details>
    <app-interaction-photos></app-interaction-photos>
    <app-dennoucement-map></app-dennoucement-map>
  </div>
</section>
