import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { HeaderTableSettings } from "../../../shared/components/header-box-table/header-box-table.model";
import { UsersService } from "../../../shared/services/190rj/users-page/users.service";

@Injectable({
  providedIn: 'root'
})

export class TableUsers190RJService {
  constructor(
    private usersService: UsersService,
  ) { }

  public async getSettingsTable(pageNumber?: number, name?: string, profileID?: string, isItSearch?: boolean) {
    let config = {} as HeaderTableSettings;
    config.optionsFirstFilter = [{ name: "Perfil", value: "", default: true }];
    if (!isItSearch) {
      let profilesFilter: any = await this.getProfilesRJ190();
      profilesFilter.data.forEach((profile: any) => {
        config.optionsFirstFilter?.push({ name: profile.NM_Perfil, value: profile.ID_Perfil });
      });
    }
    let responseUsers = await this.getDataTableByCard(pageNumber, name, profileID);
    let newArray: any[] = [];
    responseUsers.data.forEach((user: any) => {
      let newObject = {
        name: user.nome,
        cpf: user.cpf,
        phone: user.telefone,
        email: user.email,
        organID: user.orgaoId,
        profileID: user.perfilId
      };
      newArray.push(newObject);
    });
    config.totalItens = responseUsers.total;
    config.headerTitle = "Usuários";
    config.titleSearch = "Pesquisar por nome";
    config.titlesTables = ['Nome', 'CPF', 'Telefone', 'E-mail', 'Órgão', 'Perfil'];
    config.itensPerPage = 10;
    config.showTextBox = true;
    config.titleSearch = 'Pesquise por nome';
    config.showFirstFilterBox = true;
    config.showClearButton = true;
    config.tableBody = newArray;
    return config;
  }

  private async getDataTableByCard(pageNumber?: number, name?: string, profileID?: string) {
    let response: any;
    await firstValueFrom(this.usersService.getUsers(10, pageNumber, name, profileID)).then((resService) => response = resService).catch(() => response = []);
    return response;
  }

  private async getProfilesRJ190() {
    let response;
    await firstValueFrom(this.usersService.getProfiles()).then((responseAPI) => response = responseAPI).catch(() => response = []);
    return response;
  }

}
