<form id="file-upload-form" class="uploader position-relative">
  <div class="upload-here-container">Carregue aqui a imagem</div>
  <input
    #fileUpload
    id="file-upload"
    type="file"
    name="fileUpload"
    accept="image/*"
  />

  <label for="file-upload" id="file-drag">
    <img id="file-image" src="#" alt="Preview" class="hidden" />
    <div id="start">
      <i class="fa fa-download" aria-hidden="true"></i>
      <div></div>
      <div id="notimage" class="hidden"></div>
    </div>
    <div id="response" class="hidden">
      <div id="messages"></div>
    </div>
  </label>
</form>
