import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { SIDEBAR_OPTIONS_URLS } from '../../../enums/sidebar-option-urls.enum';
import { PORTAL_TYPE_URLS } from '../../../enums/portal-types-urls.enum';
import { ButtonConfig } from '../../dynamic-button/dynamic-button.model';
import { DynamicButtonComponent } from '../../dynamic-button/dynamic-button.component';
import { SidebarOptionTitleBandComponent } from '../../title-band/sidebar-option-title-band.component';
import { BannerModel } from './banner.model';
import { LoadingService } from '../../../services/loading/loading.service';
import { Observable } from 'rxjs';
import { base64ToBlob, getPortalTypeFromLink } from '../../../utils';
import { AnnoucementsService } from '../../../services/190rj/annoucements-page/annoucements.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-announcement',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ImageUploadComponent, DynamicButtonComponent, SidebarOptionTitleBandComponent],
  templateUrl: './add-announcement.component.html',
  styleUrl: './add-announcement.component.scss',
})
export class AddAnnouncementComponent implements OnInit {
  fileUploaded: string;
  bannerFile: string;
  configButtonBack: ButtonConfig = { label: 'Voltar', imgSrc: "assets/back_arrow_ic.svg", handler: () => this._location.back() };
  annoucement = {
    orders: [
      { value: '1', name: '1' },
      { value: '2', name: '2' },
      { value: '3', name: '3' },
      { value: '4', name: '4' },
      { value: '5', name: '5' },
    ],
    status: [
      { value: true, name: 'Ativo' },
      { value: false, name: 'Inativo' },
    ],
  };
  idBanner: number;
  formGroup: FormGroup = new FormGroup({});
  bannerData: BannerModel;
  show: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _location: Location,
    private _router: Router,
    private route: ActivatedRoute,
    private annoucementsService: AnnoucementsService,
    private loadingService: LoadingService,
    private toastr: ToastrService
  ) { }

  async ngOnInit() {
    this.loadingService.present();
    this.initializeFormGroup();
    this.idBanner = await this.getBannerId();
    if (this.idBanner) {
      this.bannerData = await this.getDataBanner();
      this.fillForm();
    }
    else this.bannerData = {} as BannerModel;
    this.show = true;
    this.loadingService.dismiss();
  };

  initializeFormGroup() {
    this.formGroup = this._formBuilder.group({
      fileName: ['', [Validators.required]],
      link: ['', [Validators.required]],
      order: [0, [Validators.required, Validators.min(1)]],
      status: [0, [Validators.required, Validators.min(1)]],
    });
  };


  private async getBannerId(): Promise<number> {
    return new Promise(resolve => {
      this.route.queryParams.subscribe(params => {
        resolve(params['id']);
      });
    })
  };

  private async getDataBanner(): Promise<BannerModel> {
    return new Promise(resolve => {
      this.annoucementsService.getBannerById(this.idBanner).subscribe({
        next: (response: BannerModel) => {
          resolve(response);
        },

        error: () => {
          this.openToast('Erro ao buscar dados do banner!', 'error');
          this.disabledForms();
          resolve({} as BannerModel);
        },
      });
    })
  };

  private disabledForms() {
    this.formGroup.controls['fileName'].disable();
    this.formGroup.controls['link'].disable();
    this.formGroup.controls['order'].disable();
    this.formGroup.controls['status'].disable();
  }

  private fillForm() {
    this.formGroup.controls['fileName'].setValue(this.bannerData.nomeBanner ? this.bannerData.nomeBanner : '');
    this.formGroup.controls['link'].setValue(this.bannerData.urlDestino ? this.bannerData.urlDestino : '');
    this.formGroup.controls['order'].setValue(this.bannerData.ordem ? this.bannerData.ordem : '0');
    this.formGroup.controls['status'].setValue(this.bannerData.status ? this.bannerData.status : this.bannerData.status == null ? '0' : this.bannerData.status);
    this.bannerFile = this.bannerData.urlImagem ? this.bannerData.urlImagem : this.bannerFile;
  }


  checkValidForm() {
    const isFormValid: boolean = this.formGroup.valid;
    return isFormValid && this.fileUploaded;
  };

  saveAnnoucement() {
    this.loadingService.present();
    let formData = new FormData();
    let subscribe: Observable<any> = this.idBanner ? this.annoucementsService.changeDataBanner(formData, this.idBanner) : this.annoucementsService.addNewBanner(formData);
    formData.append('nomeBanner', this.formGroup.get('fileName')?.value);
    formData.append('urlDestino', this.formGroup.get('link')?.value);
    formData.append('ordem', this.formGroup.get('order')?.value);
    formData.append('status', this.formGroup.get('status')?.value);
    formData.append('urlImagem', base64ToBlob(this.fileUploaded));
    subscribe.subscribe({
      next: () => {
        this.back();
        this.loadingService.dismiss();
      },
      error: () => {
        let message = this.idBanner ? 'Erro ao alterar Banner!' : 'Erro ao criar Banner!';
        this.openToast(message, 'error');
        this.loadingService.dismiss();
      },
    });
  }

  back() {
    let portal = getPortalTypeFromLink(this._router.url);
    let page = portal == PORTAL_TYPE_URLS.RJ_190 ? SIDEBAR_OPTIONS_URLS.ANNOUNCEMENTS : SIDEBAR_OPTIONS_URLS.ANNOUNCEMENTS;
    let message = this.idBanner ? 'Imagem alterada com sucesso.' : 'Imagem salva com sucesso.';
    this._router.navigateByUrl(`${portal}/${page}`).then(() => this.openToast(message, 'success'));
  };

  openToast(message: string, type: string): void {
    if (type == 'error') {
      this.toastr.error(message, 'Erro!');
    } else {
      this.toastr.success(message, 'Sucesso!');
    }
  }

  setFileUploaded(fileBase64: string) {
    this.fileUploaded = fileBase64;
  }
}
