import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../rest-api/rest-api.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { authSecretKey, isTokenExpired } from '../../utils';
import { BehaviorSubject, catchError, filter, Observable, of, switchMap, take, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { LoadingService } from '../loading/loading.service';
import { SidebarOptionService } from '../../components/sidebar/services/sidebar-option.service';
import { PortalTypeService } from '../portal-type.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly baseUrl = environment.baseUrlAuth;
  public isRefreshingToken: boolean = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private refreshTokenObservable: Observable<any>;



  constructor(
    private restApiService: RestApiService,
    private localStorage: LocalStorageService,
    private router: Router,
    private loadingService: LoadingService,
    private _sidebarOptionsService: SidebarOptionService,
    private _portalTypeService: PortalTypeService
  ) { }

  get getAccessToken(): string | null {
    return localStorage.getItem(authSecretKey);
  }

  set setAcessToken(token: string) {
    this.localStorage.setItem(authSecretKey, token);
  }

  login(email: string, password: string): Observable<any> {
    const endpoint = `${this.baseUrl}auth/login`;
    const body = {
      email: email,
      password: password
    };
    return this.restApiService
      .post(endpoint, body).pipe(
        switchMap((response: any) => {
          this.localStorage.setItem(authSecretKey, response.accessToken);
          return of(response);
        })
      );
  }

  refreshTokenRequest(): Observable<any> {
    if (!this.getAccessToken) return throwError(() => new Error('Refresh token não encontrado'));
    if (this.isRefreshingToken) {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== ''),
        take(1)
      );
    }
    this.refreshTokenSubject.next('');
    this.isRefreshingToken = true;
    this.refreshTokenObservable = this.restApiService
      .post(`${this.baseUrl}${environment.baseEndpointRefreshToken}`, {
        refreshToken: this.getAccessToken,
      })
      .pipe(
        switchMap((response: any) => {
          this.setAcessToken = response.accessToken;
          this.refreshTokenSubject.next(response.accessToken);
          this.isRefreshingToken = false;
          return of(response.accessToken);
        }),
        catchError((err) => {
          this.isRefreshingToken = false;
          this.refreshTokenSubject.next('');
          return throwError(() => err);
        })
      );
    return this.refreshTokenObservable;
  }

  isPageReload(): boolean {
    const isReload = performance.navigation.type === 1;
    return isReload;
  }

  forgotPasseEmail(email: string) {
    const endpoint = `${this.baseUrl}auth/redefinir-senha`;
    const body = {
      email: email,
    };
    return this.restApiService
      .patch(endpoint, body);
  }

  resetPass(senha: string, hash: string | null, token: string) {
    const endpoint = `${this.baseUrl}auth/redefinir-senha/${hash}`;
    const body = {
      token: token,
      senha: senha,
    };
    return this.restApiService
      .patch(endpoint, body);
  }

  isAuthenticatedUser(): boolean | null | string {
    return this.getAccessToken && !isTokenExpired(this.getAccessToken);
  }

  logout(): void {
    if (this.getAccessToken) {
      this.localStorage.removeItem(authSecretKey);
      this.loadingService.dismiss();
    };
    this._sidebarOptionsService.resetSidebarOption();
    this._portalTypeService.resetPortalOption();
    this.router.navigate([PORTAL_TYPE_URLS.LOGIN]);
  }
}
