export const LINE_CHART_COLORS = {
  RJ_190: {
    USERS: {
      IOS: '#0090FF',
      ANDROID: '#895BF1',
    },
    EMERGENCY: '#895BF1',
    DENOUNCEMENTS: '#0090FF',
    MISSING: {
      MISSING: '#FE6BBA',
      FOUNG: '#FEBF0F',
    },
  },
  REDE_MULHER: {
    USERS: {
      IOS: '#E8149A',
      ANDROID: '#895BF1',
    },
    EMERGENCY: '#895BF1',
    AGRESSOR: '#FEBF0F',
  },
  REDE_ESCOLA: {
    USERS: {
      IOS: '#0090FF',
      ANDROID: '#26C6DA',
    },
    DENOUNCEMENTS: '#F6964F',
    EMERGENCY: '#26C6DA',
  },
};
