<section class="portal-header--container container-fluid">
  <app-sidebar-option-title-band></app-sidebar-option-title-band>
</section>
<section class="portal-body--container">
  <div class="mb-25 container-fluid portal-cards__container">
    <div class="row ">
      @for(portalCard of portalCards; track portalCard.id ){
      <div class="col-3">
        <app-filter-card (click)="clickCard()" [portalCard]="portalCard"></app-filter-card>
      </div>
      }
    </div>
  </div>
  <div class="mb-25 container-fluid line-chart__container">
    <div class="row">
      <div class="col-xxl-9 col-xl-8 col-md-8">
        <app-line-chart></app-line-chart>
      </div>
      @if(shouldShowTopFive()){
      <div class="col-xxl-3 col-xl-4 col-md-4">
        <app-top-five [topFive]="topFive" [data]="topFiveData"></app-top-five>
      </div>
      }@else{
      <div class=" col-xxl-3 col-xl-4 col-md-4">
        <app-single-doughnut-card></app-single-doughnut-card>
      </div>
      }
    </div>
  </div>

  @if(
  selectedPortalFilterCard?.title != portalCardsTitle.RJ_190_EMERGENCIES &&
  selectedPortalFilterCard?.title != portalCardsTitle.RJ_190_DENOUNCEMENTS &&
  selectedPortalFilterCard?.title != portalCardsTitle.RJ_190_MISSING
  ) {
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-8 col-xxl-8 col-md-7">
        <app-bar-chart></app-bar-chart>
      </div>
      <div class="col-xxl-4 col-xl-4 col-md-5">
        <app-daily-user-analysis></app-daily-user-analysis>
      </div>
    </div>
  </div>
  }

  @if(selectedPortalFilterCard?.title != portalCardsTitle.RJ_190_USERS) {
  <div class="container-fluid mt-20">
    <div class="row">
      <div class="col-12">
        <app-header-box-table [headerBoxTableSettings]="tableConfig" (searchClick)="searchAndFilterItens($event)"
          (clearSearchClick)="clearSearchTable()" (excelButtonClick)="clickExcelButton()"
          (swapPage)="swapPage($event)"></app-header-box-table>
      </div>
    </div>
  </div>
  }
</section>
