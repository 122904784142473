import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../../../shared/services/rest-api/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class InteractionsService {
  private readonly baseUrl = environment.baseUrl190RJ;
  constructor(
    private restApiService: RestApiService,
  ) { }

  getTypesDennoucements() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `interacoes/listar-tipos-denuncias`,
    };
    return this.restApiService.get(request);
  };

  getDennoucements(dennoucementTypeID?: number, description?: string) {
    const params: string[] = [];
    if (dennoucementTypeID && dennoucementTypeID > 0) params.push(`tipoDenunciaId=${dennoucementTypeID?.toString()}`);
    if (description) params.push(`descricao=${description}`);
    const queryString = params.length > 0 ? `?${params.join('&')}` : '';
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `interacoes${queryString}`,
    };
    return this.restApiService.get(request);
  };

  getDennoucementComments(dennoucementID: number, year: number) {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `interacoes/denuncias/${dennoucementID?.toString()}${year ? `?ano=${year.toString()}` : ''}`,
    };
    return this.restApiService.get(request);
  };

  getDennoucementsDetails(dennoucementID: number) {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `interacoes/denuncias/${dennoucementID?.toString()}/detalhes`,
    };
    return this.restApiService.get(request);
  };
}
