import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { HeaderTableSettings } from "../../../shared/components/header-box-table/header-box-table.model";
import { UsersService } from "../users-page/users.service";
import { MockDataTable } from "../../../shared/components/dynamic-table/services/dynamic-table.mocks";
import { getCPFMasked, getEmailMasked, getOnlyDDNumber, getOnlyFirstName, getTypeUser } from "../../../shared/utils";
import { AuthService } from "../../../shared/services/auth/auth.service";
import { TYPE_USERS } from "../../../shared/enums/type-users";

@Injectable({
  providedIn: 'root'
})

export class TableUsers190RJService {
  constructor(
    private usersService: UsersService,
    private authService: AuthService
  ) { }

  public async getSettingsTableUsersPortal(pageNumber?: number, name?: string, profileID?: string, isItSearch?: boolean) {
    let config = {} as HeaderTableSettings;
    config.optionsFirstFilter = [{ name: "Todos", value: "todos" }];
    if (!isItSearch) {
      let profilesFilter: any = await this.getProfilesRJ190();
      profilesFilter?.data?.forEach((profile: any) => {
        config.optionsFirstFilter?.push({ name: profile.NM_Perfil, value: profile.ID_Perfil });
      });
    }
    let responseUsers = await this.getDataTableUsersPortal(pageNumber, name, profileID);
    let newArray: any[] = [];
    responseUsers?.data?.forEach((user: any) => {
      let newObject = {
        name: user?.nome ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getOnlyFirstName(user.nome) : user.nome : '',
        cpf: user?.cpf ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getCPFMasked(user.cpf) : user.cpf : '',
        phone: user?.telefone ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getOnlyDDNumber(user.telefone) : user.telefone : '',
        email: user?.email ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getEmailMasked(user.email) : user.email : '',
        organID: user.orgaoId,
        profileID: user.perfilId
      };
      newArray.push(newObject);
    });
    config.totalItens = responseUsers.total;
    config.headerTitle = "Usuários";
    config.titleSearch = "Pesquisar por nome";
    config.titlesTables = ['Nome', 'CPF', 'Telefone', 'E-mail', 'Órgão/Unidade', 'Perfil'];
    config.itensPerPage = 10;
    config.showTextBox = true;
    config.titleSearch = 'Pesquise por nome';
    config.showFirstFilterBox = true;
    config.showClearButton = true;
    config.tableBody = newArray;
    return config;
  }

  public async getSettingsTableUsersApp(pageNumber?: number, name?: string) {
    let config = {} as HeaderTableSettings;
    let responseUsers = await this.getDataTableUsersApp(pageNumber, name);
    let newArray: any[] = [];
    responseUsers?.data?.forEach((user: any) => {
      let newObject = {
        name: user?.NM_Usuario ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getOnlyFirstName(user.NM_Usuario) : user.NM_Usuario : '',
        cpf: user?.NR_Cpf ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getCPFMasked(user.NR_Cpf) : user.NR_Cpf : '',
        phone: user?.NR_Telefone && user?.NR_DDD ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? `${user.NR_DDD} *********` : user.NR_DDD + user.NR_Telefone : '',
        email: user?.NM_Email ? getTypeUser(this.authService.getAccessToken) == TYPE_USERS.SPEC ? getEmailMasked(user.NM_Email) : user.NM_Email : '',
        id: user.ID_Usuario
      };
      newArray.push(newObject);
    });
    config.totalItens = responseUsers.total;
    config.headerTitle = "Usuários";
    config.titleSearch = "Pesquisar por nome";
    config.titlesTables = ['Nome', 'CPF', 'Telefone', 'E-mail'];
    config.itensPerPage = 10;
    config.showTextBox = true;
    config.titleSearch = 'Pesquise por nome';
    config.showClearButton = true;
    if (getTypeUser(this.authService.getAccessToken) != TYPE_USERS.SPEC) {
      config.titlesTables.push('Editar');
      config.showButton = true;
      config.buttonTable = { label: 'Editar' };
    }

    config.tableBody = newArray;
    return config;
  }

  private async getDataTableUsersPortal(pageNumber?: number, name?: string, profileID?: string) {
    let response: any;
    await firstValueFrom(this.usersService.getUsers(10, pageNumber, name, profileID)).then((resService) => response = resService).catch(() => response = []);
    return response;
  }

  private async getDataTableUsersApp(pageNumber?: number, name?: string, profileID?: string) {
    let response: any;
    await firstValueFrom(this.usersService.getUsersApp(10, pageNumber, name)).then((resService) => response = resService).catch(() => response = []);
    return response;
  }

  private async getProfilesRJ190() {
    let response;
    await firstValueFrom(this.usersService.getProfiles()).then((responseAPI) => response = responseAPI).catch(() => response = []);
    return response;
  }

}

