<div class="portal-body__line-chart-card">
  <div class="d-flex justify-content-between chart--header">
    <div class="pl-0 pr-5">
      <div class="line-chart--title">{{ title }}</div>
    </div>
    <div class="d-flex gap-3">

      @if(showFilterType) {
      <div class="line-chart__form position-relative w-fit-content">
        <select class="form-select" [ngModel]="currentType" (ngModelChange)="changeType($event)">
          @for(type of types; track type){
          <option [value]="type.value">{{ type.name }}</option>
          }
        </select>
      </div>
      }


      <div class="line-chart__form position-relative w-fit-content">
        <select class="form-select" [ngModel]="currentPeriod" (change)="changePeriod($event)" name="currentPeriod">
          @for(period of periods; track period){
          <option [value]="period">{{ period }}</option>
          }
        </select>
      </div>


    </div>
  </div>

  <section class="chart--container">
    <canvas id="myChart"></canvas>
  </section>
</div>
