import { Injectable } from '@angular/core';
import { PORTAL_TYPE_URLS } from '../../../enums/portal-types-urls.enum';
import { SIDEBAR_OPTIONS_URLS } from '../../../enums/sidebar-option-urls.enum';
import { Observable, firstValueFrom, of } from 'rxjs';
import { HeaderTableSettings } from '../../header-box-table/header-box-table.model';
import { PORTAL_FILTER_CARD_TITLES } from '../../filter-card/enums/filter-card-titles.enum';
import { MockDataTable } from './dynamic-table.mocks';
import { RedeMulherService } from '../../../services/rede-mulher/rede-mulher.service';
@Injectable({
  providedIn: 'root'
})
export class DynamicTableService {

  constructor(
    private redeMulherService: RedeMulherService,
  ) { }


  public async getObjectConfigTable(portal: string, page: string, card?: any) {
    if (portal.includes(PORTAL_TYPE_URLS.REDE_MULHER)) return await this.getSettingsTableMulher(page, card);
    else if (portal.includes(PORTAL_TYPE_URLS.REDE_ESCOLA)) return this.getSettingsTableEscola(page, card);
    return {};
  }

  public async getSettingsTableMulher(page: string, clickedCard: any) {
    let config: HeaderTableSettings = {};
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      let responseReported = await this.getTableMulher(page, clickedCard);
      if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS) {
        config.headerTitle = 'Todos os ' + clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquise por nome';
        config.showFirstFilterBox = true;
        config.optionsFirstFilter = [
          { name: 'Status', value: '', default: true },
          { name: 'Ativo', value: 'true' },
          { name: 'Inativo', value: 'false' },
        ];
        config.titlesTables = [
          'Nome',
          'CPF',
          'Telefone',
          'E-mail',
          'Status',
          'Ver Perfil',
        ];
        config.showButton = true;
        config.buttonTable = { label: 'Visualizar' };
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 5;
        config.showTextBox = false;
        config.showFirstFilterBox = true;
        config.optionsFirstFilter = [
          { name: 'Tipo', value: '', default: true },
        ];
        responseReported.forEach((element: any) => {
          let object = { name: element.type, value: element.type };
          if (!config.optionsFirstFilter?.includes(object)) config.optionsFirstFilter?.push(object);
        });
        config.titlesTables = [
          'Tipo',
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
          'Total'];
        config.showButton = false;
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_REPORTED) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquisar por nome ou palavra';
        config.showFirstFilterBox = false;
        config.showSecondFilterBox = false;
        config.titlesTables = [
          'Autor',
          'Data',
          'Horário',
          'Qt. de Reports',
          'Resumo do Post',
          'Ver Perfil',
        ];
        config.showButton = true;
        config.buttonTable = { label: 'Visualizar' };
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR) {
        config.headerTitle = 'Todos os' + ' ' + clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquisar por nome do agressor';
        config.showFirstFilterBox = false;
        config.showSecondFilterBox = false;
        config.titlesTables = [
          'Agressor',
          'CPF',
          'Medida Protetiva',
          'Data do Vencimento',
          'Vítima',
        ];
        config.showButton = false;
        config.tableBody = responseReported;
      };
    } else if (page == SIDEBAR_OPTIONS_URLS.USERS) {
      let responseUsers = await this.getTableMulher(page);
      config.headerTitle = "Usuários";
      config.titleSearch = "Pesquisar por nome";
      config.titlesTables = ['Nome', 'CPF', 'Telefone', 'E-mail', 'Órgão', 'Perfil'];
      config.itensPerPage = 10;
      config.showTextBox = false;
      config.showFirstFilterBox = true;
      config.optionsFirstFilter = [
        { name: "Perfil", value: "", default: true },
        { name: "Administrador", value: "admin" },
        { name: "Suporte", value: "sup" },
        { name: "Observador", value: "obs" },
      ];
      config.showButton = false;
      config.tableBody = responseUsers;
    } else if (page == SIDEBAR_OPTIONS_URLS.EMERGENCY) {
      let responseUsers = await this.getTableMulher(page);
      config.headerTitle = "Emergências";
      config.titleSearch = "Pesquisar por nome";
      config.titlesTables = ['Protocolo', 'Data', 'Hora', 'Tipo', 'Vítima', 'Status', 'Classificação'];
      config.itensPerPage = 10;
      config.showTextBox = false;
      config.showFirstFilterBox = true;
      config.optionsFirstFilter = [
        { name: "Tipo", value: "", default: true },
        { name: "Violência Sexual", value: "Violência Sexual" },
        { name: "Violência Verbal", value: "Violência Verbal" },
        { name: "Violência Psicologica", value: "Violência Psicologica" },
      ];
      config.showButton = false;
      config.tableBody = responseUsers;
    };
    return config;
  }

  public getSettingsTableEscola(page: string, clickedCard: any): Observable<any> {
    let config: HeaderTableSettings = {};
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      this.getTableEscola(page, clickedCard).subscribe({
        next: (responseReported) => {
          if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_SCHOOLS) {
            config.headerTitle = clickedCard.title;
            config.itensPerPage = 10;
            config.showTextBox = true;
            config.titleSearch = 'Pesquise por nome';
            config.showFirstFilterBox = true;
            config.optionsFirstFilter = [
              { name: 'Tipo', value: '', default: true },
              { name: 'Pública', value: 'Pública' },
              { name: 'Municipal', value: 'Municipal' },
              { name: 'Estadual', value: 'Estadual' },
              { name: 'Federal', value: 'Federal' },
              { name: 'Privada', value: 'Privada' },
            ];
            config.titlesTables = [
              'Nome da escola',
              'Tipo',
              'Endereço',
              'Telefone',
              'E-mail',
            ];
            config.showButton = false;
            config.tableBody = responseReported;

          } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS) {
            config.headerTitle = clickedCard.title;
            config.itensPerPage = 4;
            config.showTextBox = false;
            config.showFirstFilterBox = true;
            config.showExcelButton = true;
            config.optionsFirstFilter = [
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
            ];
            config.titlesTables = [
              'Nome da escola',
              'Nome do usuário',
              'Telefone',
              'Data da denúncia',
              'Ver denúncia'
            ];
            config.showButton = true;
            config.buttonTable = { label: 'Visualizar' };
            config.tableBody = responseReported;

          } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_EMERGENCIES) {
            config.headerTitle = clickedCard.title;
            config.itensPerPage = 5;
            config.showTextBox = false;
            config.showFirstFilterBox = true;
            config.showExcelButton = true;
            config.optionsFirstFilter = [
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
            ];
            config.titlesTables = [
              'Tipo',
              'Jan',
              'Fev',
              'Mar',
              'Abr',
              'Mai',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez',
              'Total'];
            config.showButton = false;
            config.tableBody = responseReported;

          };
        },
        error: () => {
          config = {};
        },
      });
    } else if (SIDEBAR_OPTIONS_URLS.EMERGENCY) {
    };
    return of(config);
  }

  private getTableEscola(page: string, cardOption?: any): Observable<any> {
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      let response: any;
      if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_SCHOOLS) {
        response = MockDataTable.REDE_ESCOLA.GENERAL_PANEL.SCHOOLS;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS) {
        response = MockDataTable.REDE_ESCOLA.GENERAL_PANEL.DENOUNCEMENTS;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_EMERGENCIES) {
        response = MockDataTable.REDE_ESCOLA.GENERAL_PANEL.EMERGENCIES;
        response.forEach((element: any) => {
          element.total =
            element.jan +
            element.fev +
            element.mar +
            element.abr +
            element.mai +
            element.jun +
            element.jul +
            element.ago +
            element.set +
            element.out +
            element.nov +
            element.dez;
        });
      };
      return of(response);
    }
    return of({});
  }

  private async getTableMulher(page: string, cardOption?: any) {
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      let response: any;
      if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS) {
        await firstValueFrom(this.redeMulherService.usersTable()).then((resService) => {
          response = resService.Results;
          response.forEach((element: any) => {
            element.Nome = element.Nome ? element.Nome : 'Sem nome';
            element.CPF = element.CPF ? element.CPF : 'Sem CPF';
            element.Email = element.Email ? element.Email : 'Sem email';
            element.Telefone = element.Telefone ? element.Telefone : 'Sem telefone';
            element.Status = element.Status ? 'Ativo' : 'Inativo';
          });
        }).catch((err) => {
          response = [];
          console.log(err)
        });
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES) {
        await firstValueFrom(this.redeMulherService.emengercyTable()).then((resService) => {
          response = resService.Results;
        }).catch((err) => {
          response = [];
          console.log(err)
        });
        if (response) {
          let newResponse: any = [];
          response.forEach((element: any) => {
            let newObject = {
              type: element?.NM_TipoOcorrencia ? element?.NM_TipoOcorrencia : element?.NM_Tipo,
              jan: element[1] ? element[1] : 0,
              fev: element[2] ? element[2] : 0,
              mar: element[3] ? element[3] : 0,
              abr: element[4] ? element[4] : 0,
              mai: element[5] ? element[5] : 0,
              jun: element[6] ? element[6] : 0,
              jul: element[7] ? element[7] : 0,
              ago: element[8] ? element[8] : 0,
              set: element[9] ? element[9] : 0,
              out: element[10] ? element[10] : 0,
              nov: element[11] ? element[11] : 0,
              dez: element[12] ? element[12] : 0,
              total: element[1] +
                element[2] +
                element[3] +
                element[4] +
                element[5] +
                element[6] +
                element[7] +
                element[8] +
                element[9] +
                element[10] +
                element[11] +
                element[12]
            };
            newResponse.push(newObject);
          });
          response = newResponse;
        };
        // response = MockDataTable.REDE_MULHER.GENERAL_PANEL.EMERGENCIES;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_REPORTED) {
        await firstValueFrom(this.redeMulherService.reportedTable()).then((resService) => {
          response = resService.Results;
        }).catch((err) => {
          response = [];
          console.log(err)
        });
        response = MockDataTable.REDE_MULHER.GENERAL_PANEL.REPORTED;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR) {
        await firstValueFrom(this.redeMulherService.agressorTable()).then((resService) => {
          response = resService.Results;
        }).catch((err) => {
          response = [];
          console.log(err)
        });
      };
      return response;
    } else if (page == SIDEBAR_OPTIONS_URLS.USERS) {
      return MockDataTable.REDE_MULHER.USERS
    } else if (page == SIDEBAR_OPTIONS_URLS.EMERGENCY) {
      return MockDataTable.REDE_MULHER.EMERGENCY
    }

    return of({});
  };

}
