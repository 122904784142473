import { HttpInterceptorFn } from '@angular/common/http';
import { of } from 'rxjs';
import { authSecretKey } from '../../utils';
export const redeMulherInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.url.includes('rede-mulher')) {
    let token;
    of(localStorage.getItem(authSecretKey)).subscribe((res) => {
      token = res;
    });
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    })
    return next(authReq);
  } else return next(req);
};
