import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { InteractionDetailsService } from './services/interaction-details.service';

@Component({
  selector: 'app-interaction-details',
  standalone: true,
  imports: [],
  templateUrl: './interaction-details.component.html',
  styleUrl: './interaction-details.component.scss',
})
export class InteractionDetailsComponent implements OnInit {
  chatDetails: any = {};

  constructor(private _interactionDetailsService: InteractionDetailsService) {}

  ngOnInit(): void {
    this.getInteractionDetails();
  }

  getInteractionDetails() {
    this._interactionDetailsService
      .getInteractionDetails()
      .subscribe((chatDetails: any) => {
        this.chatDetails = chatDetails;
      });
  }
}
