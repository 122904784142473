<section class="portal-body__doughnut-chart-card d-flex flex-column align-items-center position-relative">
  <canvas class="doughnut-chart" id="doughnut-chart"></canvas>
  <div class="position-absolute text-center inside-chart">
    <p class="total-card-label-values m-0">
      {{ getTotalLabelValues() | number : "1.0-0" }}
    </p>
    <p class="card-title text-center">
      {{ chartTitle }}
    </p>
    @if(chartTitle2){
    <p class="card-title text-center">
      {{ chartTitle2 }}
    </p>
    }
  </div>

  <div class="labels--container" [ngClass]="chartTitle === 'Downloads' ? 'downloads' : 'desaparecidos'">
    @for(cardLabel of cardLabels; track cardLabel.id){
    <div class="container-fluid" [ngClass]="chartTitle === 'Downloads' ? 'downloads' : 'desaparecidos'">
      <div class="row">
        @if(chartTitle === 'Downloads' || chartTitle === 'Meninas/') {
        <div class="col-xxl-2 col-xl-3 col-3 no-padding"></div>
        } @else {
        <div class="col-1 no-padding"></div>
        }
        <div class="col-1 no-padding mt-4">
          <div [ngStyle]="{ 'background-color': cardLabel.backgroundColor }" class="label-colored-square"></div>
        </div>
        @if(chartTitle === 'Downloads' || chartTitle === 'Meninas/') {
        <div class="col-xxl-4 col-xl-2 col-2 no-padding">
          <div class="label--title">
            {{ cardLabel.name }}
          </div>
        </div>
        } @else {
        <div class="col-6 no-padding">
          <div class="label--title">
            {{ cardLabel.name }}
          </div>
        </div>
        }

        <div class="col-3 no-padding">
          <div class="label--value" [ngClass]="chartTitle === 'Downloads' ? 'downloads' : 'desaparecidos'">
            {{ cardLabel.value }}
          </div>
        </div>

        @if(chartTitle === 'Downloads' || chartTitle === 'Meninas/') {
        <div class="col-xxl-2 col-xl-3 col-3 no-padding"></div>
        } @else {
        <div class="col-1 no-padding"></div>
        }
      </div>
    </div>
    }
  </div>
</section>
