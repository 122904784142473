import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor() { }

  setItem(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  getItem(key: string): Observable<any> {
    return of(localStorage.getItem(key))
  }

  setBool(key: string, value: boolean) {
    localStorage.setItem(key, String(value));
  }

  getBool(key: string): boolean {
    return localStorage.getItem(key) === 'true';
  }

  setObject(key: string, value: object) {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  public getObject(key: string): Observable<any> {
    return this.getItem(key).pipe(
      map((data: any) => {
        if (data) {
          return JSON.parse(data);
        }
      })
    );
  }

  public removeItem(key: string) {
    return localStorage.removeItem(key);
  }
}
