<section class="portal-header--container">
  <app-sidebar-option-title-band></app-sidebar-option-title-band>
</section>
<section class="portal-body--container">
  <div class="container-fluid portal-cards__container">
    <div class="row">
      <div class="col-12">
        <div class="header">
          <span class="title">Tabela Emergências</span>
          <app-dynamic-button [buttonConfig]="configButtonRegister"></app-dynamic-button>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <app-header-box-table [headerBoxTableSettings]="tableConfig" (searchClick)="searchAndFilterItens($event)"
        (clearSearchClick)="clearSearchTable()"></app-header-box-table>
      </div>
    </div>
  </div>
</section>
