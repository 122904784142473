import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { RestApiService } from '../../rest-api/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private readonly baseUrl = environment.baseUrl190RJ;
  constructor(
    private restApiService: RestApiService,
  ) { }

  getTotalReports(): Observable<any> {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'reports/total-reports',
    };
    return this.restApiService.get(request);
  };

  getReportsTable(limit?: number, page?: number, query?: string) {
    if (!limit) limit = 10;
    if (!page) page = 1;
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `reports?${query ? `q=${query}&` : ""}page=${page}&limit=${limit}`,
    };
    return this.restApiService.get(request);
  };
}
