import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../../../shared/services/rest-api/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private readonly baseUrl = environment.baseUrl190RJ;
  constructor(
    private restApiService: RestApiService,
  ) { }

  getUsers(limit?: number, page?: number, name?: string, profileID?: string): Observable<any> {
    if (!limit) limit = 10;
    if (!page) page = 1;
    const params: string[] = [];
    if (profileID && profileID != "todos") params.push(`perfilId=${profileID}`);
    if (name) params.push(`nome=${name}`);
    params.push(`page=${page}`);
    params.push(`limit=${limit}`);
    const queryString = params.length > 0 ? `?${params.join('&')}` : '';
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `usuario${queryString}`,
    };
    return this.restApiService.get(request);
  };

  getUsersApp(limit?: number, page?: number, name?: string): Observable<any> {
    if (!limit) limit = 10;
    if (!page) page = 1;
    const params: string[] = [];
    if (name) params.push(`filtro=${name}`);
    params.push(`page=${page}`);
    params.push(`limit=${limit}`);
    const queryString = params.length > 0 ? `?${params.join('&')}` : '';
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `users${queryString}`,
    };
    return this.restApiService.get(request);
  };

  getUserAppData(id: string | null): Observable<any> {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `users/${id}`,
    };
    return this.restApiService.get(request);
  };


  editUserAppData(id: string | null, body: any): Observable<any> {
    let endpoint = this.baseUrl + `users/${id}`;
    return this.restApiService.put(endpoint, body);
  };

  createUser(body: any) {
    let endpoint = this.baseUrl + `usuario`;
    return this.restApiService.post(endpoint, body);
  };

  getProfiles(): Observable<any> {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'usuario/listar-perfil',
    };
    return this.restApiService.get(request);
  };

  getOrgans(): Observable<any> {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'usuario/listar-orgao',
    };
    return this.restApiService.get(request);
  };
}
