<section class="portal-header--container">
  <app-sidebar-option-title-band></app-sidebar-option-title-band>
</section>
<div class="add-user__container--grid" *ngIf="show">
  <div></div>
  <div class="pb-30">
    <div class="title-box">
      <div class="row border-bottom-0 pb-0">
        <div class="div-col-title col-lg-6 col-xl-6 col-12">
          <p class="title portal-body__title mb-0">Editar Usuário</p>
        </div>
        <div class="div-col-back col-lg-6 col-xl-6 col-12">
          <app-dynamic-button [buttonConfig]="configButtonBack"></app-dynamic-button>
        </div>
      </div>
    </div>
    <div class="portal-body__add-annoucement-card--bigger-padding">
      <form class="mt-20" [formGroup]="formGroup">
        @if(userIsAdmin()) {
        <div class="mb-20">
          <label class="add-user__input-label d-block mb-5" for="link">Nome*</label>
          <input class="w-100 px-10" type="text" id="name" formControlName="name" autocomplete="off" />
        </div>
        <div class="mb-20">
          <div class="row">
            <div class="col-12">
              <label class="add-user__input-label d-block mb-5" for="fileName">
                CPF<span [ngClass]="{'text-danger': cpf?.value && !verifyValidCpf()}">*</span>
              </label>
              <input class="w-100 px-10" type="text" id="cpf" maxlength="14" formControlName="cpf" autocomplete="off"
                mask="000.000.000-00" />
            </div>
          </div>
          <div *ngIf="cpf?.value && !verifyValidCpf()" class="add-user__input-warning mt-10">
            <span class="text-danger">CPF inválido.</span>
          </div>
        </div>
        }
        <div class="mb-20">
          <div class="row">
            <div class="col-12">
              <label class="add-user__input-label d-block mb-5" for="link">Celular<span
                  [ngClass]="{'text-danger': cellphone?.value && cellphone?.status == 'INVALID'}">*</span></label>
              <input class="w-100 px-10" type="tel" id="cellphone" maxlength="15" formControlName="cellphone"
                autocomplete="off" mask="(00) 00000-0000" />
            </div>
            <div *ngIf="cellphone?.value && cellphone?.status == 'INVALID'" class="add-user__input-warning mt-10">
              <span class="text-danger">Celular inválido.</span>
            </div>
          </div>
        </div>
        <div class="mb-20">
          <label class="add-user__input-label d-block mb-5" for="link">E-mail<span
              [ngClass]="{'text-danger': email?.value && email?.status == 'INVALID'}">*</span></label>
          <input class="w-100 px-10" type="email" id="email" formControlName="email" autocomplete="off" />
          <div *ngIf="email?.value && email?.status == 'INVALID'" class="add-user__input-warning mt-10">
            <span class="text-danger">Email inválido.</span>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button (click)="saveUser()" class="mt-30 save-button" type="submit" [disabled]="!this.formGroup.valid">
            @if(this.formGroup.valid) {<img class="pr-7" src="assets/imgs/save_button_ic.svg">}
            Salvar
          </button>
        </div>
      </form>
    </div>
  </div>
  <div></div>
</div>
