import { Injectable } from '@angular/core';
import { RestApiService } from '../rest-api/rest-api.service';
import { environment } from '../../../../environments/environment';
import { IRestApiCall } from '../rest-api/rest-api-call';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralPanelRedeMulherService {
  private readonly baseUrl = environment.baseUrlRedeMulher;
  constructor(
    private restApiService: RestApiService,
  ) { }

  getTotalStatics(): Observable<any> {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'painel/total',
    };
    return this.restApiService.get(request);
  };


  statiscsYearlyUsers(year: number) {
    const request: IRestApiCall = {
      baseUrl: this.baseUrl,
      endpoint: 'users/estatistica-anual?ano=' + year,
    };
    return this.restApiService.get(request);
  };

  statiscsYearlyEmergency(year: number, type: number) {
    const request: IRestApiCall = {
      baseUrl: this.baseUrl,
      endpoint: `emergencias/estatistica-anual?ano=${year}${type > 0 ? `&tipoEmergencia=${type}` : ''}`,
    };
    return this.restApiService.get(request);
  };

  statiscsYearlyAggressor(year: number) {
    const request: IRestApiCall = {
      baseUrl: this.baseUrl,
      endpoint: 'users/estatistica-anual?ano=' + year,
    };
    return this.restApiService.get(request);
  };

  activeInativeUsers() {
    const request: IRestApiCall = {
      baseUrl: this.baseUrl,
      endpoint: 'users/total-ativos-inativos',
    };
    return this.restApiService.get(request);
  };

  emergencyTops() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'emergencias/top-rank',
    };
    return this.restApiService.get(request);
  };

  usersTable(limit?: number, page?: number, query?: string, tipoStatus?: string) {
    if (!limit) limit = 10;
    if (!page) page = 1;
    if (tipoStatus == 'todos') tipoStatus = '';
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `users?${query ? `q=${query}` : ''}${tipoStatus ? `&tipoStatus=${tipoStatus}` : ''}${page ? `&page=${page}` : ''}${limit ? `&limit=${limit}` : ''}`,
    };
    return this.restApiService.get(request);
  };

  generalPanelUsersTableExcelExport() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `users/detalhes-anual/excel`,
      responseType: 'blob'
    };
    return this.restApiService.get(request);
  }

  emergencyTable(limit?: number, page?: number, year?: string) {
    const params: string[] = [];
    year = year ? year : new Date().getFullYear().toString();
    if (year) params.push(`ano=${year}`);
    if (page) params.push(`page=${page}`);
    if (limit) params.push(`limit=${limit}`);
    const queryString = params.length > 0 ? `?${params.join('&')}` : '';
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `emergencias/detalhes-anual${queryString}`,
    };
    return this.restApiService.get(request);
  };

  generalPanelEmergencyTableExcelExport(year?: string) {
    year = year ? year : new Date().getFullYear().toString();
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `users/detalhes-anual/excel`,
      responseType: 'blob'
    };
    return this.restApiService.get(request);
  }

  emergencyTypes() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `emergencias/listar-tipos-emergencias`,
    };
    return this.restApiService.get(request);
  };

  reportedTops() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'dashboards/ocorrencias/top-5',
    };
    return this.restApiService.get(request);
  };

  reportedTable(limit?: number, page?: number, year?: string) {
    const params: string[] = [];
    year = year ? year : new Date().getFullYear().toString();
    if (year) params.push(`ano=${year}`);
    if (page) params.push(`page=${page}`);
    if (limit) params.push(`limit=${limit}`);
    const queryString = params.length > 0 ? `?${params.join('&')}` : '';
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `emergencias/detalhes-anual${queryString}`,
    };
    return this.restApiService.get(request);
  };

  agressorTable(limit?: number, page?: number, year?: string) {
    const params: string[] = [];
    year = year ? year : new Date().getFullYear().toString();
    if (year) params.push(`ano=${year}`);
    if (page) params.push(`page=${page}`);
    if (limit) params.push(`limit=${limit}`);
    const queryString = params.length > 0 ? `?${params.join('&')}` : '';
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `emergencias/detalhes-anual${queryString}`,
    };
    return this.restApiService.get(request);
  };

}
