export const MockDataTable = {
  RJ190: {
    GENERAL_PANEL: {
      EMERGENCIES: [
        {
          type: 'SOS Mulher',
          jan: 140,
          fev: 100,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 100,
          out: 500,
          nov: 1000,
          dez: 2000,
        },
        {
          type: 'Ameaça',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Crimes de ódio',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Roubo de rua',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Roubo de veículo',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Roubo de veículo',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 32,
          mai: 200,
          jun: 200,
          jul: 1002,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Roubo de veículo',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 32,
          jun: 56,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Roubo de veículo',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Roubo de rua',
          jan: 140,
          fev: 600,
          mar: 321,
          abr: 321,
          mai: 200,
          jun: 200,
          jul: 600,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Roubo de rua',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 53,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Roubo de rua',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Ameaça',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Ameaça',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Ameaça',
          jan: 140,
          fev: 600,
          mar: 400,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 500,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Crimes de ódio',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'SOS Mulher',
          jan: 140,
          fev: 600,
          mar: 2200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'SOS Mulher',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'SOS Mulher',
          jan: 140,
          fev: 600,
          mar: 343,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 1000,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Crimes de ódio',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Crimes de ódio',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
      ],
      DENOUNCEMENTS: [
        {
          type: 'Pessoa desaparecida',
          jan: 140,
          fev: 100,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 100,
          out: 500,
          nov: 1000,
          dez: 2000,
        },
        {
          type: 'Pessoa desaparecida',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pessoa desaparecida',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pessoa desaparecida',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pet perdido',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pet perdido',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 32,
          mai: 200,
          jun: 200,
          jul: 1002,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pet perdido',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 32,
          jun: 56,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pet perdido',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Local de práticas criminosas',
          jan: 140,
          fev: 600,
          mar: 321,
          abr: 321,
          mai: 200,
          jun: 200,
          jul: 600,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Local de práticas criminosas',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 53,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Local de práticas criminosas',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Local de práticas criminosas',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Local de festas irregulares',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Local de festas irregulares',
          jan: 140,
          fev: 600,
          mar: 400,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 500,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Local de festas irregulares',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Local de festas irregulares',
          jan: 140,
          fev: 600,
          mar: 2200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Esconderijo criminosos',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Esconderijo criminosos',
          jan: 140,
          fev: 600,
          mar: 343,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 1000,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Esconderijo criminosos',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Esconderijo criminosos',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
      ],
      MISSING: [
        {
          type: 'Pessoas desaparecidas',
          jan: 140,
          fev: 100,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 100,
          out: 500,
          nov: 1000,
          dez: 2000,
        },
        {
          type: 'Pessoas desaparecidas',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pessoas desaparecidas',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pessoas desaparecidas',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pessoas desaparecidas',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pessoas encontradas',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 32,
          mai: 200,
          jun: 200,
          jul: 1002,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pessoas encontradas',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 32,
          jun: 56,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pessoas encontradas',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pessoas encontradas',
          jan: 140,
          fev: 600,
          mar: 321,
          abr: 321,
          mai: 200,
          jun: 200,
          jul: 600,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pessoas encontradas',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 53,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pets desaparecidos',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pets desaparecidos',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pets desaparecidos',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pets desaparecidos',
          jan: 140,
          fev: 600,
          mar: 400,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 500,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pets desaparecidos',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pets encontrados',
          jan: 140,
          fev: 600,
          mar: 2200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pets encontrados',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pets encontrados',
          jan: 140,
          fev: 600,
          mar: 343,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 1000,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pets encontrados',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Pets encontrados',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
      ],
    },
    USERS: [
      { nome: 'Teste1', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste2', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste3', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Suporte' },
      { nome: 'Teste4', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Tteste5', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste6', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste7', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste8', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste9', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Observador' },
      { nome: 'Teste10', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste11', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste12', cpf: 12345678912, phone: 11900001111, email: 'enzo.souza@extreme.digital', organ: 'SEMP/DSI', profile: 'Observador' },
      { nome: 'Teste13', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Suporte' },
      { nome: 'Teste14', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste15', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Suporte' },
      { nome: 'Teste16', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste17', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Observador' },
      { nome: 'Teste18', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste19', cpf: 50910313830, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste20', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
    ],
    EMERGENCY: [
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Atendida', satisfaction: 4 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Atendida', satisfaction: 4 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Pendente', satisfaction: 4 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Atendida', satisfaction: 4 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Atendida', satisfaction: 4 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Pendente', satisfaction: 1 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Atendida', satisfaction: 2 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Pendente', satisfaction: 4 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Atendida', satisfaction: 10 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Atendida', satisfaction: 0 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Negada', satisfaction: 9 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Negada', satisfaction: 7 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Negada', satisfaction: 4 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Negada', satisfaction: 5 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Atendida', satisfaction: 1 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Pendente', satisfaction: 3 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Pendente', satisfaction: 2 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Pendente', satisfaction: 5 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Pendente', satisfaction: 4 },
      { date: "18/12/2023", hour: "01:00", type: "Acidente de trânsito com vítima", mike: 'M72183213812', status: 'Pendente', satisfaction: 4 },
    ],
    REPORTED: {
      REPORTS: [
        {
          name: 'Teste1',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Aberto',
        },
        {
          name: 'Teste2',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Aberto',
        },
        {
          name: 'Teste3',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Post Deletado',
        },
        {
          name: 'Teste4',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Aberto',
        },
        {
          name: 'Teste5',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Aberto',
        },
        {
          name: 'Teste6',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Post Deletado',
        },
        {
          name: 'Teste7',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Aberto',
        },
        {
          name: 'Teste8',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Post Deletado',
        },
        {
          name: 'Teste9',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Aberto',
        },
        {
          name: 'Teste10',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Aberto',
        },
        {
          name: 'Teste11',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Post Deletado',
        },
        {
          name: 'Teste12',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Post Deletado',
        },
        {
          name: 'Teste13',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Post Deletado',
        },
        {
          name: 'Teste14',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Post Deletado',
        },
        {
          name: 'Teste15',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Aberto',
        },
        {
          name: 'Teste16',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Post Deletado',
        },
        {
          name: 'Teste17',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Post Deletado',
        },
        {
          name: 'Teste18',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Post Deletado',
        },
        {
          name: 'Teste19',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Post Deletado',
        },
        {
          name: 'Teste20',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          status: 'Post Deletado',
        },
      ],
      OPEN_STATUS: [
        {
          name: 'Teste1',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste2',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste3',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste4',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste5',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste6',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste7',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste8',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste9',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste10',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste11',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste12',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste13',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste14',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste15',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste16',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste17',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste18',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste19',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste20',
          date: '18/12/2023',
          hour: '01:00',
          amount: 2,
          resume: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
      BLOCKED: [
        {
          name: 'Teste1',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'teste2',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste3',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste4',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste5',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste6',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste7',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste8',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste9',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste10',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste11',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Enzo Fonseca de Souza',
          cpf: 50910313830,
          phone: 11934891478,
          email: 'enzo.souza@extreme.digital',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste13',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste14',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste15',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste16',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste17',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste18',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste19',
          cpf: 50910313830,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste20',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
      ],
      CLOSED_STATUS: [
        {
          name: 'Teste1',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste2',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '10/01/2001',
        },
        {
          name: 'Teste3',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Noah Calebe Rodrigues',
          cpf: 23461861200,
          phone: 11900001111,
          email: 'noah.calebe.rodrigues@pichler.com.br',
          blockingDate: '10/02/2000',
        },
        {
          name: 'Teste5',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste6',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste7',
          cpf: 86613587443,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste8',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste9',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste10',
          cpf: 46631581109,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste11',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste12',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'enzo.souza@extreme.digital',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste13',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Agatha Sara da Rosa',
          cpf: 37903855140,
          phone: 11900001111,
          email: 'agatha.sara.darosa@vnews.com.br',
          blockingDate: '26/01/2002',
        },
        {
          name: 'Teste15',
          cpf: 52113504219,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste16',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste17',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '14/02/1985',
        },
        {
          name: 'Teste18',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste19',
          cpf: 50910313830,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
        {
          name: 'Teste20',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          blockingDate: '12/12/2023',
        },
      ]
    }
  },
  REDE_MULHER: {
    GENERAL_PANEL: {
      USERS: [
        {
          name: 'Teste1',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'iOS',
        },
        {
          name: 'Teste2',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'iOS',
        },
        {
          name: 'Teste3',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'Android',
        },
        {
          name: 'Teste4',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'iOS',
        },
        {
          name: 'Teste5',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'iOS',
        },
        {
          name: 'Teste6',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'iOS',
        },
        {
          name: 'Teste7',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'iOS',
        },
        {
          name: 'Teste8',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'iOS',
        },
        {
          name: 'Teste9',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'Android',
        },
        {
          name: 'Teste10',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'iOS',
        },
        {
          name: 'Teste11',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'iOS',
        },
        {
          name: 'Enzo Fonseca de Souza',
          cpf: 50910313830,
          phone: 11934891478,
          email: 'enzo.souza@extreme.digital',
          status: 'Ativo',
          device: 'Android',
        },
        {
          name: 'Teste13',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'Android',
        },
        {
          name: 'Teste14',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'iOS',
        },
        {
          name: 'Teste15',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'Android',
        },
        {
          name: 'Teste16',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'iOS',
        },
        {
          name: 'Teste17',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'Android',
        },
        {
          name: 'Teste18',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'iOS',
        },
        {
          name: 'Teste19',
          cpf: 50910313830,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'iOS',
        },
        {
          name: 'Teste20',
          cpf: 12345678912,
          phone: 11900001111,
          email: 'teste@teste.com',
          status: 'Ativo',
          device: 'iOS',
        },
      ],
      EMERGENCIES: [
        {
          type: 'Físico',
          jan: 140,
          fev: 100,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 100,
          out: 500,
          nov: 1000,
          dez: 2000,
        },
        {
          type: 'Psicológica',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Moral',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Sexual',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Patrímonial',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Patrímonial',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 32,
          mai: 200,
          jun: 200,
          jul: 1002,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Psicológica',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 32,
          jun: 56,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Patrímonial',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Patrímonial',
          jan: 140,
          fev: 600,
          mar: 321,
          abr: 321,
          mai: 200,
          jun: 200,
          jul: 600,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Patrímonial',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 53,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Sexual',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Patrímonial',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Sexual',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Físico',
          jan: 140,
          fev: 600,
          mar: 400,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 500,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Moral',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Sexual',
          jan: 140,
          fev: 600,
          mar: 2200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Moral',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Moral',
          jan: 140,
          fev: 600,
          mar: 343,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 1000,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Moral',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Físico',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
      ],
      REPORTED: [
        {
          name: 'Teste1',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum may be used as a placeholder before the final copy is available.',
        },
        {
          name: 'Teste2',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste3',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste4',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste5',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste6',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste7',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste8',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste9',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste10',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste11',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste12',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste13',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste14',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste15',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste16',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste17',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste18',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste19',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          name: 'Teste20',
          date: '07/02/2024',
          hour: '01:00',
          amount: 2,
          resume:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
      AGRESSOR: [
        {
          name: 'Teste1',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste2',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste3',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste4',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste5',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste6',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste7',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste8',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste9',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste10',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste11',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste12',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste13',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste14',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste15',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste16',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste17',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste18',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste19',
          cpf: 50910313830,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
        {
          name: 'Teste20',
          cpf: 12345678912,
          measure: 'loremipsum',
          dueDate: '18/12/2023',
          victim: 'loremipsum',
        },
      ],
    },
    USERS: [
      { nome: 'Teste1', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste2', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste3', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Suporte' },
      { nome: 'Teste4', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste5', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste6', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste7', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste8', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste9', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Observador' },
      { nome: 'Teste10', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste11', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste12', cpf: 12345678912, phone: 11900001111, email: 'enzo.souza@extreme.digital', organ: 'SEMP/DSI', profile: 'Observador' },
      { nome: 'Teste13', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Suporte' },
      { nome: 'Teste14', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste15', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Suporte' },
      { nome: 'Teste16', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste17', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Observador' },
      { nome: 'Teste18', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste19', cpf: 50910313830, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },
      { nome: 'Teste20', cpf: 12345678912, phone: 11900001111, email: 'teste@teste.com', organ: 'SEMP/DSI', profile: 'Admin' },

    ],
    EMERGENCY: [
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Sexual", victim: 'Laura Ayla', status: 'Finalizado', classification: 4 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Sexual", victim: 'Laura Ayla', status: 'Finalizado', classification: 4 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Sexual", victim: 'Laura Ayla', status: 'Pendente', classification: 4 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Sexual", victim: 'Laura Ayla', status: 'Finalizado', classification: 4 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Verbal", victim: 'Laura Ayla', status: 'Finalizado', classification: 4 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Sexual", victim: 'Laura Ayla', status: 'Pendente', classification: 1 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Sexual", victim: 'Laura Ayla', status: 'Finalizado', classification: 2 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Psicologica", victim: 'Laura Ayla', status: 'Pendente', classification: 4 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Psicologica", victim: 'Laura Ayla', status: 'Finalizado', classification: 10 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Verbal", victim: 'Laura Ayla', status: 'Finalizado', classification: 0 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Sexual", victim: 'Laura Ayla', status: 'Negada', classification: 9 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Verbal", victim: 'Laura Ayla', status: 'Negada', classification: 7 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Sexual", victim: 'Laura Ayla', status: 'Negada', classification: 4 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Verbal", victim: 'Laura Ayla', status: 'Negada', classification: 5 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Psicologica", victim: 'Laura Ayla', status: 'Finalizado', classification: 1 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Psicologica", victim: 'Laura Ayla', status: 'Pendente', classification: 3 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Sexual", victim: 'Laura Ayla', status: 'Pendente', classification: 2 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Sexual", victim: 'Laura Ayla', status: 'Pendente', classification: 5 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Verbal", victim: 'Laura Ayla', status: 'Pendente', classification: 4 },
      { protocol: 123456789, date: "18/12/2023", hour: "01:00", type: "Violência Sexual", victim: 'Laura Ayla', status: 'Pendente', classification: 4 },
    ],
  },
  REDE_ESCOLA: {
    GENERAL_PANEL: {
      SCHOOLS: [
        {
          name: 'Teste1',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste2',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste3',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste4',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste5',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste6',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste7',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste8',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste9',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste10',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste11',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste12',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste13',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste14',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste15',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste16',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste17',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste18',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste19',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
        {
          name: 'Teste20',
          type: 'Pública',
          adress: 'R. Carmo Neto, s/n - Cidade Nova, Rio de Janeiro - RJ, 20210-051',
          phone: '21000000000',
          email: 'contato@gmail.com',
        },
      ],
      DENOUNCEMENTS: [
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },
        {
          school_name: "Lorem ipsum",
          user_name: "Thyago Ferreira Vieira",
          phone: "21900000000",
          date: "12/12/2023"
        },],
      EMERGENCIES: [
        {
          type: 'Físico',
          jan: 140,
          fev: 100,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 100,
          out: 500,
          nov: 1000,
          dez: 2000,
        },
        {
          type: 'Psicológica',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Moral',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Sexual',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Patrímonial',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Patrímonial',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 32,
          mai: 200,
          jun: 200,
          jul: 1002,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Psicológica',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 32,
          jun: 56,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Patrímonial',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Patrímonial',
          jan: 140,
          fev: 600,
          mar: 321,
          abr: 321,
          mai: 200,
          jun: 200,
          jul: 600,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Patrímonial',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 53,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Sexual',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Patrímonial',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Sexual',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Físico',
          jan: 140,
          fev: 600,
          mar: 400,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 500,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Moral',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Sexual',
          jan: 140,
          fev: 600,
          mar: 2200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Moral',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Moral',
          jan: 140,
          fev: 600,
          mar: 343,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 1000,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Moral',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
        {
          type: 'Físico',
          jan: 140,
          fev: 600,
          mar: 200,
          abr: 100,
          mai: 200,
          jun: 200,
          jul: 200,
          ago: 200,
          set: 200,
          out: 200,
          nov: 200,
          dez: 200,
        },
      ],
    },
  },
  NO_DATA_TESTER: [],
}
