import { Component, OnDestroy, OnInit } from '@angular/core';
import { PORTAL_FILTER_CARD_TITLES } from '../../../shared/components/filter-card/enums/filter-card-titles.enum';
import { PORTAL_TYPES } from '../../../shared/enums/portal-types.enum';
import { Observable, Subscription, of } from 'rxjs';
import { SIDEBAR_OPTIONS_URLS } from '../../../shared/enums/sidebar-option-urls.enum';
import { PortalFilterCard } from '../../../shared/models/portal-filter-card';
import { HeaderTableSettings } from '../../../shared/components/header-box-table/header-box-table.model';
import { DynamicTableService } from '../../../shared/components/dynamic-table/services/dynamic-table.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PORTAL_TYPE_URLS } from '../../../shared/enums/portal-types-urls.enum';
import { CommonModule } from '@angular/common';
import { SidebarOptionTitleBandComponent } from '../../../shared/components/title-band/sidebar-option-title-band.component';
import { FilterCardComponent } from '../../../shared/components/filter-card/filter-card.component';
import { LineChartComponent } from '../../../shared/components/line-chart/line-chart.component';
import { SingleDoughnutChartComponent } from '../../../shared/components/single-doughnut-card/single-doughnut-card.component';
import { BarChartComponent } from '../../../shared/components/bar-chart/bar-chart.component';
import { DailyUserAnalysisComponent } from '../../../shared/components/general-panel/daily-user-analysis/daily-user-analysis.component';
import { TopFiveComponent } from '../../../shared/components/top-five/top-five.component';
import { HeaderBoxTableComponent } from '../../../shared/components/header-box-table/header-box-table.component';
import { FilterCardsService } from '../../../shared/components/filter-card/services/filter-cards.service';
import { EXTRAS_PAGES_URLS } from '../../../shared/enums/extras-pages-urls.enum';

@Component({
  selector: 'app-general-panel',
  standalone: true,
  imports: [
    CommonModule,
    SidebarOptionTitleBandComponent,
    FilterCardComponent,
    LineChartComponent,
    SingleDoughnutChartComponent,
    BarChartComponent,
    DailyUserAnalysisComponent,
    TopFiveComponent,
    HeaderBoxTableComponent,
  ],
  templateUrl: './general-panel.component.html',
  styleUrl: './general-panel.component.scss'
})
export class RedeEscolaGeneralPanelComponent implements OnInit, OnDestroy {
  portalCards: Array<PortalFilterCard> = [];
  portalCardsSubscription: Subscription | null = null;
  myEventSubscribeCurrentPortal: Subscription | null = null;
  selectedPortalFilterCard: PortalFilterCard | null = null;
  painelData: any[];
  portalCardsTitle = PORTAL_FILTER_CARD_TITLES;
  portalTypes = PORTAL_TYPE_URLS;
  tableConfig: any = {};

  constructor(
    private _portalFilterCardsService: FilterCardsService,
    public router: Router,
    private _tableService: DynamicTableService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.emitNewPortalFilterCards();
    this.listenToNewPortalCards();
    this.listenToChangesOnSelectedPortalFilterCard();
    await this.getConfigTable();
  };

  ngOnDestroy(): void {
    if (this.portalCardsSubscription) this.portalCardsSubscription?.unsubscribe();
    if(this.myEventSubscribeCurrentPortal) this.myEventSubscribeCurrentPortal?.unsubscribe();
  };

  getConfigTable(): Promise<boolean> {
    return new Promise(resolve => {
      if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.REDE_ESCOLA_USERS) return resolve(false);
      this.getData().subscribe(
        {
          next: (response: HeaderTableSettings) => {
            this.tableConfig = response;
            this.painelData = response.tableBody;
            if (this.tableConfig.showButton) {
              this.tableConfig.buttonTable.handler = (dataObjectTable: any) => {
                this.openVisualize(dataObjectTable);
              };
            }
            resolve(true);
          },
          error: () => {
            console.log('error api');
            resolve(true);
          }
        }
      )
    })
  }

  emitNewPortalFilterCards() {
    this._portalFilterCardsService.emitNewPortalFilterCards(
      PORTAL_TYPES.REDE_ESCOLA
    );
  }

  listenToNewPortalCards() {
    this.portalCardsSubscription =
      this._portalFilterCardsService.portalFilterCard$.subscribe(
        (portalCards: Array<PortalFilterCard>) => {
          this.portalCards = portalCards;
        }
      );
  }

  listenToChangesOnSelectedPortalFilterCard() {
    this.myEventSubscribeCurrentPortal = this._portalFilterCardsService.currentPortalFilterCard$.subscribe(
      (selectedPortalFilterCard: PortalFilterCard | null) => {
        if (selectedPortalFilterCard) {
          this.selectedPortalFilterCard = selectedPortalFilterCard;
          this.portalCards.forEach((portalCard: PortalFilterCard) => {
            portalCard.active = false;
            if (portalCard.id === selectedPortalFilterCard.id) {
              portalCard.active = true;
            }
          });
        }
      }
    );
  }

  public searchAndFilterItens(event: any) {
    let newArray: any = [];
    this.painelData.forEach((elementArray) => {
      if (event?.searchValue && event?.filterValue) {
        if (this.filterDepedingCard(event.filterValue, elementArray)) {
          if (this.validateObject(elementArray, event.searchValue)) {
            newArray.push(elementArray);
          }
        }
      } else {
        if (event?.searchValue) {
          if (this.validateObject(elementArray, event.searchValue)) {
            newArray.push(elementArray);
          }
        } else {
          if (this.filterDepedingCard(event.filterValue, elementArray))
            newArray.push(elementArray);
        }
      }
    });
    this.tableConfig.tableBody = newArray;
  }

  public filterDepedingCard(value: string, object: any): boolean {
    switch (this.selectedPortalFilterCard?.title) {
      case PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_SCHOOLS:
        if (value.toLowerCase().includes(object.type.toLowerCase())) return true;
        return false;

      case PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS:
        if (value.toLowerCase().includes(object.date.toLowerCase())) return true;
        return false;

      case PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_EMERGENCIES:
        // if (value.toLowerCase().includes(object.device.toLowerCase())) return true;
        return false;

      default:
        return false;
    }
  }

  public validateObject(object: any, textValue: string): boolean {
    switch (this.selectedPortalFilterCard?.title) {
      case PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_SCHOOLS:
        if (
          object.name.toString().toLowerCase().includes(textValue) ||
          object.type.toString().toLowerCase().includes(textValue) ||
          object.adress.toString().toLowerCase().includes(textValue) ||
          object.phone.toString().toLowerCase().includes(textValue) ||
          object.email.toString().toLowerCase().includes(textValue)
        )
          return true;
        return false;
      case PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS:
        if (
          object.school_name.toString().toLowerCase().includes(textValue) ||
          object.user_name.toString().toLowerCase().includes(textValue) ||
          object.phone.toString().toLowerCase().includes(textValue) ||
          object.date.toString().toLowerCase().includes(textValue)
        )
          return true;
        return false;

      default:
        return false;
    }
  }

  public clearSearchTable() {
    this.tableConfig.tableBody = this.painelData;
  }

  openVisualize(data: any) {
    data.name = data.user_name;
    data.resume = "Lorem ips";
    this.router.navigate([EXTRAS_PAGES_URLS.VIEW_REPORTED], { relativeTo: this.route, state: { reportData: data, card: this.selectedPortalFilterCard } });
  }

  public clickExcelButton() {
    // exportExcelTable(this.painelData);
  }


  private getData(): Observable<any> {
    // return this._tableService.getObjectConfigTable(this.router.url, SIDEBAR_OPTIONS_URLS.GENERAL_PANEL, this.selectedPortalFilterCard);
    return of({});
  }

  shouldShowTopFive() {
    return (
      (this.selectedPortalFilterCard?.selectedPortal.type ===
        PORTAL_TYPES.RJ_190 &&
        this.selectedPortalFilterCard?.title ===
        PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES) ||
      (this.selectedPortalFilterCard?.selectedPortal.type ===
        PORTAL_TYPES.RJ_190 &&
        this.selectedPortalFilterCard?.title ===
        PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS)
    );
  }
}
