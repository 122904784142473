import { Component } from '@angular/core';

@Component({
  selector: 'app-dennoucement-map',
  standalone: true,
  imports: [],
  templateUrl: './dennoucement-map.component.html',
  styleUrl: './dennoucement-map.component.scss'
})
export class DennoucementMapComponent {

}
